<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="100" :autoZIndex="true" :dismissable="false" @hide="closeForm" :showCloseIcon="true" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <div class="mt-0 mb-0">
                <div class="col-12 lg:col-12 mb-0">
                    <h3 class="mb-0">Manage {{ module_name }}</h3>
                    <p>You can add or update {{ module_name }} here.</p>
                </div>
                <Divider class="mt-0" />
                <div class="grid long-modal" :class="[$appState.modelClass]">
                    <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                        <TabView @tab-click="tabClicked($event)" lazy class="tabview-custom" v-model:activeIndex="activeTab" :scrollable="true">
                            <TabPanel v-if="checkPermission(40, 'insert') && !checkUserCoreRole(['Customer'])">
                                <template #header>
                                    <i class="pi pi-info-circle"></i>
                                    <span>&nbsp; Order Details</span>
                                </template>
                                <div>
                                    <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
                                        <div class="grid" :class="[$appState.modelClass]">
                                            <div class="col-12 lg:col-6 sm:col-12 mb-2">
                                                <!-- <div class="col-12 lg:col-12 mb-2 mt-5">
                                                    <h4 class="mb-0">Order Info</h4>  
                                                </div>
                                                <Divider class="mt-0" />  -->
                                                <div class="col-12 lg:col-6 sm:col-12 mt-5" style="float: left">
                                                    <label>Order Number</label>
                                                    <InputText :disabled="viewableOnly" placeholder="Order Number" v-model="form.order_number" class="_full-width" />
                                                    <HasError class="p-error" :form="form" field="order_number" />
                                                </div>
                                                <!-- HIDE THIS FIELD BECAUSE USER DIDN'T REQUIRED THIS -->
                                                <div class="col-12 lg:col-6 sm:col-12 mt-5" style="float: left; display: none" v-if="checkPermission(65, 'dropdown')">
                                                    <label> Order Category <span v-if="checkPermission(66, 'insert') && !viewableOnly" class="supporting-btn-link" @click="showOrderCategoryForm = true">+ Add New</span></label>
                                                    <Dropdown
                                                        :disabled="viewableOnly"
                                                        orderCategoriesLoading
                                                        :filter="true"
                                                        :showClear="true"
                                                        v-model="form.category_id"
                                                        :options="orderCategories"
                                                        optionLabel="name"
                                                        optionValue="id"
                                                        :placeholder="orderCategoriesLoading ? 'Loading...' : 'Select Category'"
                                                        class="full-width"
                                                    />
                                                    <HasError class="p-error" :form="form" field="category_id" />
                                                </div>
                                                <!-- <div class="col-12 lg:col-12 mb-2 mt-2">
                                                    <h4 class="mb-0">Customer Info</h4>  
                                                </div> -->
                                                <Divider class="mt-0" />
                                                <div>
                                                    <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float: left" v-if="checkPermission(16, 'dropdown')">
                                                        <label><span class="pi pi-star-fill important mr-1"></span>Company</label>
                                                        <Dropdown
                                                            :disabled="viewableOnly"
                                                            CompanyLoading
                                                            :filter="true"
                                                            :showClear="true"
                                                            v-model="form.company_id"
                                                            :options="companies"
                                                            optionLabel="name"
                                                            optionValue="id"
                                                            :placeholder="CompanyLoading ? 'Loading...' : 'Select Company'"
                                                            class="full-width"
                                                        />
                                                        <HasError class="p-error" :form="form" field="company_id" />
                                                    </div>
                                                    <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float: left" v-if="checkPermission(5, 'dropdown')">
                                                        <label><span class="pi pi-star-fill important mr-1"></span>Customer</label>
                                                        <!-- <Dropdown CustomersLoading :filter="true" :showClear="true"  v-model="form.user_id" :options="customers" optionLabel="name" optionValue="id"  :placeholder="CustomersLoading ? 'Loading...' : 'Select Customer'" class="full-width" style="float:left"/> -->
                                                        <AutoComplete
                                                            :disabled="viewableOnly"
                                                            :item-select="getSelectedCustomer()"
                                                            v-model="selectedCustomer"
                                                            :suggestions="filteredCustomers"
                                                            @complete="searchInCustomers($event)"
                                                            :dropdown="true"
                                                            optionLabel="name"
                                                            field="name"
                                                            :placeholder="CustomersLoading ? 'Loading...' : 'Select Customer'"
                                                            forceSelection
                                                            class="full-width"
                                                            style="float: left"
                                                        >
                                                            <template #item="slotProps">
                                                                <div class="country-item">
                                                                    <div class="ml-2">
                                                                        <strong>{{ slotProps.item.name }}</strong
                                                                        ><br /><small>{{ slotProps.item.email }}</small>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </AutoComplete>
                                                        <HasError class="p-error" :form="form" field="user_id" />
                                                    </div>

                                                    <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float: left">
                                                        <Button
                                                            v-if="form.user_id > 0 && checkPermission(5, 'update') && !viewableOnly"
                                                            @click="
                                                                showUserForm = true;
                                                                addViewInURL({ view: 'add-user', id: form.user_id });
                                                            "
                                                            type="button"
                                                            label="Edit Customer"
                                                            icon="pi pi-pencil"
                                                            class="p-button-raised p-button-lg p-button-warning mr-2 full-width"
                                                        />
                                                    </div>
                                                    <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float: left" v-if="checkPermission(5, 'insert') && !viewableOnly">
                                                        <Button
                                                            @click="
                                                                showUserForm = true;
                                                                form.user_id = 0;
                                                                addViewInURL({ view: 'add-user', id: form.user_id });
                                                            "
                                                            type="button"
                                                            label="Add New Customer"
                                                            icon="pi pi-check"
                                                            class="p-button-raised p-button-lg p-button-success mr-2 full-width"
                                                        />
                                                    </div>
                                                    <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float: left">
                                                        <Message severity="info" :icon="'pi pi-book'" :closable="false" v-for="(contact, key) in customerContacts" :key="key">
                                                            <label><strong>Name:</strong> &nbsp; {{ contact.first_name }} {{ contact.surname }} &nbsp;&nbsp;</label>
                                                            <label><strong>Email:</strong> &nbsp; {{ contact.email }}&nbsp;&nbsp;</label>
                                                            <br />
                                                            <label><strong>Phone:</strong> &nbsp; {{ contact.phone }}&nbsp;&nbsp;</label>
                                                            <label><strong>Mobile:</strong> &nbsp; {{ contact.mobile }}&nbsp;&nbsp;</label>
                                                            <br />
                                                            <label><strong>Position:</strong> &nbsp; {{ contact.position }}&nbsp;&nbsp;</label>
                                                            <br />
                                                            <Tag v-if="contact.show_on_job_sheet" class="mr-2" severity="Danger" value="Show On Job Sheet"></Tag>
                                                            <Tag v-if="contact.billing_contact" class="mr-2" severity="warning" value="Billing Contact"></Tag>
                                                        </Message>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 lg:col-6 sm:col-12 mb-2 mt-5" v-if="!checkUserCoreRole(['Customer'])">
                                                <div class="col-12 lg:col-12 mb-1">
                                                    <h4 class="mb-0">Agent Information</h4>
                                                </div>
                                                <Divider class="mt-0" />
                                                <div>
                                                    <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float: left" v-if="checkPermission(5, 'dropdown')">
                                                        <label>Order Agent</label>
                                                        <Dropdown
                                                            :disabled="viewableOnly"
                                                            EmployeesLoading
                                                            :filter="true"
                                                            :showClear="true"
                                                            v-model="form.agent_id"
                                                            :options="employees"
                                                            optionLabel="name"
                                                            optionValue="id"
                                                            :placeholder="EmployeesLoading ? 'Loading...' : 'Select Agent'"
                                                            class="full-width"
                                                        />
                                                        <HasError class="p-error" :form="form" field="agent_id" />
                                                    </div>
                                                    <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float: left">
                                                        <label>Notes for Agent</label>
                                                        <Textarea :disabled="viewableOnly" class="full-width" placeholder="Notes" v-model="form.agent_notes" rows="15" cols="30" />
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12 lg:col-6 sm:col-12 mb-2 mt-5"> 
                                                <div class="col-12 lg:col-12 mb-1">
                                                    <h4 class="mb-0">Cargo Information</h4>  
                                                </div>
                                                <Divider class="mt-0" />
                                                <div>
                                                    <div v-for="group in cargoGroups" :key="group.id"> 
                                                        <Panel class="mb-3 full-width" :header="group.name" :toggleable="true"  style="float:left" >  
                                                            <div v-for="(groupType,key) in group.groupTypes" :key="key"> 
                                                                <Panel class="mb-3 full-width" :header="groupType.name" :toggleable="true"  style="float:left" >   
                                                                    <div v-if="groupType.name"> 
                                                                        <div v-if="form.groupItems[group.id]"> 
                                                                            <div v-if="form.groupItems[group.id][groupType.id] "> 
                                                                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                                                    <label>Count</label>
                                                                                    <InputNumber placeholder="Quantity" v-model="form.groupItems[group.id][groupType.id].quantity" :min="0" showButtons class="full-width"/> 
                                                                                </div> 
                                                                                <div v-if="form.groupItems[group.id][groupType.id].quantity > 0" class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                                                                    <label>Manifest</label>
                                                                                    <Dropdown @change="getManifestData(group.id,groupType.id)" :filter="true" :showClear="true" v-model="form.groupItems[group.id][groupType.id].manifest_id" :options="group.groupManifests" optionLabel="name" optionValue="id" placeholder="Select Manifest" class="full-width"/>
                                                                                </div> 
                                                                                <Panel v-for="(number) in form.groupItems[group.id][groupType.id].quantity" :key="number" class="mb-3 full-width" :header="groupType.name +'-'+number" :toggleable="true"  style="float:left" >  
                                                                                    <span v-if="form.groupItems[group.id][groupType.id].manifest_id > 0">
                                                                                        <span v-if="groupType.manifestRec.length"> 
                                                                                            <span v-for="(rec, ikey) in groupType.manifestRec" :key="ikey" >
                                                                                                <span v-if="form.groupItems[group.id][groupType.id].manifestData[number]">
                                                                                                    <div v-if="rec !== undefined" class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left">   
                                                                                                        <label>{{rec.name}}</label>
                                                                                                        <InputNumber v-if="rec.field == 'number'" :id="'groupTypeManifestField'+ikey" :placeholder="rec.name" v-model="form.groupItems[group.id][groupType.id].manifestData[number][rec.id].value" min="0" showButtons class="full-width"/> 
                                                                                                        <InputText v-if="rec.field == 'text'" :id="'groupTypeManifestField'+ikey" :placeholder="rec.name" v-model="form.groupItems[group.id][groupType.id].manifestData[number][rec.id].value" class="full-width"/>  
                                                                                                    </div>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </Panel>
                                                                            </div> 
                                                                        </div> 
                                                                    </div> 
                                                                </Panel> 
                                                            </div> 
                                                        </Panel> 
                                                    </div>   
                                                </div>     
                                            </div> -->
                                        </div>
                                        <div class="col-12 lg:col-12">
                                            <Toolbar class="pl-0 pr-0">
                                                <template v-slot:start>
                                                    <Button v-if="!viewableOnly" type="submit" label="Save & Next" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2" />
                                                    <Button
                                                        v-if="!viewableOnly"
                                                        type="submit"
                                                        label="Save and Close"
                                                        :loading="isSaving"
                                                        icon="pi pi-check"
                                                        @click="(saveAndClose = true), save()"
                                                        class="p-button-raised p-button-lg p-button-info mr-2"
                                                    />
                                                    <Button v-if="!viewableOnly" type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()" />
                                                    <Button v-if="viewableOnly" type="button" label="Next" icon="pi pi-arrow-right" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="moveNext()" />
                                                </template>
                                                <template v-slot:end>
                                                    <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm" />
                                                </template>
                                            </Toolbar>
                                        </div>
                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel v-model:activeIndex="activeTab" :disabled="checkUserCoreRole(['Customer']) ? false : tabDisabled" v-if="checkPermission(41, 'insert')">
                                <template #header>
                                    <i class="pi pi-car"></i>
                                    <span>&nbsp; Transport</span>
                                </template>
                                <OrderTransportForm @loadNextTab="activeTab += 1" @loadPreviousTab="activeTab -= 1" v-on:formClose="closeForm" :viewableOnly="viewableOnly" :companyId="form.company_id" :recordId="orderId" />
                                <!-- <OrderTransports v-on:formClose="closeForm" v-if="showTab1" :recordId="selectedRecordId"/> -->
                            </TabPanel>
                            <!-- <TabPanel   :disabled="tabDisabled" v-if="checkPermission(27,'view') && orderId > 0">
                                <template #header>
                                    <i class="pi pi-clone"></i>
                                    <span>&nbsp; Products</span> 
                                </template>
                                <OrderProducts  :orderId="orderId"  />
                            </TabPanel>  -->
                            <TabPanel :disabled="tabDisabled" v-if="checkPermission(51, 'view') && orderId > 0">
                                <template #header>
                                    <i class="pi pi-money-bill"></i>
                                    <span>&nbsp; Quotations</span>
                                </template>
                                <OrderQuotations :orderId="orderId" />
                            </TabPanel>
                            <TabPanel :disabled="tabDisabled" v-if="checkPermission(43, 'view') && orderId > 0">
                                <template #header>
                                    <i class="pi pi-money-bill"></i>
                                    <span>&nbsp; Invoices</span>
                                </template>
                                <OrderInvoices :orderId="orderId" />
                            </TabPanel>
                            <TabPanel :disabled="tabDisabled" v-if="checkPermission(41, 'view') && !checkUserCoreRole(['Customer']) && orderId > 0">
                                <template #header>
                                    <i class="pi pi-clock"></i>
                                    <span>&nbsp;Traveling Details</span>
                                </template>
                                <OrderTravelings :orderId="orderId" />
                            </TabPanel>
                            <TabPanel :disabled="tabDisabled" v-if="checkPermission(54, 'view') && !checkUserCoreRole(['Customer']) && orderId > 0">
                                <template #header>
                                    <i class="pi pi-clock"></i>
                                    <span>&nbsp;Repeat Job</span>
                                </template>
                                <OrderSchedules :orderId="orderId" />
                            </TabPanel>
                            <TabPanel :disabled="tabDisabled" v-if="checkPermission(44, 'view') && !checkUserCoreRole(['Customer']) && orderId > 0">
                                <template #header>
                                    <i class="pi pi-envelope"></i>
                                    <span>&nbsp; Emails</span>
                                </template>
                                <OrderEmails :orderId="orderId" />
                            </TabPanel>
                            <TabPanel :disabled="tabDisabled" v-if="checkPermission(45, 'view') && !checkUserCoreRole(['Customer']) && orderId > 0">
                                <template #header>
                                    <i class="pi pi-book"></i>
                                    <span>&nbsp; Notes</span>
                                </template>
                                <OrderNotes :orderId="orderId" />
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        </Sidebar>
    </BlockUI>
    <OrderCategoryForm v-on:formClose="closeOrderCategoryForm" v-if="showOrderCategoryForm && (checkPermission(66, 'insert') || checkPermission(66, 'update'))" :orderId="form.category_id" :recordId="0" :showForm="true" />
    <UserForm
        v-on:formClose="
            closeUserForm();
            removeViewFromURL('add-user');
        "
        v-if="showUserForm"
        :addCustomer="true"
        :recordId="form.user_id"
        :showForm="true"
    />
    <!-- <OrdersTransportForm v-on:formClose="closeForm" v-if="showForm" :recordId="selectedRecordId" :showForm="true" />  -->
</template>
<script>
import UserForm from '../../components/AccessManagement/UserForm.vue';
// import OrderTransports from '../../pages/Orders/OrderTransports.vue';
import OrderTransportForm from '../../components/Orders/OrderTransportForm.vue';
import OrderCategoryForm from '../../components/Orders/OrderCategoryForm.vue';
import OrderProducts from '../../pages/Orders/OrderProducts.vue';
import OrderInvoices from '../../pages/Orders/OrderInvoices.vue';
import OrderQuotations from '../../pages/Orders/OrderQuotations.vue';
import OrderEmails from '../../pages/Orders/OrderEmails.vue';
import OrderNotes from '../../pages/Orders/OrderNotes.vue';
import OrderSchedules from '../../pages/Orders/OrderSchedules.vue';
import OrderTravelings from '../../pages/Orders/OrderTravelings.vue';

export default {
    data() {
        return {
            form: new this.Form({
                id: '',
                company_id: 0,
                order_number: '',
                user_id: '',
                category_id: 0,
                agent_notes: '',
                groupItems: [],
                agent_id: this.userData['id'],
                status: 1,
            }),

            tabDisabled: true,
            orderId: 0,
            tabIndex: 0,
            filteredCustomers: [],
            selectedCustomer: [],
            showUserForm: false,
            CompanyLoading: false,
            EmployeesLoading: false,
            CustomersLoading: false,
            CargoGroupLoading: false,
            module_name: 'Order',
            isSaving: false,
            saveAndClose: false,
            activeTab: 0,
            recId: 0,
            loadingComponent: true,
            orderCategoriesLoading: true,
            formVisible: false,
            customerData: '',
            showOrderCategoryForm: false,
            customerContacts: [],
            customers: [],
            companies: [],
            employees: [],
            filteredCustomerComapnies: [],
            cargoGroups: [],
            orderCategories: [],
            user: [],
            viewableOnly: false,
        };
    },
    props: {
        showForm: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
        orderView: {
            type: Boolean,
            required: true,
        },
    },
    created() {},
    computed: {},
    components: {
        // OrderTransports,
        UserForm,
        OrderTransportForm,
        // OrderProducts,
        OrderCategoryForm,
        OrderInvoices,
        OrderQuotations,
        OrderEmails,
        OrderNotes,
        OrderSchedules,
        OrderTravelings,
    },
    mounted() {
        this.emitter.on('orderCreated', (orderId) => {
            // this.recId = orderId;
            this.orderId = orderId;
            // this.activeTabs();
            this.closeForm();
        });
        this.user = JSON.parse(localStorage.getItem('user'));
        this.resetForm();
        this.formVisible = this.showForm;
        this.recId = this.recordId;
        this.orderId = this.recordId;
        this.viewableOnly = this.orderView;
        this.getAllRequestData();

        if (this.checkUserCoreRole(['Customer'])) {
            this.activeTab = 0;
        }

        if (this.$route.query.view) {
            this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
            if (Object.keys(this.urlParams).length) {
                this.urlParams.forEach((parameter) => {
                    if (parameter.view == 'add-user') {
                        this.form.user_id = parameter.id;
                        this.showUserForm = true;
                    } else if (parameter.view == 'order-tab') {
                        this.activeTab = parameter.id;
                    }
                });
            }
        }
    },
    methods: {
        searchInCustomers(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCustomers = [...this.customers];
                } else {
                    this.filteredCustomers = this.customers.filter((customer, found = false) => {
                        if (customer.name.toLowerCase().startsWith(event.query.toLowerCase())) {
                            found = true;
                        }

                        if (customer.email.toLowerCase().startsWith(event.query.toLowerCase())) {
                            found = true;
                        }

                        return found;
                    });
                }
            }, 250);
        },
        closeOrderCategoryForm() {
            this.getOrderCategories();
            this.showOrderCategoryForm = false;
        },
        tabClicked($event) {
            // console.log($event.index); 
            this.removeViewFromURL('order-tab');
            this.addViewInURL({ view: 'order-tab', id: this.activeTab });
        },
        searchCustomerCompany(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredCustomerComapnies = [...this.customerCompanies];
                } else {
                    this.filteredCustomerComapnies = this.customerCompanies.filter((company) => {
                        return company.customer_company.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        getAllRequestData() {
            this.$axios
                .all([this.updateRecord()])
                .then(() => {
                    this.getOrderNumber();
                    this.getCompanies();
                    this.getCustomers();
                    return this.getEmployees();
                })
                .then(() => {
                    return this.getCargoGroupForOrders();
                })
                .then(() => {
                    return this.getCustomerData();
                })
                .then(() => {
                    return this.getOrderCategories();
                })
                .then(() => {
                    // setTimeout( () => {
                    //     this.updateRecord();
                    // },2000)
                })
                .then(() => {
                    this.blockComponent = false;
                })
                .catch(() => {
                    //    this.getAllRequestData();
                })
                .finally(() => {});
        },

        async getOrderNumber() {
            var Vthis = this;
            await this.axios
                .post('getOrderNumber')
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (Object.keys(response.data).length) {
                            Vthis.form.order_number = response.data.orderNumber;
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {});
        },
        async getManifestData($group, $groupType) {
            if (this.form.groupItems[$group][$groupType].manifest_id > 0) {
                var Vthis = this;
                Vthis.CompanyLoading = true;
                var $manifest_id = this.form.groupItems[$group][$groupType].manifest_id;
                await this.axios
                    .post('getAllManifestFields', { cargo_group_id: $group, manifest_id: $manifest_id })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            if (response.data.length) {
                                if (this.form.groupItems[$group][$groupType].quantity > 0) {
                                    if (!Vthis.form.groupItems[$group][$groupType].manifestData) {
                                        Vthis.form.groupItems[$group][$groupType].manifestData = [];
                                    }
                                    for (var i = 1; i <= this.form.groupItems[$group][$groupType].quantity; i++) {
                                        if (!Vthis.form.groupItems[$group][$groupType].manifestData[i]) {
                                            Vthis.form.groupItems[$group][$groupType].manifestData[i] = [];
                                        }
                                        response.data.forEach((rec) => {
                                            if (!Vthis.form.groupItems[$group][$groupType].manifestData[i][rec.id]) {
                                                Vthis.form.groupItems[$group][$groupType].manifestData[i][rec.id] = { value: '' };
                                            }
                                        });
                                    }

                                    this.cargoGroups.forEach((group) => {
                                        if (group.id == $group) {
                                            group.groupTypes.forEach((groupType) => {
                                                if (groupType.id == $groupType) {
                                                    response.data.forEach((rec) => {
                                                        groupType.manifestRec[rec.id] = rec;
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.CompanyLoading = false;
                    });
            }
        },
        async getCargoGroupForOrders() {
            var Vthis = this;
            Vthis.CargoGroupLoading = true;
            await this.axios
                .post('getAllVehiclesCargoGroupForOrders', { orderId: this.recordId })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        Vthis.cargoGroups = response.data;
                        if (Vthis.cargoGroups.length) {
                            Vthis.cargoGroups.forEach((group) => {
                                Vthis.form.groupItems[group.id] = [];
                                group.groupTypes.forEach((groupType) => {
                                    Vthis.form.groupItems[group.id][groupType.id] = { quantity: groupType.quantity, manifest_id: groupType.manifest_id, manifestData: groupType.manifestData };
                                    // Vthis.form.groupItems[group.id][groupType.id].push({'quantity' :groupType.quantity, 'manifest_id' :groupType.manifest_id});
                                    if (groupType.manifest_id > 0) {
                                        Vthis.getManifestData(group.id, groupType.id);
                                    }
                                });
                            });
                        }
                    }
                    // console.log(Vthis.form.groupItems);
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.CargoGroupLoading = false;
                });
        },
        async getCompanies() {
            var Vthis = this;
            Vthis.CompanyLoading = true;
            await this.axios
                .post('getAllCompanies', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response.data.length) {
                            response.data.forEach((record) => {
                                if (record.id == this.user.company_id) {
                                    this.form.company_id = record.id;
                                }
                            });
                        }

                        this.companies = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.CompanyLoading = false;
                });
        },
        getSelectedCustomer() {
            if (event && this.selectedCustomer) {
                if (this.selectedCustomer.id) {
                    this.form.user_id = this.selectedCustomer.id;
                    this.selectedCustomer = this.selectedCustomer.name;
                    this.getCustomerData();
                }
            }
        },
        async getOrderCategories() {
            var Vthis = this;
            Vthis.orderCategoriesLoading = true;
            await this.axios
                .post('getAllOrderCategories', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response.data) {
                            this.orderCategories = response.data;
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.orderCategoriesLoading = false;
                });
        },
        async getCustomerData() {
            if (this.form.user_id > 0) {
                var Vthis = this;
                Vthis.CustomersLoading = true;
                await this.axios
                    .post('getRoleSpecificUsers', { id: this.form.user_id, role: 'Customer', dropdown: true })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            if (response.data) {
                                this.customerContacts = response.data[0].userContacts;
                            }
                            this.customerData = response.data;
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.CustomersLoading = false;
                    });
            }
        },
        async getCustomers() {
            var Vthis = this;
            Vthis.CustomersLoading = true;
            await this.axios
                .post('getRoleSpecificUsers', { role: 'Customer', dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.customers = response.data;
                        this.filteredCustomers = this.customers;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.CustomersLoading = false;
                });
        },
        async getEmployees() {
            var Vthis = this;
            Vthis.EmployeesLoading = true;
            await this.axios
                .post('getRoleSpecificUsers', { role: 'Agent', dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.employees = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.EmployeesLoading = false;
                });
        },

        resetForm() {
            this.form.reset();
        },
        moveNext() {
            this.activeTab = this.activeTab + 1;
            this.loadTargetedTab('', this.activeTab);
        },
        async save() {
            var Vthis = this;
            this.isSaving = true;
            await this.form
                .post(Vthis.$baseurl + 'api/saveOrder', Vthis.form)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.recId = response.data.id;
                        this.orderId = response.data.id;
                        this.addViewInURL({ view: 'add-order', id: this.orderId });
                        if (!this.recId) {
                            //  this.getRecords();
                        }
                        // this.getRecords();
                        if (this.saveAndClose) {
                            this.saveAndClose = false;
                            // this.closeForm();
                        }

                        setTimeout(() => {
                            this.activeTab = this.activeTab + 1;
                            this.loadTargetedTab('', this.activeTab);
                        }, 1000);
                        Vthis.showMessage('Saved successfully', 'success');
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
        loadForm() {
            this.resetForm();
        },
        closeForm() {
            this.$emit('closeOrderFrom', true);
        },
        closeUserForm() {
            this.showUserForm = false;
            this.getCustomers();
            this.getCustomerData();
        },
        async updateRecord() {
            if (this.recId > 0) {
                var Vthis = this;
                this.loading = true;
                await this.axios
                    .post('getAllOrders', { id: this.recId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            Vthis.form.fill(response.data[0]);
                            this.selectedCustomer = response.data[0].customer_name;
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.loading = false;
                    });
            }
        },
        activeTabs() {
            this.tabDisabled = false;
        },
    },
    watch: {
        recId(newValue) {
            if (newValue > 0) {
                this.tabDisabled = false;
            }
        },
    },
};
</script>
 
