<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="showHistoryPage" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="true" @hide="closeForm" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <div class="mt-0 mb-0 col-12 lg:col-12">
                <div class="col-12 lg:col-12 mb-0">
                    <h3 class="mb-0">Manage {{ module_name }}</h3>
                    <p>You can add or update {{ module_name }} here. </p>
                </div>
                <Divider class="mt-0" />
                <div class="grid modal-body">
                    <div class="col-12 lg:col-12 mb-0">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <!-- <h3>Manage {{module_name}}</h3>   -->
                            </template>
                            <template v-slot:end>
                                <Button v-if="checkPermission(moduleId, 'view')" v-tooltip.top="'Refresh'"
                                    :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh"
                                    :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                    class="p-button-raised p-button-success mr-2" @click="getRecords" />
                                <!-- <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Send New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" />  -->
                            </template>
                        </Toolbar>
                        <DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records"
                            :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm" :rows="10"
                            dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" :loading="loading"
                            :filters="filters" responsiveLayout="scroll"
                            :globalFilterFields="['invoice_status', 'description', 'added_by', 'date_added',]"
                            :rowsPerPageOptions="[10, 20, 50, 100]"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            v-model:selection="selectedRecords" selectionMode="multiple" :scrollable="true"
                            scrollHeight="400px">

                            <template #header>
                                <div class="flex justify-content-between flex-column sm:flex-row">
                                    <span class="p-input-icon-left mb-2">
                                        <i class="pi pi-search" />
                                        <InputText v-model="filters['global'].value" placeholder="Keyword Search"
                                            style="width: 100%" />
                                    </span>
                                    <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header"
                                        @update:modelValue="onToggle" placeholder="Select Columns" style="width: 20em" />
                                </div>
                            </template>

                            <template #empty>
                                <div class="table-message">
                                    <img class="table-message-img" src="layout/images/no-record-found.png"
                                        alt="No record found">
                                </div>
                            </template>
                            <template #loading>
                                Loading data. Please wait.
                            </template>
                            <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                            <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header"
                                :exportable="col.field == 'action' ? false : true"
                                :sortable="col.field == 'action' ? false : true" :key="col.field + '_' + index"
                                :class="col.field == 'action' ? 'btnAction' : ''"
                                :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;' : 'max-width:5rem;flex-grow:1; flex-basis:160px;'">
                                <template #body="{ data }">

                                    <span v-if="col.field == 'invoice_status'">{{ data.invoice_status }}</span>
                                    <span v-if="col.field == 'description'">{{ data.description }}</span>
                                    <span v-else-if="col.field == 'date_added'">{{ formatDateTime(data.date_added) }}</span>
                                    <span v-else-if="col.field == 'added_by'">{{ data.added_by }}</span>


                                </template>
                                <template #filter="{ filterModel }">

                                    <span v-if="col.field == 'invoice_status'">
                                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                            placeholder="Search by Quotation Status" />
                                    </span>
                                    <span v-else-if="col.field == 'description'">
                                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                            placeholder="Search by Description" />
                                    </span>
                                    <span v-if="col.field == 'added_by'">
                                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                            placeholder="Search by Added By" />
                                    </span>
                                    <span v-else-if="col.field == 'date_added'">
                                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                            placeholder="Search by Date Added" />
                                    </span>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <Divider />
                <div class="col-12 lg:col-12">
                    <Toolbar class="pl-0 pr-0">
                        <template v-slot:start>
                            <!-- <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/> -->
                        </template>
                        <template v-slot:end>
                            <Button type="button" label="Cancel" icon="pi pi-times"
                                class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm" />
                        </template>
                    </Toolbar>
                </div>
            </div>

        </Sidebar>
    </BlockUI>
</template>
<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    data() {
        return {
            module_name: 'Invoice History',
            actions: [],
            moduleId: 53,
            showHelp: false,
            showHistoryPage: false,
            isSaving: false,
            records: null,
            filters: null,
            loading: true,
            selectedRecords: null,
            columns: null,
            selectedColumns: null,
            showForm: false,
            showViewForm: false,
            selectedRecordId: 0,
            saveAndClose: false,
        }
    },
    props: {
        invoice_id: {
            type: Number,
        },
        showHistorySidebar: {
            type: Boolean,
        },
    },
    components: {
    },
    created() {
        this.showHistoryPage = this.showHistorySidebar;

        this.initFilters();
        this.columns = [
            { field: 'invoice_status', header: 'Quotation Status' },
            { field: 'description', header: 'Description' },
            { field: 'date_added', header: 'Date Added' },
            { field: 'added_by', header: 'Added By' },
        ];
        this.selectedColumns = this.columns;
    },
    computed: {
    },
    mounted() {
        this.getRecords();
    },
    methods: {
        closeForm() {
            this.showHistoryPage = false;
            this.$emit('closeHistoryPage', true);
        },
        loadForm() {
            this.selectedRecordId = 0;
            this.showForm = true;
        },
        getRecords() {
            var Vthis = this;
            this.loading = true;
            this.axios.post('getInvoiceHistory', { 'invoice_id': this.invoice_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.records = response.data;
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },

        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        onToggle(value) {
            this.selectedColumns = this.columns.filter(col => value.includes(col));
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'invoice_status': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'description': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'added_by': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'date_added': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            }
        },
        clearFilter() {
            this.initFilters();
        },
        isRTL() {
            return this.$appState.RTL;
        }
    },
    watch: {
    }
}
</script> 
