<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="false" @hide="closeForm" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @keydown="form.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{ module_name }}</h3>
                        <p>You can add or update {{ module_name }} here.</p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-4 sm:col-12 mb-2" style="float: left"
                                    v-if="checkPermission(27, 'dropdown')">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Drivers</label>
                                    <Dropdown v-model="form.driver_id" :options="drivers"
                                        :filterFields="['name', 'designation']" optionLabel="name" :filter="true"
                                        placeholder="Select Driver" :showClear="true" class="full-width">
                                        <template #value="slotProps">
                                            <div class="country-item country-item-value" v-if="slotProps.value">
                                                <div style="display: flex">
                                                    <img :src="slotProps.value.img" style="border-radius: 2%" />
                                                    <span style="padding-left: 5px"><strong>{{ slotProps.value.name
                                                    }}</strong><span v-if="slotProps.value.user_category_name"> ({{
    slotProps.value.user_category_name }}) </span><br />
                                                        {{ slotProps.value.email }}</span>
                                                </div>
                                            </div>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                        <template #option="slotProps">
                                            <div class="country-item">
                                                <div style="display: flex">
                                                    <img v-if="slotProps.option.profile_pic"
                                                        :src="filesUrl + 'users/' + slotProps.option.profile_pic"
                                                        style="border-radius: 2%" />
                                                    <img v-else :src="filesUrl + 'defaults/default.png'"
                                                        style="border-radius: 2%; width:30px;" />
                                                    <span style="padding-left: 5px"><strong>{{ slotProps.option.name
                                                    }}</strong><span v-if="slotProps.option.user_category_name"> ({{
    slotProps.option.user_category_name }}) </span><br />
                                                        {{ slotProps.option.email }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </Dropdown>
                                    <!-- <Dropdown
                                        @change="userSelected()"
                                        usersLoading
                                        :filter="true"
                                        :showClear="true"
                                        v-model="form.driver_id"
                                        :options="drivers"
                                        optionLabel="name"
                                        optionValue="id"
                                        :placeholder="usersLoading ? 'Loading...' : 'Select Driver'"
                                        class="full-width"
                                        style="float: left"
                                    /> -->
                                    <HasError class="p-error" :form="form" field="driver_id" />
                                </div>
                                <div class="col-12 lg:col-4 sm:col-12 mb-2" style="float: left"
                                    v-if="checkPermission(27, 'dropdown')">
                                    <label>Driver Price</label>
                                    <InputNumber id="driver_price" v-model="form.driver_price" mode="currency" showButtons
                                        :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency"
                                        style="float: left" locale="en-US" class="full-width" />
                                    <HasError class="p-error" :form="form" field="driver_price" />
                                </div>
                                <div class="col-12 lg:col-4 sm:col-12 mb-2" style="float: left"
                                    v-if="checkPermission(27, 'dropdown')">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Vehicle</label>
                                    <Dropdown vehiclesLoading :filter="true" :showClear="true" v-model="form.vehicle_id"
                                        :options="vehicles" optionLabel="name" optionValue="id"
                                        :placeholder="vehiclesLoading ? 'Loading...' : 'Select Driver'" class="full-width"
                                        style="float: left" />
                                    <HasError class="p-error" :form="form" field="vehicle_id" />
                                </div>

                                <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float: left">
                                    <label>Driver Notes</label>
                                    <Textarea placeholder="Notes" v-model="form.notes" rows="2" cols="30"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="notes" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float: left"
                                    v-if="moves && moves.length">
                                    <span v-for="(move, key) in moves" :key="key">
                                        <div class="col-12 lg:col-4 sm:col-12 mb-2" style="float: left"
                                            v-if="move.assigned_status != 'Yes'">
                                            <Card style="float:left">
                                                <template #title>
                                                    <Checkbox name="moves" :value="move.id" v-model="form.moves"
                                                        style="float:right" />
                                                    <label class="ml-2"><span style="font-size:10px">From</span><br> {{
                                                        move.departure_address }}
                                                    </label>
                                                </template>
                                                <template #content>
                                                    <div class="grid mt-3">
                                                        <div class="col-12 col-md-12">
                                                            <div class="info-row"><strong>To:</strong></div>
                                                            <div class="info-row">{{ move.destination_address }}</div>
                                                        </div>
                                                        <div class="col-6 col-md-6">
                                                            <div class="info-row"><strong>Stops:</strong></div>
                                                            <div class="info-row">{{ move.legs && move.legs > 2 ? move.legs
                                                                - 2
                                                                : 0 }}
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-md-6">
                                                            <div class="info-row"><strong>Distance:</strong></div>
                                                            <div class="info-row">{{ move.journey_distance }}</div>
                                                        </div>
                                                        <div class="col-6 col-md-6">
                                                            <div class="info-row"><strong>Time:</strong></div>
                                                            <div class="info-row">{{ move.journey_duration }}</div>
                                                        </div>
                                                        <div class="col-6 col-md-6">
                                                            <div class="info-row"><strong>Vehicle Type:</strong></div>
                                                            <div class="info-row">{{ move.vehicle_type_name }}</div>
                                                        </div>
                                                        <div class="col-6 col-md-6">
                                                            <div class="info-row"><strong>Passangers:</strong></div>
                                                            <div class="info-row">{{ move.passangers }}</div>
                                                        </div>
                                                        <div class="col-6 col-md-6">
                                                            <div class="info-row"><strong>Base Location:</strong></div>
                                                            <div class="info-row">{{ move.base_location }}</div>
                                                        </div>
                                                        <div class="col-6 col-md-6">
                                                            <div class="info-row"><strong>Journey Price:</strong></div>
                                                            <div class="info-row">{{ defaultCurrency }} {{
                                                                move.journey_price }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </Card>
                                        </div>
                                    </span>
                                </div>
                                <div class="col-12 lg:col-4 sm:col-12 mb-2" style="float: left">
                                    <Checkbox :value="true" id="attach_jobsheet" v-model="form.attach_jobsheet" />
                                    <label class="ml-2">
                                        Attach Job Sheet
                                    </label>
                                </div>
                                <div class="col-12 lg:col-4 sm:col-12 mb-2" style="float: left">
                                    <Checkbox :value="true" id="send_email" v-model="form.send_email" />
                                    <label class="ml-2">
                                        Send Email
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()"
                                    class="p-button-raised p-button-lg p-button-success mr-2" />
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check"
                                    @click="(saveAndClose = true), save()"
                                    class="p-button-raised p-button-lg p-button-info mr-2" />
                                <Button type="button" label="Reset" icon="pi pi-refresh"
                                    class="p-right p-button-raised p-button-lg p-button-warning mr-2"
                                    @click="resetForm()" />
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>
    </BlockUI>
</template>
<script>
export default {
    data() {
        return {
            form: new this.Form({
                id: '',
                order_id: 0,
                company_id: 0,
                driver_id: 0,
                driver_price: 0,
                moves: [],
                send_email: false,
                attach_jobsheet: false,
                driver_notes: '',
            }),

            usersLoading: false,
            CompanyLoading: false,
            userCategoriesLoading: false,
            module_name: 'Assign Driver',
            isSaving: false,
            saveAndClose: false,
            recId: 0,
            loadingComponent: true,
            formVisible: false,
            companies: [],
            drivers: [],
            userCategories: [],
            moves: [],
            vehicles: [],
        };
    },
    props: {
        showForm: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
        orderId: {
            type: Number,
        },
        companyId: {
            type: Number,
        },
    },
    created() { },
    computed: {},
    mounted() {
        this.resetForm();
        this.formVisible = this.showForm;
        this.recId = this.recordId;
        this.form.order_id = this.orderId;
        this.form.company_id = this.companyId;
        this.getAllRequestData();
    },
    methods: {
        getAllRequestData() {
            this.$axios
                .all([this.updateRecord()])
                .then(() => {
                    this.getCompanyUsers();
                    this.getVehicles();
                    // return this.getCompanies();
                })
                .then(() => {
                    this.blockComponent = false;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => { });
        },
        async getVehicles() {
            var Vthis = this;
            Vthis.vehiclesLoading = true;
            await this.axios
                .post('getAllVehicles', { company_id: this.form.company_id, dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.vehicles = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.vehiclesLoading = false;
                });
        },

        async getCompanyUsers() {
            var Vthis = this;
            Vthis.usersLoading = true;
            await this.axios
                .post('getRoleSpecificUsers', { company_id: this.form.company_id, role: 'Driver', dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.drivers = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.usersLoading = false;
                });
        },

        async getCompanies() {
            var Vthis = this;
            Vthis.CompanyLoading = true;
            await this.axios
                .post('getAllCompanies', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.companies = response.data;
                        if (this.form.company_id == 0) {
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.CompanyLoading = false;
                });
        },

        resetForm() {
            this.form.reset();
        },
        async save() {
            var Vthis = this;
            this.isSaving = true;
            console.log(this.form);
            await this.form
                .post(Vthis.$baseurl + 'api/saveOrderDriver', Vthis.form)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.form.reset();
                        // this.getRecords();
                        if (this.saveAndClose) {
                            this.saveAndClose = false;
                            setTimeout(() => {
                                this.closeForm();
                            }, 1000);
                        }
                        Vthis.showMessage('Saved successfully', 'success');
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
        loadForm() {
            this.resetForm();
        },
        closeForm() {
            this.$emit('formClose', true);
        },

        async updateRecord() {
            if (this.orderId > 0) {
                var Vthis = this;
                this.loading = true;
                await this.axios
                    .post('getAllOrderTransportMoves', { id: 0, order_id: this.orderId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            this.moves = response.data;
                            if (this.moves.length) {
                                this.moves.forEach((move) => {
                                    this.estimatedPrice += move.journey_price;
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.loading = false;
                    });
            }
        },
    },
    watch: {},
};
</script>
 
