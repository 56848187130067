<template>
    <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false"
        @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
        <div class="grid">
            <div class="col-12 lg:col-12 mb-0 customer-transection-page">
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start>
                        <h3 class="mb-0">{{ userData.name }}<br><span style="font-size:10px">{{ userData.user_category_name
                        }}</span> </h3>
                    </template>
                    <template v-slot:end>
                        <div v-if="userData.user_balance && userData.user_balance">
                            <h3 v-if="userData.user_balance" style="color:darkorange;" class="mb-0"><small
                                    style="display:block;font-size:11px;color:darkgray">Available Balance</small><span
                                    style="color:black">{{ defaultCurrency ? defaultCurrency : 'USD' }}</span>
                                {{ Number(userData.user_balance).toFixed(priceRounding) }}</h3>
                            <h3 v-else class="mb-0" style="color:darkorange;"><small
                                    style="display:block;font-size:11px;color:darkgray">Available Balance</small><span
                                    style="color:black">{{ defaultCurrency ? defaultCurrency : 'USD' }}</span> 00.00</h3>
                        </div>
                        <div v-else>
                            <h3 class="mb-0" style="color:darkorange;"><small
                                    style="display:block;font-size:11px;color:darkgray">Available Balance</small><span
                                    style="color:black">{{ defaultCurrency ? defaultCurrency : 'USD' }}</span> 00.00</h3>
                        </div>
                    </template>
                </Toolbar>
            </div>
            <Divider class="mt-0" />
            <div class="col-12">
                <div class="card">
                    <Toolbar class="pl-0 pr-0">
                        <template v-slot:start>
                            <Button v-if="checkPermission(moduleId, 'insert')" v-tooltip.top="'Add New'"
                                :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus"
                                :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                class="p-button-raised p-button-primary mr-2" />
                            <Button v-if="checkPermission(moduleId, 'view')" v-tooltip.top="'Refresh'"
                                :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh"
                                :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                class="p-button-raised p-button-success mr-2" @click="getRecords" />
                            <Button v-if="checkPermission(moduleId, 'export')" v-tooltip.top="'Export'"
                                icon="pi pi-external-link"
                                :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''"
                                @click="exportCSV($event)" />
                            <Button v-if="checkPermission(moduleId, 'view')" v-tooltip.top="'Remove Filters'" type="button"
                                icon="pi pi-filter-slash" :label="showBtnLabels ? 'Remove Filters' : ''"
                                :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                class="p-button-raised p-button-info mr-2" @click="clearFilter()" />
                            <!-- <Button v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete Selected'" :label="showBtnLabels ? 'Delete Selected' : ''" icon="pi pi-trash" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-danger mr-2"  @click="deleteRecord" :disabled="!selectedRecords || !selectedRecords.length" /> -->
                        </template>
                        <template v-slot:end>
                            <Button v-if="checkPermission(moduleId, 'view')" v-tooltip.top="'Helping Material'"
                                :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question"
                                :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                class="p-button-raised p-button-info mr-2" />
                        </template>
                    </Toolbar>
                    <DataTable :reorderableColumns="true" @columnReorder="onColReorder" :resizableColumns="true"
                        columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple"
                        class="p-datatable-gridlines p-datatable-sm" :rows="10" dataKey="id" :rowHover="true"
                        v-model:filters="filters" filterDisplay="menu" :loading="loading" :filters="filters"
                        responsiveLayout="scroll"
                        :globalFilterFields="['transection_type', 'transection_id', 'transection_date', 'transection_amount', 'source_name', 'company', 'description', 'added_by', 'modified_by', 'date_added', 'status']"
                        :rowsPerPageOptions="[10, 20, 50, 100]"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        v-model:selection="selectedRecords" selectionMode="multiple" :scrollable="true"
                        scrollHeight="400px">
                        <template #header>
                            <div class="flex justify-content-between flex-column sm:flex-row">
                                <span class="p-input-icon-left mb-2">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters['global'].value" placeholder="Keyword Search"
                                        style="width: 100%" />
                                </span>
                                <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header"
                                    @update:modelValue="onToggle" placeholder="Select Columns" style="width: 20em" />
                            </div>
                        </template>
                        <template #empty>
                            <div class="table-message">
                                <img class="table-message-img" src="layout/images/no-record-found.png"
                                    alt="No record found">
                            </div>
                        </template>
                        <template #loading>
                            Loading data. Please wait.
                        </template>
                        <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                        <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header"
                            :exportable="col.field == 'action' ? false : true"
                            :sortable="col.field == 'action' ? false : true" :key="col.field + '_' + index"
                            :class="col.field == 'action' ? 'btnAction' : ''"
                            :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;' : 'max-width:5rem;flex-grow:1; flex-basis:160px;'">
                            <template #body="{ data }">
                                <img v-if="col.field == 'company' && data.logo" :alt="data.company"
                                    :src="filesUrl + 'companies/' + data.logo" class="tableProfileImg mr-2" />
                                <img v-if="col.field == 'company' && !data.logo" :alt="data.company"
                                    :src="filesUrl + 'defaults/default-logo.png'" class="tableProfileImg mr-2" />
                                <span v-if="col.field == 'company'">{{ data.company }}</span>
                                <span v-if="col.field == 'transection_type'">
                                    <span v-if="data.transection_type == 'credit'" class="pi pi-arrow-down"
                                        style="color:green;font-weight: bold;"></span>&nbsp;
                                    <span v-else class="pi pi-arrow-up" style="color:red;font-weight: bold;"></span>&nbsp;
                                    {{ data.transection_type.charAt(0).toUpperCase() + data.transection_type.slice(1) }}
                                </span>
                                <span v-if="col.field == 'transection_status'">
                                    <Tag v-if="data.transection_status == 'successfull'" class="mr-2" icon="pi pi-check"
                                        severity="success" value="Successfull"></Tag>
                                    <Tag v-else-if="data.transection_status == 'canceled'" class="mr-2" icon="pi pi-times"
                                        severity="danger" value="Canceled"></Tag>
                                </span>
                                <span v-if="col.field == 'transection_id'">{{ data.transection_id }}</span>
                                <span v-if="col.field == 'transection_amount'">{{ defaultCurrency ? defaultCurrency : 'USD'
                                }}
                                    <span v-if="data.transection_amount">{{
                                        Number(data.transection_amount).toFixed(priceRounding) }}</span>
                                    <span v-else>00.00</span>
                                </span>
                                <span v-if="col.field == 'transection_date'">{{ data.transection_date }}</span>
                                <span v-if="col.field == 'source_name'">{{ data.source_name }}</span>
                                <span v-else-if="col.field == 'description'">{{ shortStr(data.description, 0, 50) }}</span>
                                <span v-else-if="col.field == 'date_added'">{{ formatDate(data.date_added) }}</span>
                                <span v-else-if="col.field == 'added_by'">{{ data.added_by }}</span>
                                <span v-else-if="col.field == 'modified_by'">{{ data.modified_by }}</span>
                                <span v-else-if="col.field == 'date_modification'">{{ formatDate(data.date_modification)
                                }}</span>

                                <span v-else-if="col.field == 'action'">
                                    <div v-if="data.transection_status != 'canceled'"
                                        :class="{ 'ml-auto': !isRTL, 'mr-auto': isRTL }">
                                        <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text"
                                            :recordId="data.id" @click="toggleRecordMenu"></Button>
                                        <Menu ref="menu" :popup="true" :model="actions"></Menu>
                                    </div>
                                </span>
                            </template>
                            <template #filter="{ filterModel }">
                                <span v-if="col.field == 'company'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by company name" />
                                </span>
                                <span v-if="col.field == 'transection_type'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by Transection Type" />
                                </span>
                                <span v-if="col.field == 'transection_id'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by Transection ID" />
                                </span>
                                <span v-if="col.field == 'transection_date'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by Transection Date" />
                                </span>
                                <span v-if="col.field == 'transection_status'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by Transection Status" />
                                </span>
                                <span v-if="col.field == 'transection_amount'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by Transection Amount" />
                                </span>
                                <span v-if="col.field == 'source_name'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by Source Name" />
                                </span>
                                <span v-else-if="col.field == 'description'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by about" />
                                </span>
                                <span v-if="col.field == 'added_by'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by name" />
                                </span>
                                <span v-if="col.field == 'modified_by'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by name" />
                                </span>
                                <span v-else-if="col.field == 'date_added'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by Date Added" />
                                </span>
                                <span v-else-if="col.field == 'date_modification'">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Search by Date Modification" />
                                </span>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
            <Divider />
            <div class="col-12 lg:col-12">
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start> </template>
                    <template v-slot:end>
                        <Button type="button" label="Cancel" icon="pi pi-times"
                            class="p-right p-button-raised p-button-lg p-button-danger mr-2"
                            @click="closeTransectionForm" />
                    </template>
                </Toolbar>
            </div>
            <Toast />
            <ConfirmDialog group="dialog1" />
            <!-- form loading -->
            <UserTransectionForm v-on:formClose="closeForm"
                v-if="showForm && (checkPermission(moduleId, 'insert') || checkPermission(moduleId, 'update'))"
                :recordId="selectedRecordId" :user_id="user_id" :showForm="true" />
            <ModuleHelp v-on:closeHelp="showHelp = false" v-if="showHelp && checkPermission(moduleId, 'view')"
                :moduleId="moduleId" />

        </div>
    </Sidebar>
</template>
<script>
import UserTransectionForm from '../../components/AccessManagement/UserTransectionForm.vue';
import ModuleHelp from '../../components/Help/ModuleHelp.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    data() {
        return {
            module_name: 'Customer Transections',
            actions: [],
            moduleId: 65,
            showHelp: false,
            isSaving: false,
            records: null,
            filters: null,
            loading: true,
            selectedRecords: null,
            columns: null,
            selectedColumns: null,
            showForm: false,
            selectedRecordId: 0,
            saveAndClose: false,
            formVisible: false,
            userData: [],
        }
    },
    components: {
        UserTransectionForm,
        ModuleHelp
    },
    created() {
        // if(this.checkPermission(this.moduleId,'update')){
        //     this.actions.push({
        // 			label: 'Update',
        // 			icon: 'pi pi-refresh',
        //             command: () => {
        //                 this.getClickedRecord('update');
        //             }
        // 		});
        // }
        if (this.checkPermission(this.moduleId, 'delete')) {
            this.actions.push({
                label: 'Cancel',
                icon: 'pi pi-times',
                command: () => {
                    this.getClickedRecord('cancel');
                }
            });
        }
        this.initFilters();
        this.columns = [
            // {field: 'action', header: 'Action'},
            { field: 'company', header: 'Company' },
            { field: 'transection_type', header: 'Type' },
            { field: 'transection_amount', header: 'Amount' },
            { field: 'transection_status', header: 'Transaction Status' },
            { field: 'transection_id', header: 'Transection ID' },
            { field: 'source_name', header: 'Source' },
            { field: 'transection_date', header: 'Transection Date' },
            { field: 'description', header: 'Description' },
            { field: 'date_added', header: 'Date Added' },
            { field: 'added_by', header: 'Created By' },
        ];
        this.selectedColumns = this.columns;
    },
    props: {
        showTransectionSidebar: {
            type: Boolean,
            required: true,
        },
        user_id: {
            type: Number,
        },
    },
    computed: {
    },
    mounted() {
        //THIS CODE IS TO TABLE COLUMNS SETTINGS
        this.axios.post('getAllModuleColumnSettings', { 'module_id': this.moduleId })
            .then((response) => {
                if (response.data) {
                    if (Object.keys(response.data).length) {
                        this.columns = [];
                        this.selectedColumns = [];
                        response.data.forEach((column) => {
                            this.columns.push({ 'field': column.column_field, 'header': column.column_head });
                            if (column.display) {
                                this.selectedColumns.push({ 'field': column.column_field, 'header': column.column_head });
                            }
                        });
                    }
                }
            })
            .catch(() => {
            })
            .finally(() => {
            })
        //END

        this.priceRounding = this.userData['company_price_rounding'] ? this.userData['company_price_rounding'] : 2;
        this.formVisible = this.showTransectionSidebar;
        this.getRecords();
        this.getUserData();
        if (this.$route.query.view) {
            this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
            if (Object.keys(this.urlParams).length) {
                this.urlParams.forEach((parameter) => {
                    if (parameter.view == 'add-customerTransaction') {
                        this.selectedRecordId = parameter.id;
                        this.showForm = true;
                    }
                })
            }
        }
    },
    methods: {
        onColReorder(event) {
            this.columns = this.tableColumnOrderChange(event, this.columns);
            this.saveTableColumnOrderChange(this.moduleId, this.columns, this.selectedColumns);
        },
        onToggle(value) {
            //this.selectedColumns = this.columns.filter(col => value.includes(col)); 
            this.selectedColumns = [];
            let newColumns = [];
            this.columns.filter((col) => {
                if (value.find(x => x.field === col.field)) {
                    newColumns.push(col);
                    return col;
                }
            });
            this.selectedColumns = newColumns;
            this.saveTableColumnOrderChange(this.moduleId, this.columns, this.selectedColumns);
        },
        getClickedRecord(action) {
            if (action == 'cancel') {
                this.deleteRecord();
            }
            else if (action == 'view') {
                this.viewRecord();
            }
            else if (action == 'update') {
                this.showForm = true;
                this.addViewInURL({ view: 'add-customerTransaction', id: this.selectedRecordId });
            }
        },
        toggleRecordMenu(event) {
            this.selectedRecordId = event.currentTarget.getAttribute('recordId');
            this.$refs.menu.toggle(event);
        },
        loadForm() {
            this.selectedRecordId = 0;
            this.addViewInURL({ view: 'add-customerTransaction', id: this.selectedRecordId });
            this.showForm = true;
        },
        closeTransectionForm() {
            this.$emit('closeTransectionPage', true);
        },
        closeForm() {
            // this.$emit('closeTransectionPage',true); 
            this.showForm = false;
            this.removeViewFromURL('add-customerTransaction');
            this.getRecords();
            this.getUserData();
        },
        getUserData() {
            var Vthis = this;
            this.axios.post('getAllUsers', { id: this.user_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        Vthis.userData = response.data[0];
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        getRecords() {
            var Vthis = this;
            this.loading = true;
            this.axios.post('getAllPaymentTransections', { user_id: this.user_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.records = response.data;
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        viewRecord() {
            var Vthis = this;
            this.loading = true;
            this.axios.post('getAllPaymentTransections', { id: this.selectedRecordId, user_id: this.user_id })
                .then((response) => {
                    this.printResponseResult(response, false);
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        deleteRecord() {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog1',
                header: 'Confirmation',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loading = true;

                    // let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
                    // filterdRecords.forEach((data) => {
                    //     DeleteAbleRecords.push(data.id);
                    // })  

                    Vthis.axios.post('cancelPaymentTransection', { id: this.selectedRecordId, user_id: this.user_id })
                        .then((response) => {
                            let responseStatus = this.printResponseResult(response, false);
                            if (responseStatus == 200) {
                                Vthis.getRecords();
                                Vthis.getUserData();
                                Vthis.showMessage('canceled successfully', 'success');
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                            Vthis.loading = false;
                        })
                },
                reject: () => {
                    // this.loading = false;
                }
            });
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },


        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'transection_type': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'transection_id': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'transection_amount': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'transection_date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'transection_status': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'source_id': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'company': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'added_by': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'modified_by': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'description': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'date_added': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                'date_modification': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            }
        },
        clearFilter() {
            this.initFilters();
        },
        isRTL() {
            return this.$appState.RTL;
        }
    },
    watch: {
    }
}
</script>
 
