<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>

        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="true" @hide="closeForm" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @keydown="form.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{ module_name }}</h3>
                        <p>You can add or update {{ module_name }} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid layout-invoice-content">
                        <div class="col-12">
                            <div class="card">
                                <div class="p-3 flex justify-content-between">
                                    <div>
                                        <h3>INVOICE</h3>
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">
                                                <i class="pi pi-hashtag"></i>
                                            </span>
                                            <InputText :id="'invoice_number'" placeholder="Invoice Number"
                                                v-model="form.invoice_number" class="full-width" />
                                        </div>

                                    </div>
                                    <div class="flex flex-column align-items-end">
                                        <div v-if="userData.company_logo" style="width:80px">
                                            <img :src="filesUrl + 'companies/' + userData.company_logo" alt="company-logo"
                                                style="width:100%" />
                                        </div>
                                        <h2 v-else>{{ userData.company_name }}</h2>
                                        <p v-if="userData.compnay_address" class="m-0 p-0"><i class="pi pi-map-marker"></i>
                                            <span v-if="userData.country_name">{{ userData.country_name }},</span> <span
                                                v-if="userData.state_name">{{ userData.state_name }},</span> <span
                                                v-if="userData.city_name">{{ userData.city_name }},
                                            </span>{{ userData.compnay_address }}
                                        </p>
                                        <p v-if="userData.phone" class="m-0 p-0"><i class="pi pi-phone"></i>
                                            {{ userData.phone }}</p>
                                        <p v-if="userData.email" class="m-0 p-0"><i class="pi pi-envelope"></i>
                                            {{ userData.email }}</p>
                                        <p v-if="userData.website" class="m-0 p-0"><i class="pi pi-globe"></i>
                                            {{ userData.website }}</p>
                                    </div>
                                </div>


                                <DataTable :value="routesData" class="p-invoice-datatable-responsive py-4">
                                    <template #header>
                                        <h5 class="m-0 p-0">Routes Information</h5>
                                    </template>
                                    <Column v-for="col of routesCols" :field="col.field" :header="col.header"
                                        :key="col.field">
                                        <template #body="{ data }">

                                            <span v-if="col.field == 'transport_route'">
                                                <span v-if="data.routes">
                                                    <span v-for="(route, key) of data.routes" :key="key">
                                                        <span v-if="key > 0">
                                                            <strong>
                                                                <span class="pi pi-arrow-down" style="color: green"></span>
                                                            </strong>
                                                        </span>
                                                        <div class="mb-2 p-2">
                                                            <strong v-if="route.city">{{ route.city }}</strong>
                                                            <strong v-if="route.state">, {{ route.state }}</strong>
                                                            <strong v-if="route.country">, {{ route.country
                                                            }}</strong>
                                                            <br /><small v-if="route.address">{{ route.address
                                                            }}</small> <br /><strong
                                                                v-if="route.stay_hours || route.stay_minutes">Stay
                                                                Time:
                                                                <small v-if="route.stay_hours">{{ route.stay_hours
                                                                }}
                                                                    hours</small> <small v-if="route.stay_minutes">{{
                                                                        route.stay_minutes }} minutes</small>
                                                            </strong>
                                                            <br /><small v-if="route.notes">{{ route.notes
                                                            }}</small>
                                                        </div>
                                                    </span>
                                                    <span>Start Time: <strong>{{ data.start_time }}</strong></span>
                                                    <span> | Passangers: <strong>{{ data.passangers }}</strong></span>
                                                    <span> | Hand Luggage: <strong>{{ data.hand_luggage
                                                    }}</strong></span>
                                                    <span> | Suitcases: <strong>{{ data.suitcases }}</strong></span>
                                                    <span> | Child Seats: <strong>{{ data.child_seats }}</strong></span>
                                                    <span> | Distance: <strong>{{ data.journey_distance ?
                                                        data.journey_distance : 'N/A'
                                                    }}</strong></span>
                                                    <span> | Duration: <strong>{{ data.journey_duration ?
                                                        data.journey_duration : 'N/A' }}</strong></span>
                                                </span>
                                            </span>
                                            <span v-if="col.field == 'price'">
                                                <div class="p-inputgroup">
                                                    <span class="p-inputgroup-addon">{{ currency
                                                    }} </span>
                                                    <InputNumber disabled="true" mode="currency"
                                                        :currency="currency != '' ? currency : 'USD'" locale="en-US"
                                                        placeholder="Price" v-model="data.journey_price" :min="0"
                                                        showButtons class="full-width" />
                                                </div>
                                            </span>

                                        </template>
                                    </Column>
                                </DataTable>

                                <DataTable v-if="productsData" :value="productsData"
                                    class="p-invoice-datatable-responsive py-6">
                                    <template #header>
                                        <h5 class="m-0 p-0">Product Information</h5>
                                    </template>
                                    <Column v-for="col of productsCols" :field="col.field" :header="col.header"
                                        :key="col.field">
                                        <template #body="{ data }">
                                            <div v-if="col.field == 'name'">
                                                <strong v-if="data.product_name">{{ data.product_name }} &nbsp; &nbsp;
                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp; &nbsp; &nbsp;</strong>
                                            </div>
                                            <span v-if="col.field == 'quantity'">{{ data.quantity }}</span>
                                            <span v-if="col.field == 'unit_price'">{{ data.unit_price }}</span>
                                            <span v-if="col.field == 'total_price'">
                                                <div class="p-inputgroup">
                                                    <span class="p-inputgroup-addon">{{ currency }}</span>
                                                    <InputNumber
                                                        :value="Number(data.unit_price * data.quantity).toFixed(priceRounding)"
                                                        mode="currency" :currency="currency != '' ? currency : 'USD'"
                                                        locale="en-US" placeholder="Price"
                                                        v-model="form.productPrice[data.id]" :min="0" showButtons
                                                        class="full-width" />
                                                </div>
                                            </span>
                                        </template>
                                    </Column>
                                </DataTable>


                                <div class="p-3">

                                    <div class="grid" style="display:flex">
                                        <div class="col-12 md:col-7">
                                            <span v-for="(account, key) in companyBankAccounts" :key="key">
                                                <Panel :header="'BANK ' + key + 1" style="height: 100%;">
                                                    <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                        <span>BANK NAME</span>
                                                        <span class="font-bold">{{ account.bank_name }}</span>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                        <span>ACCOUNT BENEFICIARY</span>
                                                        <span class="font-bold">{{ account.acc_title }}</span>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                        <span>ACCOUNT NUMBER</span>
                                                        <span class="font-bold">{{ account.acc_no }}</span>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                        <span>BRANCH CODE</span>
                                                        <span class="font-bold">{{ account.branch_code }}</span>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                        <span>SWIFT</span>
                                                        <span class="font-bold">{{ account.swift_code }}</span>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                        <span>IBAN</span>
                                                        <span class="font-bold">{{ account.iban }}</span>
                                                    </div>
                                                </Panel>
                                            </span>
                                        </div>
                                        <div class="col-12 md:col-5">
                                            <Panel header="TOTAL" style="min-height:167px;">
                                                <div class="grid text-right">
                                                    <div class="col-12" style="border:1px solid red;border-style: dashed;"
                                                        v-if="orderInvoiceData">
                                                        <span v-if="orderInvoiceData.pricingRules">
                                                            <span
                                                                v-if="orderInvoiceData.pricingRules.pricingRulesPriceChange != ''">
                                                                <div class="col-12" style="text-align: center;color:red;">
                                                                    <small>**This information will not be displayed to the
                                                                        client</small>
                                                                </div>
                                                                <div class="col-12" style="text-align: left;">
                                                                    <strong>Pricing Rules Applied:</strong>
                                                                </div>
                                                                <span v-for="(rule, key) in orderInvoiceData.pricingRules"
                                                                    :key="key">
                                                                    <span v-if="rule.changePriceByThisRule">
                                                                        <div class="col-8"
                                                                            style="display: table; float:left">
                                                                            <span
                                                                                style="display: table-cell;vertical-align: middle;"><small><strong>{{
                                                                                    rule.name }}</strong></small>
                                                                            </span>
                                                                        </div>
                                                                        <div class="col-4" style="float:left">
                                                                            <span class="pink-color">
                                                                                {{ currency }}
                                                                                {{
                                                                                    (rule.changePriceByThisRule.toFixed(priceRounding))
                                                                                }}
                                                                            </span>
                                                                        </div>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div class="col-8">
                                                        SUBTOTAL
                                                    </div>
                                                    <div class="col-4">
                                                        <span class="pink-color">{{ currency }}
                                                            {{ Number(form.invoiceTotal).toFixed(2) }}</span>
                                                    </div>
                                                    <div class="col-8" style="display: table;">
                                                        <span
                                                            style="display: table-cell;vertical-align: middle;">DISCOUNT</span>
                                                    </div>
                                                    <div class="col-4">
                                                        <span class="pink-color">
                                                            <div class="p-inputgroup">
                                                                <span class="p-inputgroup-addon">{{ currency }}</span>
                                                                <InputNumber mode="currency"
                                                                    :currency="currency != '' ? currency : 'USD'"
                                                                    locale="en-US" placeholder="Discount Price"
                                                                    v-model="form.discountPrice" :min="0" showButtons
                                                                    class="full-width" />
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <!-- <div class="col-8" style="display: table;">
                                                        <span  style="display: table-cell;vertical-align: middle;">VAT</span>
                                                    </div>
                                                    <div class="col-4">
                                                        <span class="pink-color">
                                                            <div class="p-inputgroup">
                                                                <span class="p-inputgroup-addon">{{currency}}</span>
                                                                <InputNumber  mode="currency" :currency="currency != '' ? currency : 'USD'" locale="en-US"  placeholder="Tax Price" v-model="form.taxPrice" :min="0" showButtons class="full-width"/> 
                                                            </div>
                                                        </span>
                                                    </div>  -->
                                                    <div class="col-12" v-if="orderInvoiceData">
                                                        <span v-if="orderInvoiceData.orderTransportData">
                                                            <span v-if="orderInvoiceData.orderTransportData.tax_rates">
                                                                <span
                                                                    v-for="(tax, key) in orderInvoiceData.orderTransportData.tax_rates"
                                                                    :key="key">
                                                                    <div class="col-8" style="display: table; float:left">
                                                                        <span
                                                                            style="display: table-cell;vertical-align: middle;"><strong>{{
                                                                                tax.name }}
                                                                                -
                                                                                {{ tax.rate }}%</strong><br /><small>{{
                                                                                    tax.code }}</small>
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-4" style="float:left">
                                                                        <span class="pink-color">
                                                                            {{ currency }}
                                                                            {{ Number((tax.rate * form.invoiceTotal) /
                                                                                100).toFixed(priceRounding) }}
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div class="col-8">
                                                        <span>TOTAL PAYABLE</span>
                                                    </div>
                                                    <div class="col-4">
                                                        <h5>
                                                            <strong>
                                                                <span class="pink-color">{{ currency }}
                                                                    {{ Number(form.invoicePayAble).toFixed(2) }}</span>
                                                            </strong>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </div>
                                    </div>
                                    <!-- <div class="grid align-items-stretch vertical-container" v-if="orderInvoiceData.orderTransportData">
                                        <div class=" col-12 md:col-7">
                                            <div class="box box-stretched">
                                                <Panel header="VEHICLE INFORMATION"  style="float:left; width:100%; height:100%">
                                                    <div class="col-6" style="float:left">
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                            <span>VEHICLE TYPE</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.vehicle_type_name">{{orderInvoiceData.orderTransportData.vehicle_type_name}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                            <span>VEHICLE NAME</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.vehicle_name">{{orderInvoiceData.orderTransportData.vehicle_name}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                            <span>PASSANGERS</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.passangers">{{orderInvoiceData.orderTransportData.passangers}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                            <span>LUGGAGE</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.luggage">{{orderInvoiceData.orderTransportData.luggage}} {{luggageUnit}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div> 
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                            <span>FIRST JOURNEY DISTANCE</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.first_journey_distance">{{orderInvoiceData.orderTransportData.first_journey_distance}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                            <span>RETURN JOURNEY DISTANCE</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.return_journey_distance">{{orderInvoiceData.orderTransportData.return_journey_distance}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-6" style="float:left"> 
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                            <span>START DATE TIME</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.start_time">{{formatDateTime(orderInvoiceData.orderTransportData.start_time)}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                            <span>END DATE TIME</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.end_time">{{formatDateTime(orderInvoiceData.orderTransportData.end_time)}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div> 
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2" >
                                                            <span>RETURN DATE TIME</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.return_time">{{formatDateTime(orderInvoiceData.orderTransportData.return_time)}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                            <span>RETURN END DATE TIME</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.return_end_time">{{formatDateTime(orderInvoiceData.orderTransportData.return_end_time)}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div> 
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2" >
                                                            <span>Driver Name</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.driver_name">{{ orderInvoiceData.orderTransportData.driver_name}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-between pt-2 py-2">
                                                            <span>Driver Phone</span>
                                                            <span class="font-bold" v-if="orderInvoiceData.orderTransportData.driver_phone">{{orderInvoiceData.orderTransportData.driver_phone}}</span>
                                                            <span class="font-bold" v-else >NA</span>
                                                        </div> 
                                                    </div>
                                                </Panel> 
                                            </div>
                                        </div>
                                        <div class=" col-12 md:col-5">
                                            <div class="box box-stretched" style="height:100%">
                                                <Panel header="TRANSPORT NOTES"   style="height:100%">
                                                    <p v-if="orderInvoiceData.orderTransportData.notes">{{orderInvoiceData.orderTransportData.notes}} </p>
                                                </Panel> 
                                            </div>
                                        </div> 
                                    </div> -->
                                    <Divider />
                                    <div class="grid" v-if="orderInvoiceData.customerBillingContact">
                                        <div class="col-12 md:col-12">
                                            <div v-if="orderInvoiceData.customerData.email_sending">
                                                <Panel header="SEND TO">
                                                    <div class="col-12 md:col-12">
                                                        <InputText disabled
                                                            :value="orderInvoiceData.customerBillingContact.email"
                                                            class="full-width" />
                                                    </div>
                                                    <div class="col-12 md:col-12">
                                                        <Textarea class="full-width" placeholder="CC TO"
                                                            v-model="form.recipients" rows="5" cols="30" />
                                                        <span><small class="green">You can add multiple recipients emails
                                                                separated by comma (,)</small></span>
                                                    </div>
                                                </Panel>
                                            </div>
                                            <div v-else>
                                                <h5><span style="color:red">**NOTE</span><br> Email sending to this customer
                                                    is blocked. </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid" v-else-if="orderInvoiceData.customerData">
                                        <div class="col-12 md:col-12">
                                            <Panel header="SEND TO">
                                                <div v-if="orderInvoiceData.customerData.email_sending">
                                                    <div class="col-12 md:col-12">
                                                        <InputText disabled :value="orderInvoiceData.customerData.email"
                                                            class="full-width" />
                                                    </div>
                                                    <div class="col-12 md:col-12">
                                                        <Textarea class="full-width" placeholder="CC TO"
                                                            v-model="form.recipients" rows="5" cols="30" />
                                                        <span><small class="green">You can add multiple recipients emails
                                                                separated by comma (,)</small></span>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <h5>Email sending to this customer is blocked. </h5>
                                                </div>
                                            </Panel>
                                        </div>
                                    </div>
                                    <Divider />
                                    <Panel header="Attach Files">
                                        <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                            <FileUpload name="invoice" :showUploadButton="false" :customUpload="true"
                                                @select="myUploader" :multiple="true" :maxFileSize="100000000">
                                            </FileUpload>
                                            <div style="display: flex;" v-if="form.attachments"
                                                class="col-12 lg:col-12 sm:col-12 mb-2">
                                                <div class="col-3 lg:col-3 sm:col-6 mb-2"
                                                    v-for="(file, key) in form.attachments" :key="key">
                                                    <a target="_blank" :href="file.path">
                                                        <span style="font-size: 100px;" class="pi pi-file m-2"></span>
                                                        <span style="font-size: 10px; display: block;">{{ file.name
                                                        }}</span>
                                                    </a>
                                                    <span style="font-size: 10px; display: block;">
                                                        <Button type="button" label="Remove" icon="pi pi-times"
                                                            class="m2 p-right p-button-raised p-button-lg p-button-danger m-4"
                                                            @click="form.attachments.splice(key, 1);" />
                                                    </span>

                                                </div>
                                            </div>

                                        </div>

                                    </Panel>
                                    <div class="col-12 lg:col-12 mb-0" style="float: left">
                                        <label>Invoice Terms</label>
                                        <ckeditor id="editor" @ready="onReady" :editor="editor" placeholder="Invoice Terms"
                                            v-model="form.invoice_terms" :config="editorConfig1" />
                                        <HasError class="p-error" :form="form" field="invoice_terms" />
                                    </div>

                                    <!-- <div class="grid">
                                        <div class="col-12 md:col-12">
                                            <h3>Cargo Details</h3>
                                            <span v-for="(group,key) in cargoGroups" :key="key"> 
                                                <span v-if="group.groupTypes.length">  
                                                    <span v-for="(groupType,index) in group.groupTypes" :key="index"> 
                                                        <span v-if="groupType.quantity"> 
                                                            <h5>{{group.name}}:</h5>
                                                            <div class="col-12 md:col-4 pl-0" style="float:left" v-for="(manifestData,number) in groupType.manifestData" :key="number"> 
                                                                <Panel :header="groupType.name +' '+ (number)" > 
                                                                    <Divider class="mb-1 mt-1" />
                                                                    <div  v-for="(field,Findex) in manifestData" :key="Findex" class="flex align-items-center justify-content-between pt-2 py-2">
                                                                        <span>{{field.label}}</span>
                                                                        <span class="font-bold">{{field.value}}</span>
                                                                    </div> 
                                                                </Panel>
                                                            </div>
                                                        </span>
                                                    </span>
                                                </span> 
                                            </span>
                                        </div>
                                    </div> -->


                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Send" :loading="isSaving" icon="pi pi-check" @click="save()"
                                    class="p-button-raised p-button-lg p-button-success mr-2" />
                                <Button type="submit" label="Send and Close" :loading="isSaving" icon="pi pi-check"
                                    @click="saveAndClose = true, save()"
                                    class="p-button-raised p-button-lg p-button-info mr-2" />
                                <Button type="button" label="Reset" icon="pi pi-refresh"
                                    class="p-right p-button-raised p-button-lg p-button-warning mr-2"
                                    @click="resetForm()" />
                                <!-- <Button type="button" label="Preview" icon="pi pi-eye" class="p-right p-button-raised p-button-lg p-button-help mr-2" @click="InvoicePreview=true"/> -->
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>
    </BlockUI>
</template>
<script>
import ClassicEditor1 from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            form: new this.Form({
                id: '',
                order_id: 0,
                invoice_number: 0,
                invoice_date: '',
                due_date: 0,
                price: 0,
                pricingRulesPriceChange: 0,
                routesPrice: { 'first': 0, 'return': 0 },
                productPrice: [],
                groupItems: [],
                attachments: [],
                invoiceTotal: 0,
                invoicePayAble: 0,
                discountPrice: 0,
                vehicleTypeTax: 0,
                taxPrice: 0,
                invoice_terms: '',
                quotation_terms: '',
                recipients: '',
                invoice: '',
                invoiceId: 0,
                currency: this.userData['company_currency_code'] ? this.userData['company_currency_code'] : 'USD',
                distance_unit: this.userData['company_distance_unit_code'] ? this.userData['company_distance_unit_code'] : 'km',
            }),


            tax_rates: [],
            billData: [
                {
                    'billTo': '',
                    'date': '',
                    'due_date': '',
                }
            ],
            billCols: [
                { field: 'billTo', header: 'BILL TO' },
                { field: 'date', header: 'INVOICE DATE' },
                { field: 'due_date', header: 'DUE DATE' },
            ],

            routesData: [],
            routesCols: [
                { field: 'transport_route', header: 'Transport Route' },
                { field: 'journey_type', header: 'Journey Type' },
                { field: 'price', header: 'Price' },
            ],

            productsData: [],
            productsCols: [
                { field: 'name', header: 'Product' },
                { field: 'quantity', header: 'Quantity' },
                { field: 'unit_price', header: 'Unit Price' },
                { field: 'total_price', header: 'Product Ttoal Price' },
            ],
            InvoicePreview: false,
            module_name: 'Order Invoices',
            isSaving: false,
            saveAndClose: false,
            recId: 0,
            loadingComponent: true,
            formVisible: false,
            orderInvoiceData: [],
            invoiceData: [],
            companyBankAccounts: [],
            cargoGroups: [],
            groupItems: [],
            todayDate: '',
            minDateTime: '',
            CargoGroupLoading: false,
            currency: this.userData['company_currency_code'] ? this.userData['company_currency_code'] : 'USD',
            distanceUnit: this.userData['company_distance_unit_code'] ? this.userData['company_distance_unit_code'] : 'km',
            editor: ClassicEditor1,
            onReady: false,
        }
    },
    props: {
        showForm: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
        orderId: {
            type: Number,
        },
        quotationId: {
            type: Number,
        },
    },
    created() {
    },
    mounted() {

        setTimeout(() => {
            this.form.due_date = new Date();
            const today = new Date();
            const invoice_date = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
            this.form.invoice_date = invoice_date;
            this.form.invoice_date = this.formatDate(this.form.invoice_date);

            if (this.userData['compnay_deposited_invoice_due_date_days']) {
                if (this.userData['compnay_deposited_invoice_due_date_var'] == 'afterRaised') {
                    this.form.due_date.setDate(this.form.due_date.getDate() + this.userData['compnay_deposited_invoice_due_date_days']);
                }
            }

        }, 2000);



        this.priceRounding = this.userData['company_price_rounding'] ? this.userData['company_price_rounding'] : 2,
            // console.log(this.userData);
            this.luggageUnit = 'KG';
        this.resetForm();
        this.formVisible = this.showForm;
        this.recId = this.recordId;
        this.form.invoiceId = this.recordId;
        this.form.order_id = this.orderId;
        this.getAllRequestData();
    },
    computed: {

    },
    methods: {
        printInvoice() {
            window.print();
        },
        myUploader(event) {
            this.form.invoice = event.files;
        },
        countTotal() {
            this.form.invoiceTotal = 0;
            if (this.form.productPrice) {
                if (this.form.productPrice.length) {
                    this.form.productPrice.forEach((productPrice, productId) => {
                        // console.log(productId + " =>> $"+ productPrice);
                        this.form.invoiceTotal += productPrice;
                        // this.form.invoiceTotal += product.price;
                    });
                }
            }

            if (this.routesData) {
                this.routesData.forEach((transportData) => {
                    this.form.invoiceTotal += transportData.journey_price;
                });
            }

            this.form.invoiceTotal = this.form.invoiceTotal + this.form.pricingRulesPriceChange;
            this.form.invoicePayAble = this.form.invoiceTotal - this.form.discountPrice;
        },

        startDateTimeSelected($event) {
            this.form.due_date = '';
            var selectedDate = new Date($event);
            this.minDateTime = selectedDate;
            this.form.due_date = new Date($event);
            this.form.invoice_date = (selectedDate.getMonth() + 1) + '/' + (selectedDate.getDate()) + '/' + selectedDate.getFullYear();
            this.form.invoice_date = this.formatDate(this.form.invoice_date);
            if (this.userData['compnay_deposited_invoice_due_date_days']) {
                if (this.userData['compnay_deposited_invoice_due_date_var'] == 'afterRaised') {
                    this.form.due_date.setDate(this.form.due_date.getDate() + this.userData['compnay_deposited_invoice_due_date_days']);

                }
            }

            this.form.due_date = this.formatDate(this.form.due_date);
        },
        endDateTimeSelected($event) {
            var selectedDate = new Date($event);
            this.form.due_date = this.formatDate((selectedDate.getMonth() + 1) + '/' + (selectedDate.getDate()) + '/' + selectedDate.getFullYear());
            this.form.due_date = this.formatDate(this.form.due_date);
        },
        currentDate() {
            const today = new Date();
            const date = (today.getMonth() + 1) + '/' + (today.getDate()) + '/' + today.getFullYear();
            this.todayDate = this.formatDate(date);
        },
        getAllRequestData() {
            this.$axios.all([this.getOrderInvoiceData()])
                .then(() => {
                    this.getCargoGroupForOrders();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                });
        },

        async getCargoGroupForOrders() {
            var Vthis = this;
            Vthis.CargoGroupLoading = true;
            await this.axios.post('getAllVehiclesCargoGroupForOrders', { orderId: this.orderId })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        Vthis.cargoGroups = response.data;
                        if (Vthis.cargoGroups.length) {
                            Vthis.cargoGroups.forEach((group) => {
                                Vthis.groupItems[group.id] = [];
                                group.groupTypes.forEach((groupType) => {
                                    Vthis.groupItems[group.id][groupType.id] = { 'quantity': groupType.quantity, 'manifest_id': groupType.manifest_id, 'manifestData': groupType.manifestData };
                                    if (groupType.manifest_id > 0) {
                                        Vthis.getManifestData(group.id, groupType.id);
                                    }
                                });
                            });
                        }
                    }
                    // console.log(Vthis.cargoGroups);
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.CargoGroupLoading = false;
                })
        },
        async getManifestData($group, $groupType) {
            if (this.groupItems[$group][$groupType].manifest_id > 0) {
                var Vthis = this;
                var $manifest_id = this.groupItems[$group][$groupType].manifest_id;
                await this.axios.post('getAllManifestFields', { 'cargo_group_id': $group, 'manifest_id': $manifest_id })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            if (response.data.length) {
                                if (this.groupItems[$group][$groupType].quantity > 0) {
                                    // if(!Vthis.groupItems[$group][$groupType].manifestData){
                                    //     Vthis.groupItems[$group][$groupType].manifestData = []; 
                                    // }
                                    // for(var i=0; i<=this.groupItems[$group][$groupType].quantity;i++){ 
                                    //     if(!Vthis.groupItems[$group][$groupType].manifestData[i]){
                                    //         Vthis.groupItems[$group][$groupType].manifestData[i] = [];
                                    //     }
                                    //     response.data.forEach( (rec,j) => {  
                                    //         if(!Vthis.groupItems[$group][$groupType].manifestData[i][j]){
                                    //             Vthis.groupItems[$group][$groupType].manifestData[i][j] = rec; 
                                    //         } 
                                    //     }); 
                                    // } 

                                    this.cargoGroups.forEach((group) => {
                                        if (group.id == $group) {
                                            group.groupTypes.forEach((groupType) => {
                                                if (groupType.id == $groupType) {
                                                    response.data.forEach((rec, i) => {
                                                        groupType.manifestRec[i] = rec;
                                                    });
                                                }
                                            })
                                        }
                                    });
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.CompanyLoading = false;
                    })
            }
        },

        async getOrderInvoiceData() {
            if (this.form.order_id) {
                var Vthis = this;
                await this.axios.post('getAllOrderInvoiceData', { order: this.form.order_id, invoiceId: this.recordId, quotationId: this.quotationId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            this.orderInvoiceData = response.data;
                            this.invoiceData = response.data.invoiceData;
                            this.form.invoice_number = this.orderInvoiceData.invoiceNumber;
                            this.routesData = this.orderInvoiceData.orderTransportData;
                            this.companyBankAccounts = this.orderInvoiceData.companyBankAccounts;
                            if (this.orderInvoiceData.orderProducts) {
                                if (this.orderInvoiceData.orderProducts.length) {
                                    this.orderInvoiceData.orderProducts.forEach((p) => {
                                        Vthis.form.productPrice[p.id] = [];
                                        Vthis.form.productPrice[p.id] = p.price;
                                    });
                                }
                            }

                            if (this.orderInvoiceData.order) {
                                if (this.orderInvoiceData.order['invoice_terms']) {
                                    this.invoice_terms = this.orderInvoiceData.order['invoice_terms'];
                                }
                            }

                            if (this.orderInvoiceData.company_data) {
                                if (this.orderInvoiceData.company_data['currency']) {
                                    this.currency = this.orderInvoiceData.company_data['currency'];
                                }
                                if (this.orderInvoiceData.company_data['distance_unit']) {
                                    this.distance_unit = this.orderInvoiceData.company_data['distance_unit'];
                                }
                                if (this.orderInvoiceData.company_data['quotation_terms'] && (!this.quotation_terms || this.quotation_terms == '')) {
                                    this.quotation_terms = this.orderInvoiceData.company_data['quotation_terms'];
                                }
                                if (this.orderInvoiceData.company_data['invoice_terms']) {
                                    this.invoice_terms = this.orderInvoiceData.company_data['invoice_terms'];
                                }
                            }

                            if (this.orderInvoiceData.pricingRulesPriceChange != '') {
                                this.form.pricingRulesPriceChange = this.orderInvoiceData.pricingRulesPriceChange;
                            }

                            if (this.orderInvoiceData.invoiceData) {

                                this.invoiceData = this.orderInvoiceData.invoiceData;
                                const today = new Date(this.invoiceData.invoice_date);
                                const invoice_date = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
                                this.form.invoice_date = invoice_date;

                                const nextDay = new Date(this.invoiceData.due_date);
                                const due_date = nextDay.getMonth() + 1 + '/' + nextDay.getDate() + '/' + nextDay.getFullYear();
                                this.form.due_date = due_date;
                                this.form.price = this.invoiceData.amount ? this.invoiceData.amount : 0;
                                this.form.discountPrice = this.invoiceData.discount ? this.invoiceData.discount : 0;
                                this.form.taxPrice = this.invoiceData.tax ? this.invoiceData.tax : 0;

                                if (this.orderInvoiceData.recipients) {
                                    if (this.orderInvoiceData.recipients.length) {
                                        this.orderInvoiceData.recipients.forEach((recipient, key) => {
                                            if (recipient && recipient !== '') {
                                                this.form.recipients += recipient;
                                                if (key + 1 < this.orderInvoiceData.recipients.length) {
                                                    this.form.recipients += ',';
                                                }
                                            }
                                        });
                                    }
                                }

                                if (this.quotationData.attachments) {
                                    // console.log(typeof this.quotationData.attachments);
                                    // console.log(this.quotationData.attachments);
                                    // this.form.attachments = this.quotationData.attachments;
                                    this.quotationData.attachments.forEach((attachment) => {
                                        this.form.attachments.push(attachment);
                                    });
                                    // this.form.attachments = Object.entries(this.orderQuotationData.attachments);
                                }
                            }
                            if (response.data.customerContacts) {
                                // this.form.recipients = response.data.customerData.email;
                                if (response.data.customerContacts.length) {
                                    response.data.customerContacts.forEach((contact, key) => {
                                        if (contact.email && contact.email !== response.data.customerData.email && contact.email != '') {
                                            if (key + 1 == Object.keys(response.data.customerContacts).length) {
                                                this.form.recipients += contact.email;
                                            } else {
                                                this.form.recipients += contact.email + ',';
                                            }
                                        }
                                    });
                                }
                            }


                            this.countTotal();
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.ProductCategoriesLoading = false;
                    })
            }
        },

        resetForm() {
            this.form.reset();
        },
        async save() {
            var Vthis = this;
            this.isSaving = true;
            this.form.currency = this.currency;
            this.form.distance_unit = this.distanceUnit;
            this.form.file_attachments = JSON.stringify(this.form.attachments);
            await this.form
                .post(Vthis.$baseurl + 'api/saveOrderInvoice', Vthis.form)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response);
                    if (responseStatus == 200) {
                        window.open(Vthis.$baseurl + response.data.invoic_link, '_blank').focus();
                        if (this.saveAndClose) {
                            this.saveAndClose = false;
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                    // let message = Vthis.customError(Vthis.form.errors.has('error') ? Vthis.form.errors.get('error') : error  ); 
                    // Vthis.showMessage(message,'error'); 
                })
                .finally(() => {
                    this.isSaving = false;
                })
        },
        loadForm() {
            this.resetForm();
        },
        closeForm() {
            this.$emit('formClose', true);
        },
    },
    watch: {
        'form.productPrice': {
            handler() {
                this.countTotal();
            },
            deep: true,
        },
        'form.routesPrice': {
            handler() {
                this.countTotal();
            },
            deep: true,
        },
        'form.discountPrice': {
            handler() {
                this.countTotal();
            },
            deep: true,
        },
        'form.taxPrice': {
            handler() {
                this.countTotal();
            },
            deep: true,
        },

        'form.invoiceTotal': function (newVal, oldVal) {
            // handler() {  
            this.form.vehicleTypeTax = 0;
            if (this.tax_rates) {
                this.tax_rates.forEach((tax) => {
                    this.form.vehicleTypeTax = Number(this.form.vehicleTypeTax) + Number(((tax.rate * newVal) / 100).toFixed(this.priceRounding));
                })
            }
            this.countTotal();
            // },
            // deep: true,
        },
    }
}
</script>
 
