<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="false" @hide="closeForm" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @keydown="form.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{ module_name }}</h3>
                        <p>You can add or update {{ module_name }} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"
                                    v-if="checkPermission(28, 'dropdown')">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Product Category</label>
                                    <Dropdown @change="getCategoryProducts()" ProductCategoriesLoading :filter="true"
                                        :showClear="true" v-model="form.category_id" :options="ProductCategories"
                                        optionLabel="name" optionValue="id"
                                        :placeholder="ProductCategoriesLoading ? 'Loading...' : 'Select Category'"
                                        class="full-width" style="float:left" />
                                    <HasError class="p-error" :form="form" field="category_id" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"
                                    v-if="checkPermission(27, 'dropdown')">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Products</label>
                                    <Dropdown @change="checkProductQuantity()" ProductsLoading :filter="true"
                                        :showClear="true" v-model="form.product_id" :options="catProducts"
                                        optionLabel="name" optionValue="id"
                                        :placeholder="ProductsLoading ? 'Loading...' : 'Select Category'" class="full-width"
                                        style="float:left" />
                                    <HasError class="p-error" :form="form" field="product_id" />
                                </div>
                                <div v-if="isQuantity">
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left">
                                        <label>Quantity</label>
                                        <div class="p-inputgroup">
                                            <InputNumber @blur="calculatePrice()" id="quantity" showButtons
                                                v-model="form.quantity" :min="0" :max="form.max_quantity" class="full-width"
                                                style="float:left" />
                                        </div>
                                        <HasError class="p-error" :form="form" field="quantity" />
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left">
                                        <label>Unit Price</label>
                                        <InputNumber @blur="calculatePrice()" id="unit_price" v-model="form.unit_price"
                                            mode="currency" showButtons
                                            :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency"
                                            style="float:left" locale="en-US" class="full-width" />
                                        <HasError class="p-error" :form="form" field="unit_price" />
                                    </div>
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left">
                                    <label>Price</label>
                                    <InputNumber id="price" v-model="form.price" mode="currency" showButtons
                                        :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency"
                                        style="float:left" locale="en-US" class="full-width" />
                                    <HasError class="p-error" :form="form" field="price" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left">
                                    <label>Notes</label>
                                    <Textarea placeholder="Notes" v-model="form.notes" rows="5" cols="30"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="notes" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()"
                                    class="p-button-raised p-button-lg p-button-success mr-2" />
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check"
                                    @click="saveAndClose = true, save()"
                                    class="p-button-raised p-button-lg p-button-info mr-2" />
                                <Button type="button" label="Reset" icon="pi pi-refresh"
                                    class="p-right p-button-raised p-button-lg p-button-warning mr-2"
                                    @click="resetForm()" />
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>
    </BlockUI>
</template>
<script>
export default {
    data() {
        return {
            form: new this.Form({
                id: '',
                order_id: 0,
                company_id: 0,
                category_id: 0,
                product_id: 0,
                price: 0,
                max_quantity: 0,
                quantity: 0,
                unit_price: 0,
                notes: '',
            }),
            isQuantity: false,
            ProductsLoading: false,
            CompanyLoading: false,
            ProductCategoriesLoading: false,
            module_name: 'Order Product',
            isSaving: false,
            saveAndClose: false,
            recId: 0,
            loadingComponent: true,
            formVisible: false,
            companies: [],
            catProducts: [],
            ProductCategories: [],
        }
    },
    props: {
        showForm: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
        orderId: {
            type: Number,
        },
    },
    created() {
    },
    computed: {
    },
    mounted() {
        this.resetForm();
        this.formVisible = this.showForm;
        this.recId = this.recordId;
        this.form.order_id = this.orderId;
        this.getAllRequestData();
    },
    methods: {
        calculatePrice() {
            setTimeout(() => {
                this.form.price = this.form.quantity * this.form.unit_price;
            }, 300)
        },
        checkProductQuantity() {
            this.isQuantity = false;
            if (this.form.product_id) {
                this.catProducts.forEach(rec => {
                    if (rec.id === this.form.product_id) {
                        if (rec.max_quantity && rec.max_quantity !== undefined) {
                            this.form.max_quantity = rec.max_quantity;
                            this.isQuantity = true;
                            return
                        }
                    }
                });
            }
        },
        getAllRequestData() {
            this.$axios.all([this.updateRecord()])
                .then(() => {
                    this.getCompanies();
                    this.getProductCategories();
                })
                .then(() => {
                    this.getCategoryProducts();
                    this.blockComponent = false;
                }).catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                });
        },
        async getCategoryProducts() {
            this.isQuantity = false;
            if (this.form.category_id) {
                var Vthis = this;
                Vthis.ProductCategoriesLoading = true;
                await this.axios.post('getAllProducts', { category: this.form.category_id, dropdown: true })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            this.catProducts = response.data;
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.ProductCategoriesLoading = false;
                    })
            }
        },

        async getCompanies() {
            var Vthis = this;
            Vthis.CompanyLoading = true;
            await this.axios.post('getAllCompanies', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.companies = response.data;
                        if (this.form.company_id == 0) {
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.CompanyLoading = false;
                })
        },

        async getProductCategories() {
            var Vthis = this;
            Vthis.ProductCategoriesLoading = true;
            await this.axios.post('getAllProductCategories', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.ProductCategories = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.ProductCategoriesLoading = false;
                })
        },

        resetForm() {
            this.form.reset();
        },
        async save() {
            var Vthis = this;
            this.isSaving = true;
            await this.form
                .post(Vthis.$baseurl + 'api/saveOrderProduct', Vthis.form)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.form.reset();
                        // this.getRecords();
                        if (this.saveAndClose) {
                            this.saveAndClose = false;
                            setTimeout(() => { this.closeForm(); }, 1000);
                        }
                        Vthis.showMessage('Saved successfully', 'success',);
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false;
                })
        },
        loadForm() {
            this.resetForm();
        },
        closeForm() {
            this.$emit('formClose', true);
        },

        async updateRecord() {
            if (this.recId > 0) {
                var Vthis = this;
                this.loading = true;
                await this.axios.post('getAllOrderProducts', { id: this.recId, orderId: this.orderId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            Vthis.form.fill(response.data[0]);
                            Vthis.checkProductQuantity();
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.loading = false;
                    })
            }
        },
    },
    watch: {

    }
}
</script>
 
