<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <ConfirmDialog group="dialog1"></ConfirmDialog>
        <ConfirmDialog group="dialog2"></ConfirmDialog>
        <!-- <Sidebar v-model:visible="formVisible" :baseZIndex="101" :autoZIndex="true" :dismissable="false" :showCloseIcon="true" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">  -->
        <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
            <div class="mt-0 mb-0">
                <!-- <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update {{module_name}} here. </p> 
                    </div>
                    <Divider class="mt-0" /> -->
                <div class="grid" :class="[$appState.modelClass]">
                    <div class="col-12 lg:col-12 mb-0 p-0 pt-4">
                        <div class="col-12 lg:col-12 mb-0 p-0 pt-4 grid" style="padding: 10px">
                            <div class="col-6 lg:col-6 mb-0 p-2">
                                <h2>Order ID# {{ orderData.order_number }}
                                    <Tag severity="primary" class=" ml-3" v-if="orderData.order_status === 'New'"
                                        style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                        :value="orderData.order_status">
                                    </Tag>
                                    <Tag severity="warning" class=" ml-3" v-else-if="orderData.order_status === 'Pending'"
                                        style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                        :value="orderData.order_status">
                                    </Tag>
                                    <Tag severity="success" class=" ml-3" v-else-if="orderData.order_status === 'Confirmed'"
                                        style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                        :value="orderData.order_status">
                                    </Tag>
                                    <Tag severity="danger" class=" ml-3" v-else-if="orderData.order_status === 'Canceled'"
                                        style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                        :value="orderData.order_status">
                                    </Tag>
                                    <br><span style="font-size:15px;"><small>{{ orderData.company }}</small></span>
                                </h2>
                            </div>
                            <div class="col-6 lg:col-6 mb-0 p-2">
                                <div class="grid float-right mr-4">
                                    <Avatar v-if="orderData.customer_profile_pic"
                                        :image="filesUrl + 'users/' + orderData.customer_profile_pic" class="mr-2"
                                        size="xlarge" shape="circle" />
                                    <Avatar v-else :image="filesUrl + 'defaults/default.png'" class="mr-2" size="xlarge"
                                        shape="circle" />
                                    <h4 class="mt-0">{{ orderData.customer_name }} <br><small style="font-size:11px">{{
                                        orderData.customer_email }}</small>
                                    </h4>

                                </div>
                            </div>
                        </div>

                        <div class="col-12 lg:col-12 mb-0 p-3 grid">
                            <div class="col-4 lg:col-4 mb-0 p-2">
                                <Button v-if="!viewableOnly" type="button" label="Create Move" icon="pi pi-plus"
                                    @click="openMoveForm()" class="p-button-raised p-button-lg p-button-success mr-2" />
                            </div>
                            <div class="col-4 lg:col-4 mb-0 p-2">
                                <label v-if="!viewableOnly && !checkUserCoreRole(['Customer'])">
                                    <h4>Calcualte price automatically?
                                        <InputSwitch v-model="form.price_auto_calculation" />
                                    </h4>
                                </label>
                            </div>
                            <div class="col-4 lg:col-4 mb-0 pr-4 text-right">
                                <h3 v-if="orderPrice > 0">Order Price : {{ orderPrice }}</h3>
                                <h3 v-else-if="quotedPrice > 0">Quoted Price : {{ quotedPrice }}</h3>
                                <h3 v-else-if="estimatedPrice > 0">Estimated Price : {{ estimatedPrice }}</h3>
                            </div>
                        </div>
                        <div class="col-7 lg:col-8 mb-0 p-3 grid" style="float:left">
                            <div v-if="moves && moves.length" style="float:left">
                                <div class="col-6 lg:col-6 mb-0 p-2" v-for="(move, key) in moves" :key="key"
                                    style="float:left">
                                    <Panel :header="formatDateTime(move.start_time)" :toggleable="true">
                                        <template #icons>
                                            <span v-if="move.assigned_status == 'No'">
                                                <button class="p-panel-header-icon p-link mr-2" :moveId="move.id"
                                                    @click="toggleMoveMenu">
                                                    <span class="pi pi-cog"></span>
                                                </button>
                                                <Menu id="config_menu" ref="menu" :model="MoveMenue" :popup="true" />
                                            </span>
                                            <span v-else>
                                                <Tag class="p-panel-header-icon p-link mr-2" severity="primary"
                                                    style="font-size:12px;padding: 5px 15px !important;border-radius: 50px; font-weight:bold; color:red !important;">
                                                    <span class="pi pi-check"></span>
                                                </Tag>
                                            </span>
                                        </template>
                                        <div class="grid mt-3">
                                            <div class="col-6 col-md-6">
                                                <div class="info-row"><strong>From:</strong></div>
                                                <div class="info-row">{{ move.departure_address }}</div>
                                            </div>
                                            <div class="col-6 col-md-6">
                                                <div class="info-row"><strong>To:</strong></div>
                                                <div class="info-row">{{ move.destination_address }}</div>
                                            </div>
                                            <div class="col-6 col-md-6">
                                                <div class="info-row"><strong>Stops:</strong></div>
                                                <div class="info-row">{{ move.legs && move.legs > 2 ? move.legs - 2 : 0 }}
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-6">
                                                <div class="info-row"><strong>Distance:</strong></div>
                                                <div class="info-row">{{ move.journey_distance }}</div>
                                            </div>
                                            <div class="col-6 col-md-6">
                                                <div class="info-row"><strong>Time:</strong></div>
                                                <div class="info-row">{{ move.journey_duration }}</div>
                                            </div>
                                            <div class="col-6 col-md-6">
                                                <div class="info-row"><strong>Vehicle Type:</strong></div>
                                                <div class="info-row">{{ move.vehicle_type_name }}</div>
                                            </div>
                                            <div class="col-6 col-md-6">
                                                <div class="info-row"><strong>Vehicle:</strong></div>
                                                <div class="info-row">{{ move.vehicle_name }}</div>
                                            </div>
                                            <div class="col-6 col-md-6">
                                                <div class="info-row"><strong>Passangers:</strong></div>
                                                <div class="info-row">{{ move.passangers }}</div>
                                            </div>
                                            <div class="col-6 col-md-6">
                                                <div class="info-row"><strong>Driver:</strong></div>
                                                <div class="info-row">{{ move.driver_name }}</div>
                                            </div>
                                            <div class="col-6 col-md-6">
                                                <div class="info-row"><strong>Base Location:</strong></div>
                                                <div class="info-row">{{ move.base_location }}</div>
                                            </div>
                                            <div class="col-6 col-md-6">
                                                <div class="info-row"><strong>Journey Price:</strong></div>
                                                <div class="info-row">{{ defaultCurrency }} {{ move.journey_price }}</div>
                                            </div>
                                        </div>
                                    </Panel>
                                </div>
                            </div>
                            <div v-else class="col-6 lg:col-6 mb-0 p-2" style="float:left">
                                <h3>No Move Saved!</h3>
                            </div>
                            <div class="col-12 lg:col-12 mb-0" style="float: left">
                                <label>Quotation Terms</label>
                                <ckeditor id="editor" @ready="onReady" :editor="editor" placeholder="Quotation Terms"
                                    v-model="form.quotation_terms" :config="editorConfig1" />
                                <HasError class="p-error" :form="form" field="quotation_terms" />
                            </div>
                            <div class="col-12 lg:col-12 mb-0" style="float: left">
                                <label>Invoice Terms</label>
                                <ckeditor id="editor" @ready="onReady" :editor="editor" placeholder="Invoice Terms"
                                    v-model="form.invoice_terms" :config="editorConfig1" />
                                <HasError class="p-error" :form="form" field="invoice_terms" />
                            </div>
                        </div>

                        <div class="col-5 lg:col-4 mb-0 p-3 grid" style="float:left">
                            <!-- <div class="p-2"> -->
                            <div class="col-12 lg:col-12 sm:col-12 mb-1 text-center" style="float: left">
                                <Panel class="mb-3" header="Products" :toggleable="true" style="float: left; width: 100%">
                                    <Button v-if="checkPermission(42, 'insert')" v-tooltip.top="'Add Product'"
                                        :label="showBtnLabels ? 'Add Product' : ''"
                                        @click="(showProductForm = true), (selectedProductId = 0)" icon="pi pi-plus"
                                        :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                        class="p-button-raised p-button-primary mr-2" />
                                    <Divider />
                                    <div style="display: table; width: 100%"
                                        v-if="orderProducts && Object.keys(orderProducts).length">
                                        <div class="col-12 lg:col-6 md:col-6 mb-0" v-for="(product, pkey) in orderProducts"
                                            :key="pkey" style="float: left; display: table-cell">
                                            <Card class="mb-3" style="height: 100%">
                                                <template #title>
                                                    {{ product.product_name }}
                                                    <div style="float: right" v-if="!viewableOnly">
                                                        <Button icon="pi pi-ellipsis-h" :productId="product.id"
                                                            class="p-button-rounded p-button-text p-button-plain"
                                                            @click="toggleProductMenu"></Button>
                                                        <Menu :popup="true" ref="menu2" :model="productsMenu"></Menu>
                                                    </div>
                                                </template>
                                                <template #subtitle>
                                                    {{ product.product_category_name }}
                                                </template>
                                                <template #content>
                                                    <!-- <img v-if="slotProps.item.image" :src="'assets/showcase/images/demo/product/' + slotProps.item.image" :alt="slotProps.item.name" width="200" class="shadow-2" /> -->
                                                    <p class="m-1"><strong>Quantity :</strong> {{
                                                        product.quantity }}</p>
                                                    <p class="m-1"><strong>Unit Price :</strong> {{ defaultCurrency }}
                                                        {{ product.unit_price }}</p>
                                                    <p class="m-1"><strong>Price :</strong> {{ defaultCurrency }} {{
                                                        product.price }}</p>
                                                    <p class="m-1">{{ product.notes }}</p>
                                                </template>
                                            </Card>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                            <div class="col-12 lg:col-12 sm:col-12 mb-1 text-center" style="float: left"
                                v-if="!checkUserCoreRole(['Customer'])">
                                <Panel class="mb-3" header="Quotations" :toggleable="true" style="float: left; width: 100%">
                                    <Button v-if="checkPermission(40, 'insert')" v-tooltip.top="'Generte Quotation'"
                                        :label="showBtnLabels ? 'Generate Quotation' : ''"
                                        @click="(showQuotationForm = true), (selectedQuotationId = 0)" icon="pi pi-plus"
                                        :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                        class="p-button-raised p-button-primary mr-2" />
                                    <Divider />
                                    <div style="display: table; width: 100%"
                                        v-if="orderQuotation && Object.keys(orderQuotation).length">
                                        <div :class="orderQuotation.quotation_status.toLowerCase() + ' col-12 lg:col-12 md:col-12 mb-0'"
                                            style="float: left; display: table-cell">

                                            <div class="grid mt-3">
                                                <div class="col-12 col-md-12 grid text-right" style="padding: 4px 15px;">
                                                    <button class="p-panel-header-icon p-link mr-2"
                                                        :quotationId="orderQuotation.id" @click="toggleQuotationMenu">
                                                        <span class="pi pi-cog"></span>
                                                    </button>
                                                    <Menu id="quotation_menu" ref="menu4" :model="QuotationMenue"
                                                        :popup="true" />
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 pt-0" style="float:left">
                                                        <h2>#{{ orderQuotation.quotation_number }}</h2>
                                                    </div>
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 pt-0" style="float:left">
                                                        <Tag severity="primary" class=" ml-3"
                                                            v-if="orderQuotation.quotation_status === 'Seen'"
                                                            style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                            :value="orderQuotation.quotation_status">
                                                        </Tag>
                                                        <Tag severity="warning" class=" ml-3"
                                                            v-else-if="orderQuotation.quotation_status === 'Pending'"
                                                            style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                            :value="orderQuotation.quotation_status">
                                                        </Tag>
                                                        <Tag severity="success" class=" ml-3"
                                                            v-else-if="orderQuotation.quotation_status === 'Accepted'"
                                                            style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                            :value="orderQuotation.quotation_status">
                                                        </Tag>
                                                        <Tag severity="danger" class=" ml-3"
                                                            v-else-if="orderQuotation.quotation_status === 'Canceled'"
                                                            style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                            :value="orderQuotation.quotation_status">
                                                        </Tag>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">

                                                    </div>
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        <h4 class="mb-0">{{ orderQuotation.currency }}{{
                                                            orderQuotation.amount }}</h4>
                                                        <span>Payable</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        <strong>Due Date:</strong>
                                                    </div>
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        {{
                                                            formatDate(orderQuotation.due_date) }}</div>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        <strong>Quotation Date:</strong>
                                                    </div>
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        {{
                                                            formatDate(orderQuotation.quotation_date) }}
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        <strong>Sent Date:</strong>
                                                    </div>
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        {{
                                                            formatDate(orderQuotation.sent_date) }}</div>
                                                </div>
                                                <div class="col-12 col-md-12 text-center">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                            <div class="col-12 lg:col-12 sm:col-12 mb-1 text-center" style="float: left"
                                v-if="!checkUserCoreRole(['Customer'])">
                                <Panel class="mb-3" header="Invoices" :toggleable="true" style="float: left; width: 100%">
                                    <Button v-if="checkPermission(40, 'insert')" v-tooltip.top="'Generte Invoice'"
                                        :label="showBtnLabels ? 'Generate Invoice' : ''"
                                        @click="(showInvoiceForm = true), (selectedInvoiceId = 0)" icon="pi pi-plus"
                                        :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                        class="p-button-raised p-button-primary mr-2" />
                                    <Divider />
                                    <div style="display: table; width: 100%"
                                        v-if="orderInvoice && Object.keys(orderInvoice).length">
                                        <div :class="orderInvoice.invoice_status.toLowerCase() + ' col-12 lg:col-12 md:col-12 mb-0 '"
                                            style="float: left; display: table-cell">
                                            <div class="grid mt-3">
                                                <div class="col-12 col-md-12 grid text-right" style="padding: 4px 15px;">
                                                    <button class="p-panel-header-icon p-link mr-2"
                                                        :invoiceId="orderInvoice.id" @click="toggleInvoiceMenu">
                                                        <span class="pi pi-cog"></span>
                                                    </button>
                                                    <Menu id="invoice_menu" ref="menu5" :model="InvoiceMenue"
                                                        :popup="true" />
                                                </div>
                                                <div class="col-6 col-md-6 col-sm-12 col-xs-12  pt-0" style="float:left">
                                                    <h2>#{{ orderInvoice.invoice_number }}</h2>
                                                </div>
                                                <div class="col-6 col-md-6 col-sm-12 col-xs-12 pt-0" style="float:left">

                                                    <Tag severity="primary" class=" ml-3"
                                                        v-if="orderInvoice.invoice_status === 'Seen'"
                                                        style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                        :value="orderInvoice.invoice_status">
                                                    </Tag>
                                                    <Tag severity="warning" class=" ml-3"
                                                        v-else-if="orderInvoice.invoice_status === 'Pending'"
                                                        style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                        :value="orderInvoice.invoice_status">
                                                    </Tag>
                                                    <Tag severity="success" class=" ml-3"
                                                        v-else-if="orderInvoice.invoice_status === 'Accepted'"
                                                        style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                        :value="orderInvoice.invoice_status">
                                                    </Tag>
                                                    <Tag severity="danger" class=" ml-3"
                                                        v-else-if="orderInvoice.quotation_status === 'Canceled'"
                                                        style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                        :value="orderInvoice.invoice_status">
                                                    </Tag>

                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        <h4 class="mb-0">{{ defaultCurrency }}{{
                                                            orderInvoice.paid_amount }}</h4>
                                                        <span>Paid</span>
                                                    </div>
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        <h4 class="mb-0">{{ defaultCurrency }}{{ orderInvoice.amount
                                                        }}</h4>
                                                        <span>Payable</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        <strong>Due Date:</strong>
                                                    </div>
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        {{
                                                            formatDate(orderInvoice.due_date) }}</div>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        <strong>Invoice Date:</strong>
                                                    </div>
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        {{
                                                            formatDate(orderInvoice.invoice_date) }}
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        <strong>Sent Date:</strong>
                                                    </div>
                                                    <div class="col-6 col-md-6 col-sm-12 col-xs-12 p-0" style="float:left">
                                                        {{
                                                            formatDate(orderInvoice.sent_date) }}</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                            <div class="col-12 lg:col-12 sm:col-12 mb-1  text-center" style="float: left"
                                v-if="!checkUserCoreRole(['Customer'])">
                                <Panel class="mb-3" header="Driver Details" :toggleable="true"
                                    style="float: left; width: 100%">
                                    <Button v-if="checkPermission(40, 'insert')" v-tooltip.top="'Assign Driver'"
                                        :label="showBtnLabels ? 'Assign Driver' : ''"
                                        @click="(showAssignDriverForm = true), (selectedAssignedDriverId = 0)"
                                        icon="pi pi-plus"
                                        :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                        class="p-button-raised p-button-primary mr-2" />
                                    <Divider />
                                    <div style="display: table; width: 100%"
                                        v-if="orderDrivers && (orderDrivers.length > 0)">
                                        <div :class="' col-12 lg:col-12 md:col-12 mb-0 '"
                                            style="float: left; display: table-cell" v-for="(driver, key) in orderDrivers"
                                            :key="key">
                                            <div :class="' grid mt-3'">
                                                <div :class="driver.request_status.toLowerCase() + ' grid pt-3'">
                                                    <div class="col-12 col-md-12 grid">
                                                        <div class="col-12 col-md-12 grid text-right"
                                                            style="padding: 4px 15px;">
                                                            <button class="p-panel-header-icon p-link mr-2"
                                                                :driverAssignedId="driver.id" @click="toggleDriverMenu">
                                                                <span class="pi pi-cog"></span>
                                                            </button>
                                                            <Menu id="driver_menu" ref="menu3" :model="DriverMenue"
                                                                :popup="true" />
                                                        </div>
                                                        <div class="col-6 col-md-6 col-sm-12 col-xs-12  p-0">
                                                            <strong>Date Assigned: </strong><br>
                                                            <span class="mb-0">{{ formatDate(driver.date_assigned) }}
                                                            </span>
                                                        </div>
                                                        <div class="col-6 col-md-6 col-sm-12 col-xs-12 pt-0"
                                                            style="text-align: right;">
                                                            <Tag severity="primary" class=" ml-3"
                                                                v-if="driver.request_status === 'Seen'"
                                                                style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                                :value="driver.request_status">
                                                            </Tag>
                                                            <Tag severity="warning" class=" ml-3"
                                                                v-else-if="driver.request_status === 'Pending'"
                                                                style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                                :value="driver.request_status">
                                                            </Tag>
                                                            <Tag severity="success" class=" ml-3"
                                                                v-else-if="driver.request_status === 'Confirmed'"
                                                                style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                                :value="driver.request_status">
                                                            </Tag>
                                                            <Tag severity="danger" class=" ml-3"
                                                                v-else-if="driver.request_status === 'Canceled'"
                                                                style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                                :value="driver.request_status">
                                                            </Tag>
                                                            <Tag severity="danger" class=" ml-3"
                                                                v-else-if="driver.request_status === 'UnAssigned'"
                                                                style="font-size:12px;padding: 5px 15px !important;border-radius: 50px;"
                                                                :value="driver.request_status">
                                                            </Tag>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-12">
                                                        <h3 class="mb-0">{{ driver.name }}</h3>
                                                        <span>({{ driver.email }}) </span><br>
                                                        <span>{{ driver.user_type }} </span>
                                                    </div>
                                                    <div class="col-12 col-md-12 grid">
                                                        <div class="col-8 col-md-6 col-lg-6">
                                                            <h5 class="mb-0">{{ driver.vehicle_name }} </h5>
                                                            <span>{{ driver.vehicle_type_name }}</span>
                                                        </div>
                                                        <div class="col-4 col-md-6 col-lg-6">
                                                            <h5 class="mb-0">{{ defaultCurrency }}{{
                                                                driver.driver_price }}</h5>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-12  p-0" style="text-align:left"
                                                    v-if="driver.moves.length">
                                                    <div class="col-12 col-md-12 p-0" v-for="(move, index) in driver.moves"
                                                        :key="index">
                                                        <Divider />
                                                        <span :class="move.traveling_status.toLowerCase()">
                                                            <div class="col-12 col-md-12 col-sm-12 col-xs-12 p-0">
                                                                {{ formatDateTime(move.start_time) }}
                                                                <Tag class="ml-1" style="float:right"
                                                                    v-if="move.traveling_status === 'Pending'"
                                                                    severity="warning"> {{ move.traveling_status }}</Tag>
                                                                <Tag class="ml-1" style="float:right"
                                                                    v-else-if="move.traveling_status === 'Delivered'"
                                                                    severity="success"> {{ move.traveling_status }}
                                                                </Tag>
                                                                <Tag class="ml-1" style="float:right"
                                                                    v-else-if="move.traveling_status === 'Canceled'"
                                                                    severity="danger"> {{ move.traveling_status }}
                                                                </Tag>
                                                            </div>
                                                            <div class="col-12 col-md-12 col-sm-12 col-xs-12 p-0">
                                                                <strong>{{ move.departure_address }}</strong>
                                                            </div>
                                                            <div class="col-12 col-md-12 col-sm-12 col-xs-12 p-0">
                                                                <span>{{ move.destination_address }}</span>
                                                            </div>
                                                            <div class="col-12 col-md-12 col-sm-12 col-xs-12 grid">
                                                                <Tag value="Primary"><span>{{ move.legs }} Stops</span>
                                                                </Tag>
                                                                <Tag value="Primary" class="ml-1"><span>{{
                                                                    move.destination_time }}</span> </Tag>
                                                                <Tag value="Primary" class="ml-1"><span>{{ move.passangers
                                                                }}
                                                                        Passanger </span></Tag>
                                                                <Tag value="Primary" class="ml-1"><span>{{ move.hand_luggage
                                                                }}
                                                                        H.L </span></Tag>
                                                                <Tag value="Primary" class="ml-1"><span>{{ move.suitcases }}
                                                                        S.C </span></Tag>
                                                                <Tag value="Primary" class="ml-1"><span>{{ move.child_seats
                                                                }}
                                                                        C.S </span></Tag>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                            <div class="col-12 lg:col-12 sm:col-12 mb-1  text-center" style="float: left"
                                v-if="!checkUserCoreRole(['Customer'])">
                                <Panel class="mb-3" header="Notes" :toggleable="true" style="float: left; width: 100%">
                                    <Button v-if="checkPermission(40, 'insert')" v-tooltip.top="'Notes'"
                                        :label="showBtnLabels ? 'Create Note' : ''"
                                        @click="(showNotesForm = true), (selectedNoteId = 0)" icon="pi pi-plus"
                                        :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                        class="p-button-raised p-button-primary mr-2" />
                                    <Divider />
                                    <div style="display: table; width: 100%"
                                        v-if="orderNotes && Object.keys(orderNotes).length">
                                        <div class="col-12 lg:col-12 md:col-12 mb-0" v-for="(note, nkey) in orderNotes"
                                            :key="nkey" style="float: left; display: table-cell">
                                            <Card class="mb-3" style="height: 100%">
                                                <template #title>
                                                    <!-- {{ nkey + 1 }} -->
                                                    <div style="float: right" v-if="!viewableOnly">
                                                        <Button icon="pi pi-ellipsis-h" :noteId="note.id"
                                                            class="p-button-rounded p-button-text p-button-plain"
                                                            @click="toggleOrderNoteMenu"></Button>
                                                        <Menu :popup="true" ref="menu6" :model="orderNotesMenu"></Menu>
                                                    </div>
                                                </template>
                                                <template #content>
                                                    <p class="m-1"> {{ note.description }}</p>
                                                </template>
                                            </Card>
                                        </div>
                                    </div>
                                </Panel>
                            </div>

                            <!-- </div> -->
                        </div>
                    </div>
                </div>
                <Divider />
                <div class="col-12 lg:col-12">
                    <Toolbar class="pl-0 pr-0">
                        <template v-slot:start>
                            <Button v-if="!viewableOnly" type="submit"
                                :label="checkUserCoreRole(['Customer']) ? 'Place Order' : 'Save & Next'" :loading="isSaving"
                                icon="pi pi-check" @click="(saveAndClose = false), save()"
                                class="p-button-raised p-button-lg p-button-success mr-2" />
                            <!-- <Button v-if="!checkUserCoreRole(['Customer']) && !viewableOnly" type="submit"
                                label="Save and Send Quotation" :loading="isSaving" icon="pi pi-check"
                                @click="(form.send_quotation = true), save()"
                                class="p-button-raised p-button-lg p-button-secondary mr-2" /> -->
                            <Button v-if="!checkUserCoreRole(['Customer']) && !viewableOnly" type="submit"
                                label="Save and Close" :loading="isSaving" icon="pi pi-check"
                                @click="(saveAndClose = true), save()"
                                class="p-button-raised p-button-lg p-button-info mr-2" />
                            <Button v-if="!viewableOnly" type="button" label="Reset" icon="pi pi-refresh"
                                class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()" />
                            <Button v-if="viewableOnly" type="button" label="Previous" icon="pi pi-arrow-left"
                                class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="movePrevious()" />
                            <Button v-if="viewableOnly" type="button" label="Next" icon="pi pi-arrow-right"
                                class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="moveNext()" />
                        </template>
                        <template v-slot:end>
                            <Button type="button" label="Cancel" icon="pi pi-times"
                                class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm" />
                        </template>
                    </Toolbar>
                </div>
            </div>
        </form>
        <!-- </Sidebar>  -->

        <Sidebar v-model:visible="showQuotationStatusChangedForm" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="true"
            @hide="showQuotationStatusChangedForm = false; removeViewFromURL('change-order-quotation-status');"
            position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @submit.prevent="login" @keydown="quotationStatusForm.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Quotation#{{ selectedQuotationId }}</h3>
                        <p>Update quotation status with reason </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <h4>Quotation status is being changed to <span style="color:red">"{{
                                        quotationStatusForm.status }}"</span></h4>
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>
                                        <h5>Status Change Description</h5>
                                    </label>
                                    <Textarea placeholder="Description" v-model="quotationStatusForm.description" rows="5"
                                        cols="30" class="full-width" />
                                    <HasError class="p-error" :form="quotationStatusForm" field="description" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Change Status" :loading="isStatusSaving" icon="pi pi-check"
                                    @click="changeOrderQuotationStatus()"
                                    class="p-button-raised p-button-lg p-button-success mr-2" />
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" :loading="isStatusSaving" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2"
                                    @click="showQuotationStatusChangedForm = false; removeViewFromURL('change-order-quotation-status');" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>
        <Sidebar v-model:visible="showInvoiceStatusChangedForm" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="true"
            @hide="showInvoiceStatusChangedForm = false; removeViewFromURL('change-order-invoice-status');" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @submit.prevent="login" @keydown="invoiceStatusForm.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Invoice#{{ selectedRecordId }}</h3>
                        <p>Update invoice status with reason </p>
                    </div>
                    <Divider class="mt-0" />

                    <div class="grid modal-body">

                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <h4>Invoice status is being changed to <span style="color:red">"{{
                                        invoiceStatusForm.status }}"</span></h4>
                                </div>
                                <span v-if="invoiceStatusForm.status == 'Paid'">
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2 " style="float:left">
                                        <label>Transection Source</label>
                                        <Dropdown TransectionSourceLoading :filter="true" :showClear="true"
                                            v-model="invoiceStatusForm.source_id" :options="transectionSources"
                                            optionLabel="name" optionValue="id"
                                            :placeholder="TransectionSourceLoading ? 'Loading...' : 'Select Source'"
                                            class="full-width" />
                                        <HasError class="p-error" :form="invoiceStatusForm" field="source_id" />
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2 " style="float:left">
                                        <label>Transection Date</label>
                                        <Calendar :dateFormat="calendarFormat()"
                                            :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                            :showButtonBar="true" id="transection_date"
                                            v-model="invoiceStatusForm.transection_date" :showTime="false"
                                            :showSeconds="false" class="full-width" />
                                        <HasError class="p-error" :form="invoiceStatusForm" field="transection_date" />
                                    </div>
                                </span>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>
                                        <h5>Status Change Description</h5>
                                    </label>
                                    <Textarea placeholder="Description" v-model="invoiceStatusForm.description" rows="5"
                                        cols="30" class="full-width" />
                                    <HasError class="p-error" :form="invoiceStatusForm" field="description" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Change Status" :loading="isStatusSaving" icon="pi pi-check"
                                    @click="changeOrderInvoiceStatus()"
                                    class="p-button-raised p-button-lg p-button-success mr-2" />
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" :loading="isStatusSaving" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2"
                                    @click="showStatusChangedForm = false; removeViewFromURL('change-order-invoice-status');" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>
        <Sidebar v-model:visible="showInvoicePaymentForm" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="true" @hide="showInvoicePaymentForm = false; removeViewFromURL('invoice-payments');"
            position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @submit.prevent="login" @keydown="invoicePaymentForm.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <h3 style="width:100%" class="mb-0">Invoice#{{ invoiceData.invoice_number }} <p
                                        style="font-size:12px;">Manage Invoice Payment</p>
                                </h3> <br>

                            </template>
                            <template v-slot:end>
                                <div>
                                    <div class="bg-yellow p-3" style="cursor:pointer"
                                        @click="showTransectionPage = true; addViewInURL({ view: 'invoice-user-transactions', id: this.selectedRecordId, user_id: orderUserData.id });">
                                        Customer Balance : {{ defaultCurrency != '' ? defaultCurrency : 'USD' }}
                                        <strong><span v-if="orderUserData.balance">
                                                {{ Number(orderUserData.balance).toFixed(priceRounding) }}</span> <span
                                                v-else> 00.00 </span></strong>
                                    </div>
                                </div>
                            </template>
                        </Toolbar>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <h4>Add Invoice Payment</h4>
                                </div>
                                <div class="col-12 lg:col-3 sm:col-12 mb-2" style="float:left">
                                    <label>
                                        <h6>Payment Amount</h6>
                                    </label>
                                    <span class="pink-color">
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">{{ currency }}</span>
                                            <InputNumber mode="currency"
                                                :currency="defaultCurrency != '' ? defaultCurrency : 'USD'" locale="en-US"
                                                placeholder="Tax Price" v-model="invoicePaymentForm.paid_amount" :min="0"
                                                showButtons class="full-width" />
                                        </div>
                                    </span>
                                    <HasError class="p-error" :form="invoicePaymentForm" field="paid_amount" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left">
                                    <label>
                                        <h6>Description/Notes</h6>
                                    </label>
                                    <InputText placeholder="Notes" v-model="invoicePaymentForm.description"
                                        class="full-width" />
                                    <HasError class="p-error" :form="invoicePaymentForm" field="description" />
                                </div>
                                <div class="col-12 lg:col-2 sm:col-12 mb-2 mt-5" style="float:left">
                                    <Button type="submit" label="Save Payment" :loading="savingInvoicePayment"
                                        icon="pi pi-check" @click="saveInvoicePayment()"
                                        class="p-button-raised p-button-lg p-button-success mr-2" />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <div class="col-12 lg:col-12 flex  justify-content-center" v-if="invoiceData">
                            <div class="p-5 bg-yellow">
                                <h6>Total</h6><br>
                                <strong>
                                    <h4 v-if="invoiceData.amount" style="margin-top:-20px">{{ defaultCurrency != '' ?
                                        defaultCurrency :
                                        'USD' }} {{ Number(invoiceData.amount).toFixed(priceRounding) }}</h4>
                                    <h4 v-else style="margin-top:-20px">{{ defaultCurrency != '' ? defaultCurrency : 'USD'
                                    }} 0.00</h4>
                                </strong>
                            </div>
                            <div class="p-5 bg-purple">
                                <h6>Paid</h6><br>
                                <strong>
                                    <h4 v-if="totalPaid" style="margin-top:-20px">{{ defaultCurrency != '' ? defaultCurrency
                                        : 'USD' }}
                                        {{ totalPaid.toFixed(priceRounding) }}</h4>
                                    <h4 v-else style="margin-top:-20px">{{ defaultCurrency != '' ? defaultCurrency : 'USD'
                                    }} 0.00</h4>
                                </strong>
                            </div>
                            <div class="p-5 bg-orange">
                                <h6>Payable</h6><br>
                                <strong>
                                    <h4 v-if="totalPaid && invoiceData.amount" style="margin-top:-20px">{{ defaultCurrency
                                        != '' ?
                                        defaultCurrency : 'USD' }} {{ Number((Number(invoiceData.amount) -
        totalPaid)).toFixed(priceRounding) }}</h4>
                                    <h4 v-else style="margin-top:-20px">{{ defaultCurrency != '' ? defaultCurrency : 'USD'
                                    }}
                                        {{ Number((Number(invoiceData.amount) - totalPaid)).toFixed(priceRounding) }}</h4>
                                </strong>
                            </div>
                        </div>
                        <div class="col-12 lg:col-12">
                            <div class="col-12 md:col-6 lg:col-3" style="float:left"
                                v-for=" (payment, key) in invoicePayments " :key="key">
                                <div class="card overview-box flex flex-column pt-2"
                                    :class="payment.payment_status == 'canceled' ? 'bg-red' : 'bg-green'">
                                    <div class="flex align-items-center muted-text">
                                        <h6 v-if="payment.payment_status"
                                            :class="payment.payment_status == 'canceled' ? 'mb-2 mt-3' : 'm-0'">
                                            {{ payment.payment_status.charAt(0).toUpperCase() +
                                                payment.payment_status.slice(1) }}</h6>
                                        <div v-if="payment.payment_status != 'canceled'"
                                            :class="{ 'ml-auto': !isRTL, 'mr-auto': isRTL }"
                                            style="width:100%; text-align:right">
                                            <Button icon="pi pi-ellipsis-h" :id="payment.id"
                                                class="p-button-rounded p-button-text p-button-plain"
                                                @click="togglePaymentMenu"></Button>
                                            <Menu ref="PaymentMenu" :popup="true" :model="PaymentMenuItems"></Menu>
                                        </div>
                                    </div>
                                    <div class="flex justify-content-between mt-3 flex-wrap">
                                        <div class="flex flex-column" style="width: 180px;">
                                            <span class="mb-1 fs-xlarge">{{ defaultCurrency != '' ? defaultCurrency : 'USD'
                                            }}
                                                {{ payment.paid_amount.toFixed(priceRounding) }}</span>
                                            <span v-if="payment.modified_by"
                                                class="overview-status p-1 teal-bgcolor fs-small">{{
                                                    formatDateTime(payment.date_modification) }}
                                                By {{ (payment.modified_by) }}</span>
                                            <span v-else class="overview-status p-1 teal-bgcolor fs-small">{{
                                                formatDateTime(payment.date_added) }}
                                                By {{ (payment.added_by) }}</span>
                                            <span class="mb-1">
                                                <p>{{ (payment.description) }}</p>
                                            </span>
                                        </div>
                                        <div class="flex align-items-end">
                                            <!-- <Chart ref="overviewChartData2" type="line" :data="overviewChartData2" :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:end>
                                <Button type="button" label="Cancel" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2"
                                    @click="showInvoicePaymentForm = false; removeViewFromURL('invoice-payments');" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>
    </BlockUI>
    <OrderMoveForm v-on:closeMoveForm="closeMoveForm"
        v-if="moveFormOpen && (checkPermission(41, 'insert') || checkPermission(41, 'update'))"
        :priceAutoCalculation="form.price_auto_calculation" :viewableOnly="viewableOnly" :orderId="form.order_id"
        :companyId="form.company_id" :recordId="selectedMoveId" :copyMove="copyMove" :showForm="true" />
    <AssignDriverForm v-on:formClose="closeAssignDriverForm"
        v-if="showAssignDriverForm && (checkPermission(42, 'insert') || checkPermission(42, 'update'))"
        :orderId="form.order_id" :companyId="form.company_id" :recordId="selectedAssignedDriverId" :showForm="true" />
    <OrderProductForm v-on:formClose="closeProductForm"
        v-if="showProductForm && (checkPermission(42, 'insert') || checkPermission(42, 'update'))" :orderId="form.order_id"
        :recordId="selectedProductId" :showForm="true" />
    <OrderQuotationForm v-on:formClose="closeQuotationForm"
        v-if="showQuotationForm && (checkPermission(51, 'insert') || checkPermission(51, 'update'))"
        :orderId="form.order_id" :recordId="selectedQuotationId" :showForm="true" />
    <OrderInvoiceForm v-on:formClose="closeInvoiceForm"
        v-if="showInvoiceForm && (checkPermission(43, 'insert') || checkPermission(43, 'update'))" :orderId="form.order_id"
        :recordId="selectedInvoiceId" :showForm="true" />
    <QuotationHistory v-on:closeHistoryPage="showHistoryPage = false; removeViewFromURL('quotation-history');"
        v-if="showHistoryPage && (permissions[52] && permissions[52].includes('view'))" :quotation_id="selectedQuotationId"
        :showHistorySidebar="true" />
    <UserTransections v-on:closeTransectionPage="closeTransectionPage(); removeViewFromURL('invoice-user-transactions');"
        v-if="showTransectionPage && (permissions[65] && permissions[65].includes('view'))" :user_id="orderUserData.user_id"
        :showTransectionSidebar="true" />
    <InvoiceHistory v-on:closeHistoryPage="showInvoiceHistoryPage = false; removeViewFromURL('invoice-history');"
        v-if="showInvoiceHistoryPage && (permissions[53] && permissions[53].includes('view'))"
        :invoice_id="selectedInvoiceId" :showHistorySidebar="true" />

    <OrderNotesForm v-on:NotesFormClose="closeNotesForm()"
        v-if="showNotesForm && (checkPermission(45, 'insert') || checkPermission(45, 'update'))" :orderId="form.order_id"
        :recordId="selectedNoteId" :showForm="true" />
</template>
<script>
import OrderNotesForm from '../../components/Orders/OrderNotesForm.vue';
import AssignDriverForm from '../../components/Orders/AssignDriverForm.vue';
import OrderProductForm from '../../components/Orders/OrderProductForm.vue';
import OrderQuotationForm from '../../components/Orders/OrderQuotationForm.vue';
import OrderInvoiceForm from '../../components/Orders/OrderInvoiceForm.vue';
import OrderMoveForm from '../../components/Orders/OrderMoveForm.vue';
import QuotationHistory from '../../pages/Orders/QuotationHistory.vue';
import UserTransections from '../../pages/AccessManagement/UserTransections.vue';
import InvoiceHistory from '../../pages/Orders/InvoiceHistory.vue';
import ClassicEditor1 from '@ckeditor/ckeditor5-build-classic';
export default {
    emits: ['editLocation'],
    data() {
        return {
            form: new this.Form({
                id: '',
                company_id: 0,
                order_id: 0,
                price_auto_calculation: false,
                quotation_terms: '',
                invoice_terms: '',
            }),
            quotationStatusForm: new this.Form({
                id: 0,
                description: '',
                status: '',
                quotation_for_id: 0,
                quotation_for: 'order'
            }),
            invoiceStatusForm: new this.Form({
                id: 0,
                description: '',
                status: '',
                source_id: 0,
                transection_date: '',
                invoice_for_id: 0,
                invoice_for: 'order'
            }),
            invoicePaymentForm: new this.Form({
                id: 0,
                invoice_for_id: 0,
                invoice_for: 'order',
                description: '',
                paid_amount: 0,
                invoice_id: 0,
            }),
            PaymentMenuItems: [
                { label: 'Cancel Payment', icon: 'pi pi-fw pi-trash', command: () => { this.cancelInvoicePayment(); } },
            ],
            selectedPaymentId: 0,
            isInvoiceStatusSaving: false,
            showHistoryPage: false,
            orderPrice: 0,
            quotedPrice: 0,
            estimatedPrice: 0,
            orderProducts: [],
            orderData: [],
            orderInvoice: [],
            orderQuotation: [],
            orderQuotations: [],
            selectedAssignedDriverId: 0,
            showAssignDriverForm: false,
            AssignedDriversloading: false,
            orderDriversLoading: false,
            orderDrivers: [],
            assignedDrivers: [],
            copyMove: false,
            selectedMoveId: 0,
            moveFormOpen: false,
            showProductForm: false,
            showQuotationForm: false,
            showInvoiceForm: false,
            orderProductsloading: false,
            orderInvoicesloading: false,
            orderQuotationsloading: false,
            selectedProductId: 0,
            selectedQuotationId: 0,
            selectedInvoiceId: 0,
            productsMenu: [],
            moves: [],
            editor: ClassicEditor1,
            onReady: false,
            activeTab: 1,
            moduleId: 41,
            MoveMenue: [],
            module_name: 'Order Transport',
            isSaving: false,
            saveAndClose: false,
            recId: 0,
            loadingComponent: true,
            formVisible: false,
            showInvoicePaymentForm: false,
            showInvoiceHistoryPage: false,
            TransectionSourceLoading: false,
            showInvoiceStatusChangedForm: false,
            showQuotationStatusChangedForm: false,
            showTransectionPage: false,
            showNotesForm: false,
            selectedNoteId: 0,
            orderNotes: [],
            orderNotesMenu: [],
            selectedDriverAssignedId: 0,
            downloadingQuotation: [],
            downloadingInvoice: [],
            DriverMenue: [],
            QuotationMenue: [],
            transectionSources: [],
            orderUserData: [],
            InvoiceMenue: [],
            invoiceData: [],

        };
    },
    props: {
        showForm: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
        companyId: {
            type: Number,
        },
        viewableOnly: {
            type: Boolean,
            required: true,
        },
    },
    created() {

        if (this.permissions[this.moduleId] && this.checkPermission(this.moduleId, 'update')) {
            this.QuotationMenue.push(
                { label: 'Pending', icon: 'pi pi-eye-slash', command: () => { this.changeQuotationStatus('Pending'); } },
                { label: 'Seen', icon: 'pi pi-eye', command: () => { this.changeQuotationStatus('Seen'); } },
                { label: 'Accepted', icon: 'pi pi-check', command: () => { this.changeQuotationStatus('Accepted'); } },
                { label: 'Canceled', icon: 'pi pi-times', command: () => { this.changeQuotationStatus('Canceled'); } },
                { separator: true },
                { label: 'Resend Email', icon: 'pi pi-envelope', command: () => { this.changeQuotationStatus('ResendMail'); } },
                { label: 'Create Copy', icon: 'pi pi-copy', command: () => { this.changeQuotationStatus('Copy'); } },
                { separator: true },
                { label: 'Send as invoice', icon: 'pi pi-money-bill', command: () => { this.changeQuotationStatus('SendInvoice'); } },
                { separator: true },
                { label: 'View History', icon: 'pi pi-history', command: () => { this.changeQuotationStatus('ViewHistory'); } },
            );
        }


        if (this.checkPermission(43, 'update')) {
            this.InvoiceMenue.push(
                { label: 'Pending', icon: 'pi pi-eye-slash', command: () => { this.changeInvoiceStatus('Pending'); } },
                { label: 'Seen', icon: 'pi pi-eye', command: () => { this.changeInvoiceStatus('Seen'); } },
                { label: 'Confirmed', icon: 'pi pi-check', command: () => { this.changeInvoiceStatus('Confirmed'); } },
                { label: 'Paid', icon: 'pi pi-check-circle', command: () => { this.changeInvoiceStatus('Paid'); } },
                { label: 'Canceled', icon: 'pi pi-times', command: () => { this.changeInvoiceStatus('Canceled'); } },
                { separator: true },
                { label: 'Resend Email', icon: 'pi pi-envelope', command: () => { this.changeInvoiceStatus('ResendMail'); } },
                { label: 'Create Copy', icon: 'pi pi-copy', command: () => { this.changeInvoiceStatus('Copy'); } },
                // {label:'Create Schedules', icon:'pi pi-clock',command: () => { this.changeInvoiceStatus('Schedules'); }},
                { separator: true },
                { label: 'Payments', icon: 'pi pi-money-bill', command: () => { this.changeInvoiceStatus('Payments'); } },
                { separator: true },
                { label: 'View History', icon: 'pi pi-history', command: () => { this.changeInvoiceStatus('ViewHistory'); } },
            );
        }

        if (this.checkPermission(this.moduleId, 'delete')) {
            this.MoveMenue.push({
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => {
                    this.getClickedRecord('delete');
                },
            });
        }
        if (this.checkPermission(this.moduleId, 'update')) {
            this.MoveMenue.push({
                label: 'Update',
                icon: 'pi pi-pencil',
                command: () => {
                    this.getClickedRecord('update');
                },
            });

            this.MoveMenue.push({
                label: 'Create Copy',
                icon: 'pi pi-copy',
                command: () => {
                    this.getClickedRecord('copy');
                },
            });
        }

        //Products Menu
        if (this.checkPermission(42, 'delete')) {
            this.productsMenu.push({
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => {
                    this.getClickedProductRecord('delete');
                },
            });
        }
        if (this.checkPermission(42, 'update')) {
            this.productsMenu.push({
                label: 'Update',
                icon: 'pi pi-pencil',
                command: () => {
                    this.getClickedProductRecord('update');
                },
            });
        }

        //Driver Assigned Jobs Menu
        if (this.checkPermission(this.moduleId, 'update')) {
            this.DriverMenue.push({
                label: 'UnAssign',
                icon: 'pi pi-times',
                command: () => {
                    this.getClickedDriverAssignedRecord('unassign');
                },
            });
        }
        if (this.checkPermission(this.moduleId, 'update')) {
            this.DriverMenue.push({
                label: 'Confirm',
                icon: 'pi pi-check',
                command: () => {
                    this.getClickedDriverAssignedRecord('confirm');
                },
            });
        }


        //Order Notes Menu
        if (this.checkPermission(45, 'update')) {
            this.orderNotesMenu.push({
                label: 'Update',
                icon: 'pi pi-refresh',
                command: () => {
                    this.getOrderNotesClickedRecord('update');
                }
            });
        }
        if (this.checkPermission(45, 'delete')) {
            this.orderNotesMenu.push({
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => {
                    this.getOrderNotesClickedRecord('delete');
                }
            });
        }
    },
    computed: {},
    components: {
        OrderMoveForm,
        OrderProductForm,
        AssignDriverForm,
        OrderQuotationForm,
        OrderInvoiceForm,
        QuotationHistory,
        UserTransections,
        InvoiceHistory,
        OrderNotesForm
    },
    mounted() {
        this.resetForm();
        this.form.order_id = this.recordId;
        this.formVisible = this.showForm;
        this.recId = this.recordId;
        this.form.company_id = this.companyId;
        this.getAllRequestData();
        if (this.$route.query.view) {
            this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
            if (Object.keys(this.urlParams).length) {
                this.urlParams.forEach((parameter) => {
                    if (parameter.orderId == 'order-tab') {
                        this.form.order_id = parameter.orderId;
                    }
                    if (parameter.view == 'transport-tab') {
                        this.activeTab = parameter.id;
                    }
                    if (parameter.view == 'moves-form') {
                        this.activeTab = parameter.id;
                        this.selectedMoveId = parameter.selected_move;
                    }
                    if (parameter.view == 'add-invoice') {
                        this.selectedInvoiceId = parameter.id;
                        this.showInvoiceForm = true;
                    }
                    else if (parameter.view == 'invoice-payments') {
                        this.invoicePaymentForm.reset();
                        this.selectedInvoiceId = parameter.id;
                        this.getInvoicePayments();
                        this.showInvoicePaymentForm = true;
                    }
                    else if (parameter.view == 'invoice-history') {
                        this.selectedInvoiceId = parameter.id;
                        this.showInvoiceHistoryPage = true;
                    }
                    else if (parameter.view == 'invoice-user-transactions') {
                        this.selectedInvoiceId = parameter.id;
                        this.orderUserData.user_id = parameter.user_id;
                        this.showTransectionPage = true;
                    }
                    else if (parameter.view == 'change-order-invoice-status') {
                        this.selectedInvoiceId = parameter.id;
                        this.invoiceStatusForm.reset();
                        this.showInvoiceStatusChangedForm = true;
                        this.invoiceStatusForm.status = parameter.invoice_status;
                    }
                });
            }
        }
    },
    methods: {

        closeNotesForm() {
            this.showNotesForm = false;
            this.getOrderNotes();
        },
        closeTransectionPage() {
            this.showTransectionPage = false;
            this.getInvoicePayments();
        },

        async getOrderNotes() {
            var Vthis = this;
            this.loadingOrderNotes = true;
            await this.axios.post('getAllOrderNotes', { orderId: this.recId })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.orderNotes = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.loadingOrderNotes = false;
                })
        },

        deleteNote() {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loadingOrderNotes = true;
                    let DeleteAbleRecords = [];
                    DeleteAbleRecords.push(Vthis.selectedNoteId);

                    Vthis.axios.post('deleteOrderNote', { id: DeleteAbleRecords })
                        .then((response) => {
                            let responseStatus = this.printResponseResult(response, false);
                            if (responseStatus == 200) {
                                Vthis.getOrderNotes();
                                Vthis.showMessage('Record deleted successfully', 'success');
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                            Vthis.loadingOrderNotes = false;
                        })
                },
                reject: () => {
                    // this.loading = false;
                }
            });
        },

        async getTransectionSources() {
            var Vthis = this;
            Vthis.TransectionSourceLoading = true;
            await this.axios.post('getAllTransectionSources')
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.transectionSources = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.TransectionSourceLoading = false;
                })
        },

        cancelInvoicePayment() {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.axios.post('cancelInvoicePayment', { id: this.selectedPaymentId })
                        .then((response) => {
                            let responseStatus = this.printResponseResult(response, false);
                            if (responseStatus == 200) {
                                Vthis.showMessage('Deleted successfully', 'success');
                                Vthis.getInvoicePayments();
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                        })
                },
                reject: () => {
                }
            });
        },

        async changeOrderInvoiceStatus() {
            var Vthis = this;
            Vthis.invoiceStatusForm.id = Vthis.selectedInvoiceId;
            Vthis.invoiceStatusForm.invoice_for_id = Vthis.recId;
            this.isInvoiceStatusSaving = true;
            await this.invoiceStatusForm
                .post(Vthis.$baseurl + 'api/changeInvoiceStatus', Vthis.invoiceStatusForm)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        Vthis.getOrderInvoice();
                        Vthis.invoiceStatusForm.reset();
                        Vthis.showMessage('Saved successfully', 'success');
                        Vthis.showInvoiceStatusChangedForm = false;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isInvoiceStatusSaving = false;
                })
        },

        togglePaymentMenu(event) {
            this.selectedPaymentId = event.currentTarget.getAttribute('id');
            this.$refs.PaymentMenu.toggle(event);
        },
        async downloadInvoice($id) {
            this.downloadingInvoice[$id] = true;
            var Vthis = this;
            await this.axios.post('downloadInvoice', { invoice_for_id: this.recId, invoice_for: 'order', id: $id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        window.open(Vthis.$baseurl + response.data.link, '_blank').focus();
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.downloadingInvoice[$id] = false;
                })
        },

        async changeInvoiceStatus(status) {
            if (status == 'Pending') {
                this.invoiceStatusForm.reset();
                this.showInvoiceStatusChangedForm = true;
                this.invoiceStatusForm.status = 'Pending';
                this.addViewInURL({ view: 'change-order-invoice-status', id: this.selectedInvoiceId, invoice_status: 'Pending' });
            }
            else if (status == 'Seen') {
                this.invoiceStatusForm.reset();
                this.showInvoiceStatusChangedForm = true;
                this.invoiceStatusForm.status = 'Seen';
                this.addViewInURL({ view: 'change-order-invoice-status', id: this.selectedInvoiceId, invoice_status: 'Seen' });
            }
            else if (status == 'Confirmed') {
                this.invoiceStatusForm.reset();
                this.showInvoiceStatusChangedForm = true;
                this.invoiceStatusForm.status = 'Confirmed';
                this.addViewInURL({ view: 'change-order-invoice-status', id: this.selectedInvoiceId, invoice_status: 'Confirmed' });
            }
            else if (status == 'Paid') {
                this.invoiceStatusForm.reset();
                this.showInvoiceStatusChangedForm = true;
                this.invoiceStatusForm.status = 'Paid';
                this.addViewInURL({ view: 'change-order-invoice-status', id: this.selectedInvoiceId, invoice_status: 'Paid' });
            }
            else if (status == 'Canceled') {
                this.invoiceStatusForm.reset();
                this.showInvoiceStatusChangedForm = true;
                this.invoiceStatusForm.status = 'Canceled';
                this.addViewInURL({ view: 'change-order-invoice-status', id: this.selectedInvoiceId, invoice_status: 'Canceled' });
            }
            else if (status == 'Payments') {
                this.getInvoicePayments();
                this.invoicePaymentForm.reset();
                this.showInvoicePaymentForm = true;
                this.invoicePaymentForm.invoice_id = this.selectedInvoiceId;
                this.addViewInURL({ view: 'invoice-payments', id: this.selectedInvoiceId });
            }
            else if (status == 'ResendMail') {
                this.resendInvoiceMail();
            }
            else if (status == 'Copy') {
                this.showInvoiceForm = true;
                this.addViewInURL({ view: 'add-invoice', id: this.selectedInvoiceId });
            }
            else if (status == 'ViewHistory') {
                this.showInvoiceHistoryPage = true;
                this.addViewInURL({ view: 'invoice-history', id: this.selectedInvoiceId });
            }
            // else if(status == 'Schedules'){ 
            //     // this.showInvoiceSchedule = true;
            // } 
        },


        resendInvoiceMail() {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog2',
                header: 'Confirmation',
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    let message = 'Sending mail...'
                    Vthis.showMessage(message, 'info', 55000);
                    Vthis.axios.post('resendInvoiceMail', { id: Vthis.selectedInvoiceId })
                        .then((returnedData) => {
                            let responseStatus = this.printResponseResult(returnedData, false);
                            if (responseStatus == 200) {
                                Vthis.$toast.removeAllGroups();
                                if (returnedData.response) {
                                    if (returnedData.response.data) {
                                        Vthis.showMessage(returnedData.response.data.message, 'info');
                                    }
                                    else if (returnedData.message) {
                                        Vthis.showMessage(returnedData.message, 'info');
                                    }
                                }
                                else if (returnedData) {
                                    let message = 'Email sent successfully.'
                                    Vthis.showMessage(message, 'success');
                                }
                                else {
                                    let message = 'Email not sent. Please try again later'
                                    Vthis.showMessage(message, 'error');
                                }
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                        })
                },
                reject: () => {
                    // this.loading = false;
                }
            });




        },


        async saveInvoicePayment() {
            var Vthis = this;
            Vthis.invoicePaymentForm.invoice_for_id = Vthis.recId;
            Vthis.invoicePaymentForm.invoice_id = Vthis.selectedInvoiceId;
            this.savingInvoicePayment = true;
            await this.invoicePaymentForm
                .post(Vthis.$baseurl + 'api/saveInvoicePayment', Vthis.invoicePaymentForm)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        Vthis.invoicePaymentForm.reset();
                        Vthis.showMessage('Payment has been saved successfully', 'success');
                        Vthis.getInvoicePayments();
                        Vthis.showInvoiceStatusChangedForm = false;
                    }

                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.savingInvoicePayment = false;
                })
        },

        getInvoicePayments() {
            var Vthis = this;
            Vthis.totalPaid = 0;
            this.axios.post('getAllInvoicePayments', { invoice_id: this.selectedInvoiceId, invoice_for_id: this.recId, invoice_for: 'order' })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.orderUserData = response.data.userBalance;
                        this.invoiceData = response.data.invoice_data;
                        this.invoicePayments = response.data.invoicePayments;
                        Vthis.invoicePayments.forEach((payment) => {
                            if (payment.payment_status == 'successfull') {
                                Vthis.totalPaid = Vthis.totalPaid + payment.paid_amount;
                            }
                        })
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },

        changeQuotationStatus(status) {
            if (status == 'Pending') {
                this.quotationStatusForm.reset();
                this.showQuotationStatusChangedForm = true;
                this.quotationStatusForm.status = 'Pending';
                this.addViewInURL({ view: 'change-order-quotation-status', id: this.selectedQuotationId, quotation_status: 'Pending' });
            }
            else if (status == 'Seen') {
                this.quotationStatusForm.reset();
                this.showQuotationStatusChangedForm = true;
                this.quotationStatusForm.status = 'Seen';
                this.addViewInURL({ view: 'change-order-quotation-status', id: this.selectedQuotationId, quotation_status: 'Seen' });
            }
            else if (status == 'Accepted') {
                this.quotationStatusForm.reset();
                this.showQuotationStatusChangedForm = true;
                this.quotationStatusForm.status = 'Accepted';
                this.addViewInURL({ view: 'change-order-quotation-status', id: this.selectedQuotationId, quotation_status: 'Accepted' });
            }

            else if (status == 'Canceled') {
                this.quotationStatusForm.reset();
                this.showQuotationStatusChangedForm = true;
                this.quotationStatusForm.status = 'Canceled';
                this.addViewInURL({ view: 'change-order-quotation-status', id: this.selectedQuotationId, quotation_status: 'Canceled' });
            }
            else if (status == 'ResendMail') {
                this.resendQuotationMail();
            }
            else if (status == 'Copy') {
                this.showQuotationForm = true;
                this.addViewInURL({ view: 'add-quotation', id: this.selectedQuotationId });
            }
            else if (status == 'SendInvoice') {
                this.showInvoiceForm = true;
                this.addViewInURL({ view: 'send-invoice', id: this.selectedQuotationId });
            }
            else if (status == 'ViewHistory') {
                this.showHistoryPage = true;
                this.addViewInURL({ view: 'quotation-history', id: this.selectedQuotationId });
            }


        },

        async changeOrderQuotationStatus() {
            var Vthis = this;
            Vthis.quotationStatusForm.id = Vthis.selectedQuotationId;
            Vthis.quotationStatusForm.quotation_for_id = Vthis.recordId;
            this.isStatusSaving = true;
            await this.quotationStatusForm
                .post(Vthis.$baseurl + 'api/changeQuotationStatus', Vthis.quotationStatusForm)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        Vthis.getOrderQuotation();
                        Vthis.quotationStatusForm.reset();
                        // Vthis.records.filter((record) => {
                        //     if (record.id == Vthis.quotationStatusForm.id) {
                        //         record.quotation_status = Vthis.quotationStatusForm.status;
                        //     }
                        // })
                        Vthis.showMessage('Saved successfully', 'success');
                        Vthis.showQuotationStatusChangedForm = false;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isStatusSaving = false;
                })
        },


        async downloadQuotation($id) {
            this.downloadingQuotation[$id] = true;
            var Vthis = this;
            await this.axios.post('downloadQuotation', { quotation_for_id: this.selectedRecordId, quotation_for: 'order', id: $id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        window.open(Vthis.$baseurl + response.data.link, '_blank').focus();
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.downloadingQuotation[$id] = false;
                })
        },
        resendQuotationMail() {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    let message = 'Sending mail...'
                    Vthis.showMessage(message, 'info', 55000);
                    Vthis.axios.post('resendQuotationMail', { id: Vthis.selectedQuotationId })
                        .then((returnedData) => {
                            let responseStatus = this.printResponseResult(returnedData, false);
                            if (responseStatus == 200) {
                                Vthis.$toast.removeAllGroups();
                                if (returnedData.response) {
                                    if (returnedData.response.data) {
                                        Vthis.showMessage(returnedData.response.data.message, 'info');
                                    }
                                    else if (returnedData.message) {
                                        Vthis.showMessage(returnedData.message, 'info');
                                    }
                                }
                                else if (returnedData) {
                                    let message = 'Email sent successfully.'
                                    Vthis.showMessage(message, 'success');
                                }
                                else {
                                    let message = 'Email not sent. Please try again later'
                                    Vthis.showMessage(message, 'error');
                                }
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                        })
                },
                reject: () => {
                    // this.loading = false;
                }
            });

        },

        getClickedProductRecord(action) {
            if (action == 'delete') {
                this.deleteOrderProduct();
            }
            if (action == 'update') {
                this.showProductForm = true;
            }
        },

        deleteOrderProduct() {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loading = true;
                    let DeleteAbleRecords = [];
                    if (Vthis.selectedRecords) {
                        Vthis.selectedRecordId = '';
                        Vthis.selectedRecords.forEach((data) => {
                            DeleteAbleRecords.push(data.id);
                        });
                    } else {
                        DeleteAbleRecords.push(Vthis.selectedProductId);
                    }

                    Vthis.axios
                        .post('deleteOrderProduct', { id: DeleteAbleRecords, move_id: this.recordId, order_id: this.orderId })
                        .then((response) => {
                            let responseStatus = this.printResponseResult(response, false);
                            if (responseStatus == 200) {
                                Vthis.getOrderProducts();
                                Vthis.showMessage('Product deleted successfully', 'success');
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                            Vthis.loading = false;
                        });
                },
                reject: () => {
                    // this.loading = false;
                },
            });
        },

        getClickedDriverAssignedRecord(action) {
            if (action == 'unassign') {
                this.changeDriverAssignedJobStatus('UnAssigned');
            }
            if (action == 'confirm') {
                this.changeDriverAssignedJobStatus('Confirmed');
            }
        },


        changeDriverAssignedJobStatus(status = '') {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to change status?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loading = true;
                    let DeleteAbleRecords = [];
                    if (Vthis.selectedRecords) {
                        Vthis.selectedRecordId = '';
                        Vthis.selectedRecords.forEach((data) => {
                            DeleteAbleRecords.push(data.id);
                        });
                    } else {
                        DeleteAbleRecords.push(Vthis.selectedDriverAssignedId);
                    }

                    Vthis.axios
                        .post('changeAssignedDriverStatus', { id: DeleteAbleRecords, order_id: this.form.order_id, 'status': status })
                        .then((response) => {
                            let responseStatus = this.printResponseResult(response, false);
                            if (responseStatus == 200) {
                                Vthis.getOrderDrivers();
                                Vthis.showMessage('Status changed successfully', 'success');
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                            Vthis.loading = false;
                        });
                },
                reject: () => {
                    // this.loading = false;
                },
            });
        },

        closeAssignDriverForm() {
            this.getOrderDrivers();
            this.showAssignDriverForm = false;
        },

        async getAssignedDrivers() {
            this.AssignedDriversloading = true;
            await this.axios
                .post('getAllOrderDrivers', { id: this.selectedAssignedDriverId, orderId: this.form.order_id, company_id: this.form.company_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.form.assignedDrivers = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.AssignedDriversloading = false;
                });
        },
        closeProductForm() {
            this.getOrderProducts();
            this.showProductForm = false;
        },
        closeQuotationForm() {
            this.getOrderQuotation();
            this.showQuotationForm = false;
        },
        closeInvoiceForm() {
            this.showInvoiceForm = false;
            this.getOrderInvoice();

        },



        // async getOrderQuotations() {
        //     this.orderQuotationsloading = true;
        //     await this.axios
        //         .post('getAllOrderQuotations', { id: 0, quotation_for_id: this.form.order_id, quotation_for: 'order', company_id: this.form.company_id })
        //         .then((response) => {
        //             let responseStatus = this.printResponseResult(response, false);
        //             if (responseStatus == 200) {
        //                 alert(responseStatus)
        //                 this.orderQuotations = response.data;
        //             }
        //         })
        //         .catch((error) => {
        //             this.printResponseResult(error);
        //         })
        //         .finally(() => {
        //             this.orderQuotationsloading = false;
        //         });
        // },


        async getOrderQuotation() {
            this.orderQuotationsloading = true;
            await this.axios
            this.axios.post('getAllOrderQuotations', { quotation_for_id: this.form.order_id, quotation_for: 'order' })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response.data.length) {
                            this.orderQuotation = response.data[0] ? response.data[0] : 0;
                            this.quotedPrice = this.orderQuotation.amount;
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.orderQuotationsloading = false;
                });
        },

        async getOrderInvoice() {
            this.orderInvoicesloading = true;
            await this.axios
            this.axios.post('getAllOrderInvoices', { invoice_for_id: this.form.order_id, invoice_for: 'order' })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response.data.length) {
                            this.orderInvoice = response.data[0] ? response.data[0] : 0;
                            this.invoicePrice = this.orderInvoice.amount;
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.orderInvoicesloading = false;
                });
        },

        async getOrderDrivers() {
            this.orderDriversLoading = true;
            await this.axiosce_for
            this.axios.post('getAllOrderDrivers', { order_id: this.recordId })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response.data.length) {
                            this.orderDrivers = response.data;
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.orderDriversLoading = false;
                });
        },

        async getOrderProducts() {
            this.orderProductsloading = true;
            await this.axios
                .post('getAllOrderProducts', { orderId: this.form.order_id, company_id: this.form.company_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.orderProducts = response.data;
                        if (this.orderProducts.length) {
                            this.orderProducts.forEach((product) => {
                                this.estimatedPrice += (product.quantity * product.unit_price);
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.orderProductsloading = false;
                });
        },

        openMoveForm() {
            this.moveFormOpen = true;
            this.removeViewFromURL('moves-form');
            this.addViewInURL({ view: 'moves-form', id: this.selectedMoveId });
        },
        closeMoveForm() {
            this.moveFormOpen = false;
            this.removeViewFromURL('moves-form');
            this.updateRecord();
        },
        tabClicked($event) {
            // console.log($event.index);
            this.removeViewFromURL('transport-tab');
            this.addViewInURL({ view: 'transport-tab', id: this.activeTab });
        },

        getClickedRecord(action) {
            if (action == 'delete') {
                this.deleteOrderMove();
            }
            if (action == 'update') {
                this.moveFormOpen = true;
                this.removeViewFromURL('moves-form');
                this.addViewInURL({ view: 'moves-form', id: this.selectedMoveId });
            }
            if (action == 'copy') {
                this.moveFormOpen = true;
                this.copyMove = true;
                this.removeViewFromURL('moves-form');
                this.addViewInURL({ view: 'moves-form', id: this.selectedMoveId });
            }
        },

        getOrderNotesClickedRecord(action) {

            if (action == 'delete') {
                this.deleteNote();
            }
            if (action == 'update') {
                this.showNotesForm = true;
                this.removeViewFromURL('order-notes-form');
                this.addViewInURL({ view: 'order-notes-form', id: this.selectedNoteId });
            }
        },

        deleteOrderMove() {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loading = true;
                    let DeleteAbleRecords = [];
                    if (Vthis.selectedMoveId) {
                        DeleteAbleRecords.push(this.selectedMoveId);
                    }

                    Vthis.axios
                        .post('deleteOrderTransportMove', { id: DeleteAbleRecords, order_id: this.form.order_id })
                        .then((response) => {
                            let responseStatus = this.printResponseResult(response, false);
                            if (responseStatus == 200) {
                                Vthis.updateRecord();
                                Vthis.showMessage('Move has been deleted successfully', 'success');
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                            Vthis.loading = false;
                        });
                },
                reject: () => {
                    // this.loading = false;
                },
            });
        },

        toggleInvoiceMenu(event) {
            this.selectedInvoiceId = event.currentTarget.getAttribute('invoiceId');
            this.$refs.menu5.toggle(event);
        },
        toggleQuotationMenu(event) {
            this.selectedQuotationId = event.currentTarget.getAttribute('quotationId');
            this.$refs.menu4.toggle(event);
        },
        toggleDriverMenu(event) {
            this.selectedDriverAssignedId = event.currentTarget.getAttribute('driverAssignedId');
            this.$refs.menu3.toggle(event);
        },
        toggleMoveMenu(event) {
            this.selectedMoveId = event.currentTarget.getAttribute('moveId');
            this.$refs.menu.toggle(event);
        },
        toggleProductMenu(event) {
            this.selectedProductId = event.currentTarget.getAttribute('productId');
            this.$refs.menu2.toggle(event);
        },
        toggleOrderNoteMenu(event) {
            this.selectedNoteId = event.currentTarget.getAttribute('noteId');
            this.$refs.menu6.toggle(event);
        },


        getAllRequestData() {
            this.$axios
                .all([this.getOrderData()])
                .then(() => {
                    return this.updateRecord();
                })
                .then(() => {
                    this.getOrderProducts();
                    this.getOrderQuotation();
                    this.getOrderInvoice();
                    this.getOrderDrivers();
                    this.getOrderNotes();
                })

                .finally(() => { });
        },

        movePrevious() {
            this.$emit('loadPreviousTab', true);
        },
        moveNext() {
            this.$emit('loadNextTab', true);
        },
        async save() {
            this.form.order_id = this.recordId;
            var Vthis = this;
            this.isSaving = true;

            // console.log(this.form.routes);
            // this.form.transportRoutes = this.form.routes;
            await this.form
                .post(Vthis.$baseurl + 'api/saveOrderTransport')
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.send_quotation = false;

                        if (this.saveAndClose) {
                            this.saveAndClose = false;
                        }
                        // alert(this.activeTab);
                        if (this.activeTab > 0) {
                            this.$emit('loadNextTab', true);
                        }
                        if (checkUserCoreRole(['Customer'])) {
                            this.emitter.emit('orderCreated', response.data.order_id);
                        }

                        // this.activeTab = this.activeTab+1;
                        Vthis.showMessage('Saved successfully', 'success');
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
        loadForm() {
            this.resetForm();
        },
        resetForm() {
            this.form.reset();
        },
        closeForm() {
            this.$emit('formClose', true);
        },

        async updateRecord() {
            if (this.recId > 0) {
                var Vthis = this;
                this.loading = true;
                await this.axios
                    .post('getAllOrderTransportMoves', { id: 0, order_id: this.recordId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            this.moves = response.data;
                            if (this.moves.length) {
                                this.moves.forEach((move) => {
                                    this.estimatedPrice += move.journey_price;
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.loading = false;
                    });
            }
        },

        async getOrderData() {
            if (this.recId > 0) {
                var Vthis = this;
                this.loading = true;
                await this.axios
                    .post('getAllOrders', { id: this.recordId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            if (response.data[0])
                                Vthis.orderData = response.data[0];
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.loading = false;
                    });
            }
        },

        setFocus(elementREF) {
            this.$refs[elementREF].$el.focus();
        },
    },
    watch: {},
};
</script>
 
