<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="101" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="true" @hide="closeMoveForm" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{ module_name }}</h3>
                        <p>You can add or update {{ module_name }} here.</p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body" :class="[$appState.modelClass]">
                        <div class="col-12 lg:col-12 mb-0 p-0 pt-4">
                            <div class="p-2">
                                <div class="col-12 lg:col-12 mb-0">
                                    <Panel class="mb-3" header="Routes Information" :toggleable="true"
                                        style="float: left; width: 100%">
                                        <div class="col-12 lg:col-4 sm:col-12 mb-2" style="float: left">
                                            <div>
                                                <label>Find Location</label>
                                                <div class="mapAutoComplete" tabindex="0">
                                                    <MapAutoComplete v-if="loadMapAutoComplete"
                                                        v-on:locationChanged="setLocation" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-2 sm:col-12 mb-2" style="float: left"
                                            v-if="checkPermission(19, 'insert') && !viewableOnly">
                                            <!-- <div class="col-12 lg:col-12 sm:col-12 mb-0"  style="float:left"> 
                                                <label :class="'full-width'">Journey Type</label>
                                                <Dropdown :filter="true" :showClear="true"  v-model="journey_type" @change="setReturnJourney($event)" :options="JourneyTypes" optionLabel="name" optionValue="id"  :placeholder="'Select Journey Type'" class="full-width"/>
                                            </div>  -->
                                            <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left">
                                                <label :class="'full-width'">Stay Hours</label>
                                                <InputNumber id="stay_hours_id" suffix=" hours" v-model="stay_hours"
                                                    mode="decimal" :min="0" class="_full-width" />
                                            </div>
                                            <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left">
                                                <label :class="'full-width'">Stay Minutes</label>
                                                <InputNumber id="stay_minutes_id" suffix=" minutes" v-model="stay_minutes"
                                                    mode="decimal" :min="0" :max="60" class="_full-width" />
                                            </div>
                                            <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left">
                                                <label :class="'full-width'">Location Notes</label>
                                                <Textarea placeholder="Stay Notes" v-model="stay_notes" rows="7" cols="7"
                                                    class="full-width" />
                                                <HasError class="p-error" :form="form" field="stay_notes" />
                                            </div>
                                            <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float: left">
                                                <Button @click="addNewRoute()" type="button" :label="locationBtnLabel"
                                                    icon="pi pi-plus"
                                                    class="p-button-raised p-button-lg p-button-success mr-2 full-width" />
                                                <Button v-if="cancelUpdateLocationBtn" @click="cancelUpdateLocation()"
                                                    type="button" :label="'Cancel'" icon="pi pi-times"
                                                    class="p-button-raised p-button-lg p-button-danger mr-2 full-width" />
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-6 sm:col-12 mb-2 routes-tabs" style="float: left">
                                            <div class="card height-100 widget-timeline" style="min-height: 100px">
                                                <div class="card-header" style="float: left">
                                                    <span
                                                        v-if="form.total_distance_without_unit && form.total_distance_without_unit != 'undefined'">
                                                        <div class="col-12 lg:col-5 sm:col-12 mb-0" style="float: left">
                                                            <Button
                                                                v-if="form.total_distance_without_unit && form.total_distance_without_unit != 'undefined'"
                                                                type="button" label="Map View" icon="pi pi-map-marker"
                                                                class="p-right p-button-raised p-button-lg p-button-info mr-2"
                                                                @click="
                                                                    displayRouteMap = true;
                                                                loadRoutesMapView();
                                                                                                                                    " />
                                                        </div>
                                                        <div v-if="Number(form.total_distance_without_unit) && !checkUserCoreRole(['Customer'])"
                                                            class="col-7 lg:col-7 sm:col-12 mb-0"
                                                            style="float: left; display: flex; margin-top: -15px">
                                                            <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                                                <label>Price/Distance Unit</label>
                                                                <InputNumber
                                                                    :disabled="viewableOnly || form.price_auto_calculation"
                                                                    mode="currency" @blur="perUnitPriceChange()"
                                                                    :currency="defaultCurrency" locale="en-US"
                                                                    placeholder="Price"
                                                                    v-model="form.per_distance_unit_price" :min="0"
                                                                    class="_full-width" />
                                                            </div>
                                                            <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                                                <label style="float: left">Total Price</label>
                                                                <InputNumber
                                                                    :disabled="viewableOnly || form.price_auto_calculation"
                                                                    mode="currency" @blur="totalPriceChange()"
                                                                    :currency="defaultCurrency" locale="en-US"
                                                                    placeholder="Price" v-model="form.journey_price"
                                                                    :min="0" class="_full-width" />
                                                            </div>
                                                        </div>
                                                        <div class="col-12 lg:col-12 sm:col-12 mb-0"
                                                            style="float: left; text-align: center; margin-top: -8px">
                                                            <Chip
                                                                :label="form.journey_duration ? form.journey_duration : 'Not Calculated'"
                                                                icon="pi pi-clock" class="mr-2" />
                                                            <Chip
                                                                :label="form.total_distance_without_unit > 0 ? Number(form.total_distance_without_unit).toFixed(0) + ' ' + userData['company_distance_unit_code'] : 'Not Calculated'"
                                                                icon="pi pi-car" class="" />
                                                        </div>
                                                    </span>
                                                    <h5 style="text-align: center" v-else>No Journey Added</h5>
                                                </div>
                                                <Divider v-if="form.routes && form.routes.length" />
                                                <div v-if="form.routes && form.routes.length"
                                                    class="col-12 lg:col-12 sm:col-12 mb-0">
                                                    <Timeline :value="form.routes" align="left" class="customized-timeline">
                                                        <template #marker="">
                                                            <span class="custom-marker shadow-2 p-2"
                                                                style="background-color: red">
                                                                <i class="marker-icon pi pi-map-marker"></i>
                                                            </span>
                                                        </template>
                                                        <template #content="slotProps">
                                                            <Card class="mb-3">
                                                                <template #title>
                                                                    {{ slotProps.item.city }}, {{ slotProps.item.state }},
                                                                    {{ slotProps.item.country }}
                                                                    <div style="float: right" v-if="!viewableOnly">
                                                                        <Button icon="pi pi-ellipsis-h"
                                                                            :locationId="slotProps.item.locationId"
                                                                            class="p-button-rounded p-button-text p-button-plain"
                                                                            @click="toggleMenu"></Button>
                                                                        <Menu :popup="true" ref="menu" :model="routesMenue">
                                                                        </Menu>
                                                                    </div>
                                                                </template>
                                                                <template #subtitle> lat: {{ slotProps.item.latitude }} |
                                                                    lng: {{ slotProps.item.longitude }} </template>
                                                                <template #content>
                                                                    <p class="m-1"><strong>Address :</strong> {{
                                                                        slotProps.item.address }}</p>
                                                                    <p class="m-1"><strong>ZipCode :</strong> {{
                                                                        slotProps.item.zipcode }}</p>
                                                                    <p class="m-1"><strong>Stay :</strong> {{
                                                                        slotProps.item.stay_hours }} hours {{
        slotProps.item.stay_minutes }} minutes</p>
                                                                    <p class="m-1"><strong>Stay Notes :</strong> {{
                                                                        slotProps.item.stay_notes }}</p>
                                                                    <p class="m-1" v-if="slotProps.item.distance">
                                                                        <strong>Distance from previous point :</strong> {{
                                                                            slotProps.item.distance }}</p>
                                                                    <p class="m-1" v-if="slotProps.item.time"><strong>Travel
                                                                            time from previous point :</strong> {{
                                                                                slotProps.item.time }}</p>
                                                                </template>
                                                            </Card>
                                                        </template>
                                                    </Timeline>
                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </div>
                                <Dialog v-if="form.routes && form.routes.length" :header="'Route Map'"
                                    v-model:visible="displayRouteMap" :style="{ width: '50vw' }" :maximizable="true"
                                    :modal="true">
                                    <div>
                                        <div id="routesMapView" class="routesMapView"></div>
                                    </div>
                                </Dialog>
                                <Divider />
                                <div class="col-12 lg:col-6 sm:col-12 mb-1" style="float: left">
                                    <Panel class="mb-3" header="Journey Information" :toggleable="true"
                                        style="float: left; width: 100%">
                                        <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                            <label><span class="pi pi-star-fill important mr-1"></span>Start Time</label>
                                            <Calendar ref="calendar1" panelClass="calendarStyle" :disabled="viewableOnly"
                                                :dateFormat="calendarFormat()" :hideOnDateTimeSelect="false"
                                                :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                                :minDate="minJourneyStartDateTime"
                                                @date-select="startDateTimeSelected($event)" :showButtonBar="true"
                                                id="start_time" v-model="form.start_time" :showTime="true" :stepMinute="5"
                                                :showSeconds="false" class="full-width">
                                                <template #footer>
                                                    <div class="full-width text-center">
                                                        <Button label="OK!" @click="closeCalendar"
                                                            class="p-button-raised p-button-success" />
                                                    </div>
                                                </template>
                                            </Calendar>
                                            <HasError class="p-error" :form="form" field="start_time" />
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                            <label>End Time <small>(Optional)</small></label>
                                            <Calendar ref="calendar2" panelClass="calendarStyle"
                                                :dateFormat="calendarFormat()" :hideOnDateTimeSelect="false"
                                                :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                                :minDate="minDateTime" @date-select="endDateTimeSelected($event)"
                                                :showButtonBar="true"
                                                :disabled="form.start_time == '' || viewableOnly ? true : false"
                                                id="end_time" v-model="form.end_time" :stepMinute="5" :showTime="true"
                                                :showSeconds="false" class="full-width">
                                                <template #footer>
                                                    <div class="full-width text-center">
                                                        <Button label="OK!" @click="closeCalendar"
                                                            class="p-button-raised p-button-success" />
                                                    </div>
                                                </template>
                                            </Calendar>
                                            <HasError class="p-error" :form="form" field="end_time" />
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left"
                                            v-if="checkPermission(36, 'dropdown')">
                                            <label>Vehicle Type<span v-if="checkPermission(36, 'insert') && !viewableOnly"
                                                    class="supporting-btn-link" @click="showVehicleTypeForm = true">+ Add
                                                    New</span></label>
                                            <Dropdown :disabled="viewableOnly" required ref="vehicleType12" @change="
                                                getVehicles();
                                            VehicleTypeChanged();
                                                                                                " VehicleTypesLoading :filter="true" :showClear="true"
                                                v-model="form.vehicle_type_id" :options="vehicleTypes" optionLabel="name"
                                                optionValue="id"
                                                :placeholder="VehicleTypesLoading ? 'Loading...' : 'Select Type'"
                                                class="full-width" />
                                            <HasError class="p-error" :form="form" field="vehicle_type_id" />
                                        </div>

                                        <div class="col-12 lg:col-6 sm:col-12 mb-0"
                                            v-for="(type, index) in form.orderCargoValues" :key="index" style="float: left">
                                            <label>{{ type.name }}</label>
                                            <InputText :disabled="viewableOnly" placeholder="Max Units" :max="type.units"
                                                v-model="form.orderCargoValues[index].cargo_units" type="number"
                                                class="full-width" />
                                        </div>

                                        <!-- <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left" v-if="checkPermission(8, 'dropdown') && !checkUserCoreRole(['Customer'])">
                                            <label>Base Location</label>
                                            <Dropdown
                                                :disabled="viewableOnly"
                                                BaseLocationsLoading
                                                :filter="true"
                                                :showClear="true"
                                                v-model="form.vehicle_base_branch_id"
                                                :options="branches"
                                                optionLabel="company_branch_name"
                                                optionValue="id"
                                                :placeholder="BaseLocationsLoading ? 'Loading...' : 'Select Base'"
                                                class="full-width"
                                            />
                                            <HasError class="p-error" :form="form" field="vehicle_base_branch_id" />
                                        </div> -->
                                        <div class="col-12 lg:col-6 sm:col-12 mb-1" style="float: left"
                                            v-if="checkPermission(19, 'dropdown') && !checkUserCoreRole(['Customer'])">
                                            <label>Journey Type<span v-if="checkPermission(19, 'insert') && !viewableOnly"
                                                    class="supporting-btn-link" @click="showJourneyTypeForm = true">+ Add
                                                    New</span></label>
                                            <Dropdown :disabled="viewableOnly" JourneyTypeLoading :filter="true"
                                                :showClear="true" v-model="form.journey_type_id" :options="journeyTypes"
                                                optionLabel="name_and_company" optionValue="id"
                                                :placeholder="JourneyTypeLoading ? 'Loading...' : 'Select Type'"
                                                class="full-width" />
                                            <HasError class="p-error" :form="form" field="journey_type_id" />
                                        </div>
                                        <div class="col-12 lg:col-6 sm:col-12 mb-0 pr-0" style="float: left">
                                            <label>Passangers</label>
                                            <InputNumber :disabled="viewableOnly" placeholder="Passangers"
                                                v-model="form.passangers" :min="0" suffix="" showButtons
                                                class="_full-width" />
                                            <HasError class="p-error" :form="form" field="passangers" />
                                        </div>
                                        <div class="col-12 lg:col-6 sm:col-12 mb-0 pr-0" style="float: left">
                                            <label>Hand Luggage</label>
                                            <InputNumber :disabled="viewableOnly" placeholder="Hand Luggage"
                                                v-model="form.hand_luggage" :min="0" suffix="" showButtons
                                                class="_full-width" />
                                            <HasError class="p-error" :form="form" field="hand_luggage" />
                                        </div>
                                        <div class="col-12 lg:col-6 sm:col-12 mb-0 pr-0" style="float: left">
                                            <label>Suitcases</label>
                                            <InputNumber :disabled="viewableOnly" placeholder="Suitcases"
                                                v-model="form.suitcases" :min="0" suffix="" showButtons
                                                class="_full-width" />
                                            <HasError class="p-error" :form="form" field="suitcases" />
                                        </div>
                                        <div class="col-12 lg:col-6 sm:col-12 mb-0 pr-0" style="float: left">
                                            <label>Child Seats</label>
                                            <InputNumber :disabled="viewableOnly" placeholder="Child Seats"
                                                v-model="form.child_seats" :min="0" suffix="" showButtons
                                                class="_full-width" />
                                            <HasError class="p-error" :form="form" field="child_seats" />
                                        </div>
                                        <!-- <div class="col-3 lg:col-3 sm:col-12 mb-0 pl-0 pr-0" style="float:left"> 
                                            <label>Luggage (KG)</label> 
                                            <InputNumber placeholder="Luggage" v-model="form.luggage" :min="0" suffix="KG" showButtons class="_full-width"/> 
                                            <HasError class="p-error" :form="form" field="luggage" /> 
                                        </div> -->
                                        <div class="col-12 lg:col-12 sm:col-12 mb-1" style="float: left">
                                            <label>Notes</label>
                                            <Textarea :disabled="viewableOnly" placeholder="Notes" v-model="form.notes"
                                                rows="5" cols="30" class="full-width" />
                                        </div>
                                    </Panel>
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-1" style="float: left">
                                    <Panel class="mb-3" header="Flight" :toggleable="true" style="float: left; width: 100%"
                                        :collapsed="true">
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0" style="float: left">
                                            <label>Airline</label>
                                            <InputText :disabled="viewableOnly" id="airline" placeholder="Airline"
                                                v-model="form.flight_name" type="text" class="full-width" />
                                            <HasError class="p-error" :form="form" field="flight_name" />
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                            <label>Flight Number</label>
                                            <InputText :disabled="viewableOnly" id="flight_number"
                                                placeholder="Flight Number" v-model="form.flight_number" type="text"
                                                class="full-width" />
                                            <HasError class="p-error" :form="form" field="flight_number" />
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                            <label>Inbound/Outbound</label>
                                            <Dropdown :disabled="viewableOnly" :filter="true" :showClear="true"
                                                v-model="form.inbound_outbound" :options="inboundOutbound"
                                                optionLabel="name" optionValue="id" class="full-width" />
                                            <HasError class="p-error" :form="form" field="inbound_outbound" />
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                            <label>Departure Time</label>
                                            <Calendar ref="calendar5" panelClass="calendarStyle" :disabled="viewableOnly"
                                                :dateFormat="calendarFormat()"
                                                :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                                :showButtonBar="true" id="departure_time" :stepMinute="5"
                                                v-model="form.departure_time" :showTime="true" :showSeconds="false"
                                                class="full-width">
                                                <template #footer>
                                                    <div class="full-width text-center">
                                                        <Button label="OK!" @click="closeCalendar"
                                                            class="p-button-raised p-button-success" />
                                                    </div>
                                                </template>
                                            </Calendar>
                                            <HasError class="p-error" :form="form" field="departure_time" />
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-0" style="float: left">
                                            <label>Arrival Time</label>
                                            <Calendar ref="calendar6" panelClass="calendarStyle" :disabled="viewableOnly"
                                                :dateFormat="calendarFormat()"
                                                :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                                :showButtonBar="true" id="arrival_time" :stepMinute="5"
                                                v-model="form.arrival_time" :showTime="true" :showSeconds="false"
                                                class="full-width">
                                                <template #footer>
                                                    <div class="full-width text-center">
                                                        <Button label="OK!" @click="closeCalendar"
                                                            class="p-button-raised p-button-success" />
                                                    </div>
                                                </template>
                                            </Calendar>
                                            <HasError class="p-error" :form="form" field="arrival_time" />
                                        </div>
                                        <div class="col-12 lg:col-12 sm:col-12 mb-0">
                                            <label>Notes</label>
                                            <Textarea :disabled="viewableOnly" placeholder="Notes"
                                                v-model="form.flight_notes" rows="5" cols="30" class="full-width" />
                                            <!-- <Editor v-model="notes" editorStyle="height: 320px"/> -->
                                        </div>
                                    </Panel>
                                </div>
                                <div v-if="priceCalculationSteps && Object.keys(priceCalculationSteps).length > 0"
                                    class="col-12 lg:col-12 mb-0" style="float: left">
                                    <div class="card height-100 widget-timeline" style="float: left; width: 100%">
                                        <div class="card-header">
                                            <h3>Price Calculations</h3>
                                        </div>
                                        <Divider />
                                        <div style="display: table; width: 100%">
                                            <div class="col-12 lg:col-12 md:col-12 mb-0"
                                                style="float: left; display: table-cell">
                                                <Panel
                                                    v-if="priceCalculationSteps.routes && priceCalculationSteps.routes.length && form.total_distance_without_unit && form.total_distance_without_unit > 0"
                                                    class="mb-3 full-width float-left" :header="'Jorney Pricing'"
                                                    :toggleable="false">
                                                    <div>
                                                        <table v-if="priceCalculationSteps.routes[0]">
                                                            <tr v-for="(step, key) in priceCalculationSteps.routes"
                                                                :key="key">
                                                                <td v-if="step.travelingHours > 0 || step.perHourPrice > 0">
                                                                    <strong>Traveling Hours :</strong> {{
                                                                        Number(step.travelingHours).toFixed(priceRounding) }}
                                                                </td>
                                                                <td v-if="step.travelingHours > 0 || step.perHourPrice > 0">
                                                                    <strong>Per Hour Price :</strong>{{
                                                                        Number(step.perHourPrice).toFixed(priceRounding) }}</td>
                                                                <td v-if="step.travelingHours > 0 || step.perHourPrice > 0">
                                                                    <strong>Total :</strong> {{ currency }} {{
                                                                        Number(step.perHourPrice *
                                                                            step.travelingHours).toFixed(priceRounding) }}</td>

                                                                <td
                                                                    v-if="step.perDistanceUnitPrice > 0 || step.travelingDistance > 0">
                                                                    <strong>Traveling Distance :</strong> {{
                                                                        Number(step.travelingDistance).toFixed(priceRounding) }}
                                                                </td>
                                                                <td
                                                                    v-if="step.perDistanceUnitPrice > 0 || step.travelingDistance > 0">
                                                                    <strong>Per Distance Unit Price :</strong> {{
                                                                        Number(step.perDistanceUnitPrice).toFixed(priceRounding)
                                                                    }}</td>
                                                                <td
                                                                    v-if="step.perDistanceUnitPrice > 0 || step.travelingDistance > 0">
                                                                    <strong>Total :</strong> {{ defaultCurrency }} {{
                                                                        Number(step.travelingDistance *
                                                                            step.perDistanceUnitPrice).toFixed(priceRounding) }}
                                                                </td>

                                                                <td v-if="step.pricePerStop > 0 || step.priceAbleStops > 0">
                                                                    <strong>Priceable Stops :</strong> {{
                                                                        step.priceAbleStops }}</td>
                                                                <td v-if="step.pricePerStop > 0 || step.priceAbleStops > 0">
                                                                    <strong>Per Stop Price :</strong> {{
                                                                        Number(step.pricePerStop).toFixed(priceRounding) }}</td>
                                                                <td v-if="step.pricePerStop > 0 || step.priceAbleStops > 0">
                                                                    <strong>Total :</strong> {{ defaultCurrency }} {{
                                                                        Number(step.pricePerStop *
                                                                            step.priceAbleStops).toFixed(priceRounding) }}
                                                                </td>

                                                                <td
                                                                    v-if="step.priceForHelper > 0 || step.priceAbleHelpers > 0 || step.priceForHelperHours > 0">
                                                                    <strong>Total :</strong> {{ defaultCurrency }} {{
                                                                        Number(step.priceForHelper * step.priceAbleHelpers *
                                                                            step.priceForHelperHours).toFixed(priceRounding) }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table v-if="priceCalculationSteps.adjustments[0]">
                                                            <tr v-for="(step, key) in priceCalculationSteps.adjustments"
                                                                :key="key">
                                                                <td
                                                                    v-if="step.minimumBookingHours && step.minimumBookingHours.priceAbleHours > 0">
                                                                    <strong>Priceable Hours (Less than minimum booking
                                                                        hours):</strong> {{
                                                                            Number(step.minimumBookingHours.priceAbleHours).toFixed(priceRounding)
                                                                        }}
                                                                </td>
                                                                <td
                                                                    v-if="step.minimumBookingHours && step.minimumBookingHours.perHourPrice > 0">
                                                                    <strong>Per Hour Price :</strong> {{
                                                                        Number(step.minimumBookingHours.perHourPrice).toFixed(priceRounding)
                                                                    }}
                                                                </td>
                                                                <td
                                                                    v-if="step.minimumBookingHours && (step.minimumBookingHours.perHourPrice > 0 || step.minimumBookingHours.priceAbleHours > 0)">
                                                                    <strong>Total :</strong> {{ defaultCurrency }} {{
                                                                        Number(step.minimumBookingHours.priceAbleHours *
                                                                            step.minimumBookingHours.perHourPrice).toFixed(priceRounding)
                                                                    }}
                                                                </td>

                                                                <td
                                                                    v-if="step.fixedTimePrice && step.fixedTimePrice.priceIncrease > 0">
                                                                    <strong>Fixed Booking Time Price :</strong> {{
                                                                        defaultCurrency }} {{
        Number(step.fixedTimePrice.priceIncrease).toFixed(priceRounding)
    }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </Panel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button v-if="!viewableOnly" type="submit" :label="'Save & Add New'" :loading="isSaving"
                                    icon="pi pi-check" @click="(saveAndClose = false), save()"
                                    class="p-button-raised p-button-lg p-button-success mr-2" />
                                <Button v-if="!checkUserCoreRole(['Customer']) && !viewableOnly" type="submit"
                                    label="Save and Close" :loading="isSaving" icon="pi pi-check"
                                    @click="(saveAndClose = true), save()"
                                    class="p-button-raised p-button-lg p-button-info mr-2" />
                                <Button v-if="!viewableOnly" type="button" label="Reset" icon="pi pi-refresh"
                                    class="p-right p-button-raised p-button-lg p-button-warning mr-2"
                                    @click="resetForm()" />
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2"
                                    @click="closeMoveForm()" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>
    </BlockUI>

    <VehicleTypeForm v-on:formClose="closeVehicleTypeForm" v-if="showVehicleTypeForm && permissions[36].includes('insert')"
        :recordId="0" :showForm="true" />
    <JourneyTypesForm v-on:formClose="closeJourneyTypesForm"
        v-if="showJourneyTypeForm && permissions[19].includes('insert')" :recordId="0" :showForm="true" />
</template>
<script>
import VehicleTypeForm from '../../components/Vehicles/VehicleTypesForm.vue';
import JourneyTypesForm from '../../components/Companies/JourneyTypeForm.vue';
// import { GoogleMap, Polyline,Marker  } from 'vue3-google-map'
import MapAutoComplete from '../../components/MapAutoComplete.vue';
// import GMap from '../../components/GMap.vue';
// import MapAutoComplete2 from '../../components/MapAutoComplete.vue';

export default {
    emits: ['editLocation'],
    data() {
        return {
            form: new this.Form({
                id: '',
                company_id: 0,
                order_id: 0,
                inbound_outbound: 'inbound',
                vehicle_type_id: 0,
                vehicle_base_branch_id: 0,
                passangers: 0,
                child_seats: 0,
                hand_luggage: 0,
                suitcases: 0,
                start_time: '',
                end_time: '',
                flight_name: '',
                flight_number: '',
                departure_time: '',
                arrival_time: '',
                per_distance_unit_price: 0,
                journey_price: 0,
                journey_duration: '',
                journey_distance: '',
                notes: '',
                journey_type_id: 0,
                flight_notes: '',
                routes: [],
                orderCargoValues: [],
                total_distance_without_unit: 0,
            }),
            createMoveCopay: false,
            price_auto_calculation: false,
            cargoGroupTypes: [],
            stay_hours: 0,
            stay_minutes: 0,
            stay_notes: '',
            total_traveling_price: 0,
            editMapLocation: {},
            displayRouteMap: false,
            loadMapAutoComplete: false,
            cancelUpdateLocationBtn: false,
            vehicleTypeCargoGroup: 0,
            vehicleCargoManifest: 0,
            orderPassengers: 0,

            routesMenue: [],
            activeLocation: [],
            activeLocationId: 0,
            moduleId: 42,
            inboundOutbound: [
                { name: 'Inbound', id: 'inbound' },
                { name: 'OutBound', id: 'outbound' },
            ],
            booleanStatus: [
                { name: 'Yes', id: 1 },
                { name: 'No', id: 0 },
            ],
            vehicleTypes: [],
            vehicles: [],
            branches: [],
            journeyTypes: [],
            minDateTime: '',
            minJourneyStartDateTime: '',
            UsersLoading: false,
            VehiclesLoading: false,
            VehicleTypesLoading: false,
            BaseLocationsLoading: false,
            JourneyTypeLoading: false,
            CompanyLoading: false,
            module_name: 'Order Move',
            isSaving: false,
            saveAndClose: false,
            recId: 0,
            formVisible: false,
            companies: [],
            locationBtnLabel: 'Add',
            showJourneyTypeForm: false,
            showVehicleTypeForm: false,

            vehicleTypeData: [],
            journeybookingHours: 2,
            bookingBeforeTime: 1,
            priceCalculationSteps: [],
        };
    },
    props: {
        showForm: {
            type: Boolean,
            required: true,
        },
        orderId: {
            type: Number,
        },
        recordId: {
            type: Number,
        },
        companyId: {
            type: Number,
        },
        viewableOnly: {
            type: Boolean,
            required: true,
        },
        priceAutoCalculation: {
            type: Boolean,
            required: true,
        },
        copyMove: {
            type: Boolean,
        },
    },
    created() {
        this.loadMapAutoComplete = true;
        if (this.checkPermission(this.moduleId, 'delete')) {
            this.routesMenue.push({
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => {
                    this.getClickedRecord('delete');
                },
            });
        }
        if (this.checkPermission(this.moduleId, 'update')) {
            this.routesMenue.push({
                label: 'Update',
                icon: 'pi pi-pencil',
                command: () => {
                    this.getClickedRecord('update');
                },
            });
        }
    },
    computed: {},
    components: {
        MapAutoComplete,
        JourneyTypesForm,
        VehicleTypeForm,
    },
    mounted() {
        this.resetForm();
        this.minJourneyStartDateTime = this.moment().toDate();
        this.price_auto_calculation = this.priceAutoCalculation;
        this.form.company_id = this.companyId;
        this.recId = this.recordId;
        this.form.id = this.recordId;
        this.form.order_id = this.orderId;
        this.createMoveCopay = this.copyMove;
        setTimeout(() => {
            this.loadMapAutoComplete = true;
        }, 1000);

        this.formVisible = this.showForm;
        this.getAllRequestData();

        // if (this.$route.query.view) {
        //     this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
        //     if (Object.keys(this.urlParams).length) {
        //         this.urlParams.forEach((parameter) => {
        //             // if (parameter.view == 'transport-tab') {
        //             //     this.activeTab = parameter.id;
        //             // }
        //         });
        //     }
        // }
    },
    methods: {
        loadRoutesMapView(interval = 1000, showMap = true) {
            if (this.form.routes && this.form.routes.length >= 2) {
                var journeyRoutes = this.form.routes;
                setTimeout(() => {
                    const directionsService = new google.maps.DirectionsService();
                    const directionsRenderer = new google.maps.DirectionsRenderer();
                    if (showMap) {
                        const map = new google.maps.Map(document.getElementById('routesMapView'), {
                            zoom: 6,
                            center: { lat: 41.85, lng: -87.65 },
                        });

                        directionsRenderer.setMap(map);
                    }
                    // this.calculateAndDisplayRoute(directionsService, directionsRenderer);
                    var queryString = {};
                    var stops = [];

                    queryString.origin = { lat: journeyRoutes[0].latitude, lng: journeyRoutes[0].longitude };
                    queryString.destination = { lat: journeyRoutes[journeyRoutes.length - 1].latitude, lng: journeyRoutes[journeyRoutes.length - 1].longitude };
                    if (journeyRoutes.length > 2) {
                        for (var k = 1; k < journeyRoutes.length - 1; k++) {
                            stops.push({
                                location: { lat: journeyRoutes[k].latitude, lng: journeyRoutes[k].longitude },
                                stopover: true,
                            });
                        }

                        queryString.stops = stops;
                    }
                    this.calculateAndDisplayRoute(directionsService, directionsRenderer, queryString);
                }, interval);
            }
        },
        calculateAndDisplayRoute(directionsService, directionsRenderer, queryString) {
            var journeyRoutes = this.form.routes;
            directionsService
                .route({
                    origin: queryString.origin,
                    destination: queryString.destination,
                    waypoints: queryString.stops,
                    optimizeWaypoints: true,
                    travelMode: google.maps.TravelMode.DRIVING,
                })
                .then((response) => {
                    // console.log(journeyType);
                    // console.log(response);
                    directionsRenderer.setDirections(response);
                    const route = response.routes[0];
                    var journeyIndex = 0;
                    var distance = 0;
                    var totalDistance = 0;
                    var totalDistanceMeters = 0;
                    var totalTravelingTime = 0;
                    var totalDistanceWithOutUnit = 0;
                    journeyRoutes[0].distance = '';
                    journeyRoutes[0].time = '';
                    route.legs.forEach((leg, index) => {
                        totalDistanceMeters = totalDistanceMeters + leg.distance.value;
                        totalTravelingTime = totalTravelingTime + leg.duration.value;
                        journeyIndex = index + 1;
                        distance = leg.distance.text;
                        if (this.defaultDistanceUnit == 'mile') {
                            distance = (leg.distance.value * 0.000621371192).toFixed(0) + ' mile';
                        }
                        journeyRoutes[journeyIndex].distance = distance;
                        journeyRoutes[journeyIndex].time = leg.duration.text;
                    });

                    // Time is in seconds so need to convert it into hours and minutes
                    totalTravelingTime = Math.floor(totalTravelingTime / 3600) + ' hours ' + Math.floor((totalTravelingTime % 3600) / 60) + ' minutes';

                    this.form.routes = journeyRoutes;

                    if (this.defaultDistanceUnit == 'mile') {
                        totalDistanceWithOutUnit = (totalDistanceMeters * 0.000621371192).toFixed(0);
                        totalDistance = totalDistanceWithOutUnit + ' mile';
                    } else if (this.defaultDistanceUnit == 'nmi') {
                        totalDistanceWithOutUnit = (totalDistanceMeters / 1852).toFixed(0);
                        totalDistance = totalDistanceWithOutUnit + ' nmi';
                    } else {
                        totalDistanceWithOutUnit = (totalDistanceMeters / 1000).toFixed(0);
                        totalDistance = totalDistanceWithOutUnit + ' km';
                    }

                    this.form.total_distance_without_unit = totalDistanceWithOutUnit;
                    this.form.journey_distance = totalDistance;
                    this.form.journey_duration = totalTravelingTime;

                    this.calculatePrice();
                })
                .catch((e) => {
                    console.log(e);
                    // window.alert("Directions request failed due to " + status)
                });
        },
        checkDrivers($driverType) {
            if (this.form.driver_id == this.form.secondary_driver_id) {
                if ($driverType == 'primary') {
                    this.form.secondary_driver_id = 0;
                } else {
                    this.form.driver_id = 0;
                }
            }
        },

        closeProductForm() {
            this.getOrderProducts();
            this.showProductForm = false;
        },
        closeVehicleForm() {
            this.getVehicles();
            this.showVehicleForm = false;
        },
        closeVehicleTypeForm() {
            this.getVehicleTypes();
            this.showVehicleTypeForm = false;
        },
        closeJourneyTypesForm() {
            this.getjourneyTypes();
            this.showJourneyTypeForm = false;
        },

        calculateBookingHours() {
            // this.bookingHours = 0;
            // this.bookingBeforeTime = 0;
            var now = '';
            var end = '';
            var duration = '';
            if (this.form.start_time && this.form.start_time != '') {
                if (this.form.end_time && this.form.end_time != '') {
                    now = this.moment(this.form.start_time); //todays date
                    end = this.moment(this.form.end_time); // another date
                    duration = this.moment.duration(end.diff(now));
                    this.journeybookingHours = duration.asHours();
                } else {
                    this.journeybookingHours = this.vehicleTypeData.default_minimum_booking_hours;
                    this.bookingBeforeTime = 1;
                }

                //Calculate booking before time
                now = this.moment(new Date()); //todays date
                end = this.moment(this.form.start_time); // another date
                duration = this.moment.duration(end.diff(now));
                this.bookingBeforeTime = duration.asHours();
            } else {
                this.journeybookingHours = this.vehicleTypeData.default_minimum_booking_hours;
                this.bookingBeforeTime = 1;
            }
        },
        calculatePrice() {
            setTimeout(() => {
                if (this.price_auto_calculation) {
                    this.priceCalculationSteps = { routes: [], adjustments: [] };

                    var perHourPrice = 0;
                    var perDistanceUnitPrice = 0;
                    var journeyTotalPrice = 0;

                    this.calculateBookingHours();

                    if (this.vehicleTypeData.distancePricingRules) {
                        this.vehicleTypeData.distancePricingRules.forEach((rule) => {
                            if (this.vehicleTypeData.price_calculation_unit == 'Hourly') {
                                perHourPrice = rule.price;
                            } else {
                                perDistanceUnitPrice = rule.price;
                            }

                            if (this.form.total_distance_without_unit && this.form.total_distance_without_unit > 0) {
                                if (this.form.total_distance_without_unit >= rule.from && this.form.total_distance_without_unit <= rule.to) {
                                    if (this.vehicleTypeData.price_calculation_unit == 'Hourly') {
                                        journeyTotalPrice = this.journeybookingHours * perHourPrice;
                                        this.priceCalculationSteps.routes.push({ perHourPrice: perHourPrice, travelingHours: this.journeybookingHours });
                                    } else {
                                        journeyTotalPrice = perDistanceUnitPrice * this.form.total_distance_without_unit;
                                        this.priceCalculationSteps.routes.push({ perDistanceUnitPrice: perDistanceUnitPrice, travelingDistance: this.form.total_distance_without_unit });
                                    }
                                }
                            }
                        });
                    }

                    //CALCULATE JOURNEY PRICE BY PER DISTANCE UNIT PRICE
                    if (journeyTotalPrice < 1) {
                        journeyTotalPrice = this.form.total_distance_without_unit * this.vehicleTypeData.per_distance_unit_price;
                        this.priceCalculationSteps.routes.push({ perDistanceUnitPrice: this.vehicleTypeData.per_distance_unit_price, travelingDistance: this.form.total_distance_without_unit });
                    }

                    //CALCULATE PRICE FOR STOPS
                    if (this.form.routes && this.form.routes.length > 0) {
                        var stops = this.form.routes.length - 2; //Substract pickup and dropof location
                        if (stops > this.vehicleTypeData.default_stops) {
                            var priceAbleStops = stops - this.vehicleTypeData.default_stops;
                            var firstStopPrice = priceAbleStops * this.vehicleTypeData.every_extra_stop_price;
                            journeyTotalPrice = journeyTotalPrice + firstStopPrice;
                            // totalPrice = totalPrice +  firstStopPrice;
                            this.priceCalculationSteps.routes.push({ priceAbleStops: priceAbleStops, pricePerStop: this.vehicleTypeData.every_extra_stop_price });
                        }
                    }

                    //MINIMUM BOOKING HOURS PRICE CALCULATION
                    var totalBookingHours = this.journeybookingHours;
                    if (totalBookingHours > 0) {
                        if (totalBookingHours < this.vehicleTypeData.default_minimum_booking_hours) {
                            if (this.vehicleTypeData.price_calculation_unit == 'Hourly') {
                                var priceAbleHours = this.vehicleTypeData.default_minimum_booking_hours - totalBookingHours;
                                var minimumBookingHoursPriceAdjustment = perHourPrice * priceAbleHours;
                                journeyTotalPrice = journeyTotalPrice + minimumBookingHoursPriceAdjustment;
                                // totalPrice = totalPrice + minimumBookingHoursPriceAdjustment;
                                this.priceCalculationSteps.adjustments.push({ minimumBookingHours: { perHourPrice: perHourPrice, priceAbleHours: priceAbleHours } });
                            }
                        }
                    }

                    //FIXED TIME PRICE CALCULATION
                    if (this.vehicleTypeData.fixedTimeArrivals) {
                        if (Object.keys(this.vehicleTypeData.fixedTimeArrivals).length > 0) {
                            this.vehicleTypeData.fixedTimeArrivals.forEach((time) => {
                                if (this.bookingBeforeTime >= time.from_hours && this.bookingBeforeTime <= time.to_hours) {
                                    this.priceCalculationSteps.adjustments.push({ fixedTimePrice: { priceIncrease: time.price } });
                                    journeyTotalPrice = journeyTotalPrice + time.price;
                                    // totalPrice += time.price;
                                }
                            });
                        }
                    }

                    //CALCULATE TOTAL PRICE
                    this.form.journey_price = journeyTotalPrice;
                    this.form.per_distance_unit_price = (this.form.first_journey_price / this.form.total_distance_without_unit).round(this.priceRounding);
                    this.total_traveling_price = this.form.journey_price;
                    // console.log(this.priceCalculationSteps.routes[0].travelingHours);
                    // console.log(this.priceCalculationSteps.routes[0].travelingHours);
                }
            }, 1000);
        },
        perUnitPriceChange() {
            if (!this.price_auto_calculation) {
                setTimeout(() => {
                    if (this.form.total_distance_without_unit) {
                        if (!this.form.per_distance_unit_price) {
                            this.form.per_distance_unit_price = 0;
                        }
                        this.form.journey_price = (Number(this.form.total_distance_without_unit) * this.form.per_distance_unit_price).round(this.priceRounding);
                    }
                }, 100);
            }
            return true;
        },
        totalPriceChange() {
            if (!this.price_auto_calculation) {
                setTimeout(() => {
                    if (this.form.total_distance_without_unit) {
                        if (!this.form.journey_price) {
                            this.form.journey_price = 0;
                        }
                        this.form.per_distance_unit_price = this.form.journey_price / this.form.total_distance_without_unit;
                    }
                }, 100);
            }
            return true;
        },
        getClickedProductRecord(action) {
            if (action == 'delete') {
                this.deleteOrderProduct();
            }
            if (action == 'update') {
                this.showProductForm = true;
            }
        },
        getClickedRecord(action) {
            if (action == 'delete') {
                this.deleteLocation();
            }
            if (action == 'update') {
                this.updateLocation();
                this.locationBtnLabel = 'Update';
                this.cancelUpdateLocationBtn = true;
                document.getElementsByClassName('mapAutoComplete')[0].focus();
            }
        },

        deleteOrderProduct() {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loading = true;
                    let DeleteAbleRecords = [];
                    if (Vthis.selectedRecords) {
                        Vthis.selectedRecordId = '';
                        Vthis.selectedRecords.forEach((data) => {
                            DeleteAbleRecords.push(data.id);
                        });
                    } else {
                        DeleteAbleRecords.push(Vthis.selectedProductId);
                    }

                    Vthis.axios
                        .post('deleteOrderMoveProduct', { id: DeleteAbleRecords, move_id: this.recordId, order_id: this.orderId })
                        .then((response) => {
                            let responseStatus = this.printResponseResult(response, false);
                            if (responseStatus == 200) {
                                Vthis.getOrderProducts();
                                Vthis.showMessage('Product deleted successfully', 'success');
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                            Vthis.loading = false;
                        });
                },
                reject: () => {
                    // this.loading = false;
                },
            });
        },
        cancelUpdateLocation() {
            this.locationBtnLabel = 'Add';
            this.cancelUpdateLocationBtn = false;
            this.activeLocationId = 0;
            this.stay_hours = '';
            this.stay_minutes = '';
            this.stay_notes = '';
            this.editMapLocation = {
                address_description: '',
                query: '',
                city: 'lahore',
                country: 'pakistan',
                lat: 41.3267905,
                lng: 19.8060475,
                state: 'punjab',
                zip_code: '54000',
                zoom: 17,
            };
            this.emitter.emit('editLocation', this.editMapLocation);
            document.getElementById('pac-input').value = '';
        },
        updateLocation() {
            var Vthis = this;
            var editLocation = {};

            if (this.form.routes && this.form.routes.length) {
                this.form.routes = this.form.routes.filter((route) => {
                    if (route.locationId == Vthis.activeLocationId) {
                        Vthis.stay_hours = route.stay_hours;
                        Vthis.stay_minutes = route.stay_minutes;
                        Vthis.stay_notes = route.stay_notes;
                        editLocation.lat = route.latitude;
                        editLocation.lng = route.longitude;
                        editLocation.address_description = route.address;
                        editLocation.country = route.country;
                        editLocation.state = route.state;
                        editLocation.city = route.city;
                        editLocation.zip_code = route.zipcode;
                        editLocation.zoom = 15;
                    }
                    return route;
                });
            }

            this.editMapLocation = editLocation;
            // this.EventBus.emit('editLocation',editLocation);
            this.emitter.emit('editLocation', this.editMapLocation);
        },
        deleteLocation() {
            var Vthis = this;

            if (this.form.routes && this.form.routes.length) {
                this.form.routes = this.form.routes.filter((route) => {
                    if (route.locationId != Vthis.activeLocationId) {
                        return route;
                    }
                });
            }
            Vthis.activeLocationId = 0;
            if (this.form.routes.length < 2) {
                this.form.total_distance_without_unit = 0;
                this.form.journey_price = 0;
                this.form.journey_distance = 0;
                this.form.per_distance_unit_price = 0;
            } else {
                this.loadRoutesMapView(0, false);
            }
        },
        toggleProductMenu(event) {
            this.selectedProductId = event.currentTarget.getAttribute('productId');
            this.$refs.menu2.toggle(event);
        },
        toggleMenu(event) {
            this.activeLocationId = event.currentTarget.getAttribute('locationId');
            this.$refs.menu.toggle(event);
        },
        setLocation(location) {
            this.activeLocation = location;
        },

        addNewRoute() {
            var Vthis = this;
            if (this.form.routes == undefined || !this.form.routes) {
                this.form.routes = [];
            }

            var push = true;
            if (this.form.routes.length) {
                this.form.routes.forEach((route) => {
                    if (Vthis.activeLocationId < 1 && route.latitude == Vthis.activeLocation.lat && route.longitude == Vthis.activeLocation.lng) {
                        push = false;
                    }
                    if (Vthis.activeLocationId > 0 && route.locationId == Vthis.activeLocationId) {
                        Vthis.locationBtnLabel = 'Add';
                        Vthis.activeLocationId = 0;
                        push = false;
                        route.stay_hours = Vthis.stay_hours;
                        route.stay_minutes = Vthis.stay_minutes;
                        route.stay_notes = Vthis.stay_notes;
                        route.address = Vthis.activeLocation.address_description;
                        route.latitude = Vthis.activeLocation.lat;
                        route.longitude = Vthis.activeLocation.lng;
                        route.country = Vthis.activeLocation.country;
                        route.state = Vthis.activeLocation.state;
                        route.city = Vthis.activeLocation.city;
                        route.zipcode = Vthis.activeLocation.zip_code;
                        Vthis.stay_hours = 0;
                        Vthis.stay_minutes = 0;
                        Vthis.stay_notes = '';

                        this.cancelUpdateLocationBtn = false;
                        document.getElementsByClassName('mapAutoComplete')[0].value = '';
                        document.getElementsByClassName('mapAutoComplete')[0].focus();
                        Vthis.activeLocationId = '';
                    }
                });
            }
            if (push) {
                var locationId = this.generateRandomNumber(10000000);
                this.form.routes.push({
                    stay_hours: Vthis.stay_hours,
                    stay_minutes: Vthis.stay_minutes,
                    stay_notes: Vthis.stay_notes,
                    address: Vthis.activeLocation.address_description,
                    latitude: Vthis.activeLocation.lat,
                    longitude: Vthis.activeLocation.lng,
                    country: Vthis.activeLocation.country,
                    state: Vthis.activeLocation.state,
                    city: Vthis.activeLocation.city,
                    zipcode: Vthis.activeLocation.zip_code,
                    locationId: locationId,
                });

                Vthis.stay_hours = 0;
                Vthis.stay_minutes = 0;
                Vthis.stay_notes = '';
            }

            // this.generateMarkers();
            this.loadRoutesMapView(0, false);
        },
        generateRandomNumber(maxNum) {
            return Math.floor(Math.random() * maxNum);
        },

        showRoutesDistanceAndTime() {
            // this.loadRoutesMapView(0, false);
            setTimeout(() => {
                this.loadRoutesMapView(0, false);
            }, 1000);
        },

        // Converts numeric degrees to radians

        startDateTimeSelected($event) {
            this.form.end_time = '';
            var $selectedDate = $event;
            this.minDateTime = new Date($selectedDate);
        },

        getAllRequestData() {
            this.$axios
                .all([this.updateRecord()])
                .then(() => {
                    this.getVehicleTypes();
                    return this.VehicleTypeChanged(true);
                })
                .then(() => {
                    return this.getjourneyTypes();
                })
                .then(() => {
                    this.getBaseBranches();
                    return this.getOrderProducts();
                })
                .then(() => {
                    return this.getVehicleTypeCargoTypes();
                })
                .then(() => {
                    // return this.getVehicles();
                })
                .then(() => {
                    // return this.getDriverUsers();
                })
                .then(() => {
                    this.blockComponent = false;
                })
                .catch(() => {
                    //    this.getAllRequestData();
                })
                .finally(() => {
                    this.showRoutesDistanceAndTime();
                });
        },

        async getOrderProducts() {
            this.orderProductsloading = true;
            await this.axios
                .post('getAllOrderProducts', { orderId: this.form.order_id, company_id: this.form.company_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.form.orderProducts = response.data;
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => { });
        },
        async getDriverUsers() {
            var Vthis = this;
            this.loading = true;
            await this.axios
                .post('getRoleSpecificUsers', { role: 'Driver', dropdown: true })
                .then((response) => {
                    this.driverUsers = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => { });
        },
        async VehicleTypeChanged(directCall = false) {
            var Vthis = this;
            await this.axios
                .post('getAllVehicleTypes', { id: this.form.vehicle_type_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        var vehicleType = response.data[0];
                        this.vehicleTypeData = vehicleType;

                        // console.log(vehicleType);
                        if (!this.form.id || this.form.id < 1) {
                            this.form.orderCargoValues = vehicleType.cargoGroupTypes;
                        }

                        // this.defaultDistanceUnit = vehicleType.vehicleDistanceUnit;
                        this.vehicleTypeCargoGroup = vehicleType.vehicle_type_group_name;
                        this.vehicleCargoManifest = vehicleType.cargo_manifest_name;
                        this.orderPassengers = vehicleType.passengers;
                        this.orderLuggage = vehicleType.luggage;

                        this.setFocus('vehicleType12');
                        this.$toast.removeAllGroups();
                        if (this.form.passangers > 0 && vehicleType.passengers && this.form.passangers > vehicleType.passengers) {
                            this.showMessage('The selected vehicle is not fit for this number of passangers. Please change it', 'warn', 5000);
                        }
                        if (this.form.luggage > 0 && vehicleType.luggage && this.form.luggage > vehicleType.luggage) {
                            this.showMessage('The selected vehicle is not fit for this number of luggage. Please change it', 'warn', 5000);
                        }

                        if (this.price_auto_calculation && !directCall) {
                            this.form.per_distance_unit_price = vehicleType.per_distance_unit_price;

                            if (this.form.total_distance_without_unit) {
                                this.form.first_journey_price = Number(this.form.total_distance_without_unit) * this.form.per_distance_unit_price;
                            }
                            this.calculatePrice();
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => { });
        },
        async getVehicles() {
            if (this.form.vehicle_type_id) {
                var Vthis = this;
                Vthis.VehiclesLoading = true;
                await this.axios
                    .post('getAllVehicles', { vehicle_type: this.form.vehicle_type_id, dropdown: true, company_id: this.form.company_id })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            this.vehicles = response.data;
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.VehiclesLoading = false;
                    });
            }
        },
        async getVehicleTypeCargoTypes() {
            // if(this.form.vehicle_type_id){
            //     if(!this.form.orderCargoValues){
            //         this.form.orderCargoValues = [];
            //     }
            //     var Vthis = this;
            //     Vthis.VehiclesLoading = true;
            //     await this.axios.post('getAllVehicleTypes',{id:this.form.vehicle_type_id,dropdown:true})
            //     .then( (response) => {
            //         let responseStatus = this.printResponseResult(response,false);
            //         if(responseStatus == 200){
            //             if(response.data[0]){
            //                 if(Object.keys(this.form.orderCargoValues).length < 1){
            //                     this.form.orderCargoValues = response.data[0].cargoGroupTypes;
            //                 }
            //                 this.cargoGroupTypes = response.data[0].cargoGroupTypes;
            //             }
            //         }
            //     })
            //     .catch((error) => {
            //         this.printResponseResult(error);
            //     })
            //     .finally(() => {
            //         Vthis.VehiclesLoading = false;
            //     })
            // }
        },
        async getVehicleTypes() {
            var Vthis = this;
            Vthis.VehicleTypesLoading = true;
            await this.axios
                .post('getAllVehicleTypes', { dropdown: true, company_id: this.form.company_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.vehicleTypes = response.data;
                        if (this.vehicleTypes && Object.keys(this.vehicleTypes).length > 0) {
                            this.vehicleTypes.forEach((type) => {
                                if (this.form.vehicle_type_id == type.id) {
                                    setTimeout(() => {
                                        if (!this.form.per_distance_unit_price) {
                                            this.form.per_distance_unit_price = type.per_distance_unit_price;
                                        }

                                        //Calculate Price
                                        if (this.form.total_distance_without_unit) {
                                            this.form.journey_price = Number(this.form.total_distance_without_unit) * this.form.per_distance_unit_price;
                                        }
                                    }, 10);

                                    // this.defaultDistanceUnit = type.vehicleDistanceUnit;
                                }
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.VehicleTypesLoading = false;
                });
        },
        async getBaseBranches() {
            var Vthis = this;
            Vthis.BaseLocationsLoading = true;
            await this.axios
                .post('getAllBranches', { dropdown: true, company_id: this.form.company_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        Vthis.branches = response.data;
                        if (response.data.length) {
                            if (!Vthis.form.vehicle_type_id || Vthis.form.vehicle_type_id < 1) {
                                response.data.forEach((record, index) => {
                                    if (index == 0) {
                                        Vthis.form.vehicle_base_branch_id = record.id;
                                    }
                                    if (record.company_id == Vthis.user.company_id && record.is_headoffice == 1) {
                                        Vthis.form.vehicle_base_branch_id = record.id;
                                    }
                                });
                            }
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.BaseLocationsLoading = false;
                });
        },

        async getjourneyTypes() {
            var Vthis = this;
            Vthis.JourneyTypeLoading = true;
            await this.axios
                .post('getAllJourneyTypes', { dropdown: true, company_id: this.form.company_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.journeyTypes = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.JourneyTypeLoading = false;
                });
        },

        resetForm() {
            this.form.reset();
        },

        async save() {
            var Vthis = this;
            this.isSaving = true;
            // this.form.transportRoutes = Object.assign({}, this.form.routes);
            // console.log(this.form.routes);
            // this.form.transportRoutes = this.form.routes;
            await this.form
                .post(Vthis.$baseurl + 'api/saveOrderTransportMove')
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        Vthis.showMessage('Saved successfully', 'success');

                        if (Vthis.saveAndClose) {
                            Vthis.closeMoveForm();
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
        loadForm() {
            this.resetForm();
        },
        closeMoveForm() {
            this.loadGMap = false;
            this.loadMapAutoComplete = false;
            this.$emit('closeMoveForm', true);
            // alert('running11');
            // this.$emit('closeOrderFrom',true);
        },

        async updateRecord() {
            if (this.recId > 0) {
                var Vthis = this;
                this.loading = true;
                await this.axios
                    .post('getAllOrderTransportMoves', { id: this.recordId, order_id: this.orderId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            if (response.data[0] && Object.keys(response.data[0]).length) {
                                Vthis.form.fill(this.replaceNullValues(response.data[0]));
                            }

                            if (!response.data[0].routes) {
                                this.form.routes = [];
                            }

                            if (Vthis.createMoveCopay) {
                                Vthis.form.id = 0;
                                Vthis.recId = 0;
                                Vthis.recordId = 0;
                                Vthis.selectedRecordId = 0;
                            }
                            // this.loadRoutesMapView(0,false);

                            Vthis.form.total_distance_without_unit = response.data[0].total_distance_without_unit != '' ? response.data[0].total_distance_without_unit : 0;
                        } else {
                            Vthis.form.total_distance_without_unit = 0;
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.loading = false;
                    });
            }
        },

        setFocus(elementREF) {
            this.$refs[elementREF].$el.focus();
        },
    },
    watch: {
        'form.start_time': {
            handler() {
                this.calculatePrice();
            },
        },
        'form.end_time': {
            handler() {
                this.calculatePrice();
            },
        },

        // 'form.total_traveling_price': {
        //     handler() {
        //         if (this.total_traveling_price) {
        //             if (this.vehicleTypes) {
        //                 this.vehicleTypes.forEach((type) => {
        //                     if (type.id == this.form.vehicle_type_id && type.instant_price) {
        //                         if (this.total_traveling_price > 0 && type.min_price && this.total_traveling_price < type.min_price) {
        //                             this.showMessage('The order cost is less than the minium order amount of the selected vehicle type. Please change it', 'warn', 5000);
        //                         }
        //                         if (this.total_traveling_price > 0 && type.max_price && this.total_traveling_price > type.max_price) {
        //                             this.showMessage('The order cost is more than the maximum order amount of the selected vehicle type. Please change it', 'warn', 5000);
        //                         }
        //                     }
        //                 });
        //             }
        //         }
        //     },
        //     deep: true,
        // },

        'form.passangers': {
            handler() {
                if (this.form.vehicle_type_id) {
                    if (this.vehicleTypes) {
                        this.vehicleTypes.forEach((type) => {
                            if (type.id == this.form.vehicle_type_id) {
                                this.setFocus('vehicleType12');
                                this.$toast.removeAllGroups();
                                if (this.form.passangers > 0 && type.passengers && this.form.passangers > type.passengers) {
                                    this.showMessage('The selected vehicle is not fit for this number of passangers. Max limit is ' + type.passengers, 'warn', 5000);
                                }
                            }
                        });
                    }
                }
            },
            deep: true,
        },

        'form.hand_luggage': {
            handler() {
                if (this.form.vehicle_type_id) {
                    if (this.vehicleTypes) {
                        this.vehicleTypes.forEach((type) => {
                            if (type.id == this.form.vehicle_type_id) {
                                this.setFocus('vehicleType12');
                                this.$toast.removeAllGroups();
                                if (this.form.hand_luggage > 0 && type.luggage && this.form.hand_luggage > type.luggage) {
                                    this.showMessage('The selected vehicle is not fit for this number of luggage. Max limit is ' + type.luggage, 'warn', 5000);
                                }
                            }
                        });
                    }
                }
            },
            deep: true,
        },
    },
};
</script>
 
